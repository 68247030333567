import { Button, Form, Input, Typography, Row, Col, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  SignUpStates,
  PHONE_NUMBER_PLACEHOLDER,
  VALID_PHONE_NUMBER_REGEX,
  ADDRESS_PLACEHOLDER,
  CITY_PLACEHOLDER,
} from '../../consts';
import Lottie from 'react-lottie-player';
import foxCelebrateAnimation from '../../lottie/fox-celebrate.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';
import { PhoneNumberInput } from '../PhoneNumberInput';
import { USStateCode } from '@finni-health/atlas-shared';

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
  invite: any;
};

export const EnterPersonalAddress: React.FC<IProps> = ({
  setInquiryStatus,
  updateInquiry,
  invite,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(isScreenMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  const submitAddress = () => {
    setInquiryStatus(SignUpStates.COMPLETE);
  };

  return (
    <>
      <Title
        style={{ fontFamily: 'Reckless Neue' }}
      >{`What is your address?`}</Title>
      <Text
        style={{
          display: 'block',
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {`We take your privacy seriously. Your details will only be stored for use by ${invite.clinicName} users.`}
      </Text>
      <Form onFinish={() => submitAddress()}>
        <Col
          span={24}
          style={{
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <Row gutter={12}>
            <Col span={18}>
              <Form.Item label="Address Line" name="line1">
                <Input
                  tabIndex={6}
                  placeholder={ADDRESS_PLACEHOLDER}
                  autoComplete="off"
                  onChange={(value) => {
                    updateInquiry({ line1: value.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="City" name="city">
                <Input
                  tabIndex={8}
                  placeholder={CITY_PLACEHOLDER}
                  autoComplete="off"
                  onChange={(value) => {
                    updateInquiry({ city: value.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="State" name="state">
                <Select
                  showSearch
                  value={''}
                  onChange={(value) => {
                    updateInquiry({ state: value });
                  }}
                >
                  {Object.values(USStateCode).map((state) => (
                    <Select.Option value={state} key={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            height: 44,
            width: 200,
          }}
        >
          Sign Up
        </Button>
      </Form>
    </>
  );
};
