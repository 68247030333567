import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Divider,
  Layout,
  List,
  message,
  Popconfirm,
  Popover,
  Typography,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import firebase from '../services/firebase';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { AuthContext } from './AuthProvider';
import './css/Header.css';
import _ from 'lodash';
import { COLORS } from '../consts';

const { Text, Link: LinkText } = Typography;

export const Header: React.FC = () => {
  const { user, clinic } = useContext(AuthContext);
  const history = useHistory();

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!_.isEmpty(user) && !_.isEmpty(clinic)) {
      setIsLoading(false);
    }
  }, [user, clinic]);

  const hidePopover = () => {
    setIsPopoverVisible(false);
  };

  const redirectToHome = () => {
    history.push('/');
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        message.info('Successfully logged out');
        history.push('/login');
      })
      .catch((error) => {
        message.info('Failed to log out');
      });
  };

  const profilePopover = () => {
    return (
      <div style={{ width: 100 }}>
        <List style={{ marginTop: -12, marginBottom: -12 }}>
          <List.Item>
            <Link
              to="/profile"
              onClick={hidePopover}
              style={{ color: '#000000a6' }}
            >
              <UserOutlined /> Profile
            </Link>
          </List.Item>
          <List.Item>
            <Popconfirm
              title={`Are you sure you want to logout?`}
              onConfirm={handleLogout}
              okText="Yes"
              cancelText="No"
              placement="bottomLeft"
            >
              <LinkText type="danger">
                <LogoutOutlined /> Logout
              </LinkText>
            </Popconfirm>
          </List.Item>
        </List>
      </div>
    );
  };

  return (
    <Layout.Header className="header">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={redirectToHome}
      >
        {/* <img src="/logo.svg" height={15} /> */}
        {!isLoading && (
          <>
            <Text
              style={{
                fontSize: 18,
                color: COLORS.PRIMARY,
              }}
            >{`Atlas`}</Text>
            <Divider
              type="vertical"
              style={{
                borderLeft: '1px solid rgba(0, 0, 0)',
                height: '22px',
              }}
            />
            <Text style={{ fontSize: 12 }}>{clinic.displayName}</Text>
          </>
        )}
      </div>
      <div>
        {!isLoading && (
          <Popover
            placement="bottomRight"
            content={profilePopover()}
            trigger="click"
            open={isPopoverVisible}
            onOpenChange={setIsPopoverVisible}
          >
            <Button type="text">
              <Avatar
                size="small"
                style={{
                  backgroundColor: COLORS.PRIMARY,
                }}
              >
                {!_.isEmpty(user) && user.firstName[0] + user.lastName[0]}
              </Avatar>
              <Text
                style={{
                  fontSize: 12,
                  marginLeft: 5,
                }}
              >
                {`${user.firstName} ${user.lastName}`}
              </Text>
            </Button>
          </Popover>
        )}
      </div>
    </Layout.Header>
  );
};
