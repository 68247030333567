import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'firebase/auth';
import firebase from 'firebase';
import { Loading } from './pages/Loading';
import { PrivateRoute } from './components/PrivateRoute';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { AuthProvider } from './components/AuthProvider';
import { SignUpFlow } from './pages/SignUpFlow';

function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  firebase.auth().onAuthStateChanged((_user: any) => {
    setIsInitialized(true);
  });

  return isInitialized ? (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUpFlow} />
          <PrivateRoute path="/">
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthProvider>
  ) : (
    <Loading />
  );
}

export default App;
