import {
  IAppointment,
  IClient,
  ICompletedAppointment,
  IIndirect,
  IUser,
} from '@finni-health/atlas-shared';

export type IRbtCalendarEvent = IAppointment | IIndirect;
export type ICalendarEvent = IAppointment | IIndirect | ICompletedAppointment;

export enum CalendarType {
  CLIENTS = 'clients',
  THERAPISTS = 'therapists',
}

export enum WeekDays {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

export interface ICalendarRow {
  person: IUser | IClient;
  sunday: ICalendarEvent[];
  monday: ICalendarEvent[];
  tuesday: ICalendarEvent[];
  wednesday: ICalendarEvent[];
  thursday: ICalendarEvent[];
  friday: ICalendarEvent[];
  saturday: ICalendarEvent[];
}
