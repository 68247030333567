import firebase from '../services/firebase';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

/**
 * Helper component to ensure user is Authenticated before accessing the route.
 */
export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      //@ts-ignore
      render={({ location }) => {
        return firebase.auth().currentUser ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
      }}
    />
  );
};
