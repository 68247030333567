import { DocumentSnapshot } from '@firebase/firestore-types';
import firebase from 'firebase/app';
import '@firebase/firestore';
import {
  IAppointment,
  IGuardian,
  IClient,
  IUser,
  IInquiry,
  IClientFile,
  IPayer,
  INote,
  ICreateInquiryEndpointRequest,
  ICreateUserEndpointRequest,
  IUpdateUserEndpointRequest,
  IConvertInquiryEndpointRequest,
  ICreateClientEndpointRequest,
  IUpdateClientFileEndpointRequest,
  IUpdateInquiryEndpointRequest,
  IUpdateGuardianEndpointRequest,
  IUpdateClientEndpointRequest,
  ICalendarGetAppointmentsForClinicIdAndWeekEndpointRequest,
  ICalendarCreateAppointmentEndpointRequest,
  ICalendarUpdateAppointmentEndpointRequest,
  ICalendarDeleteAppointmentEndpointRequest,
  IUpdateClinicEndpointRequest,
  USStateCode,
  ICreatePayerEndpointRequest,
  IUpdatePayerEndpointRequest,
  ICalendarGetAppointmentEndpointRequest,
  ICreateIndirectEndpointRequest,
  IGetIndirectsForWeekAndClinicIdEndpointRequest,
  IDeleteIndirectEndpointRequest,
  IIndirect,
  IUpdateIndirectEndpointRequest,
  ICreateCompletedAppointmentEndpointRequest,
  ICreateCancelledCompletedAppointmentEndpointRequest,
  IGetCompletedAppointmentsForClinicAndRangeEndpointRequest,
  IUpdateCompletedAppointmentEndpointRequest,
  IDeleteCompletedAppointmentEndpointRequest,
  ICompletedAppointment,
  BillingCode,
  Modifier,
  IGetBilledAppointmentsForClinicAndRangeEndpointRequest,
  IBatchBillEndpointRequest,
  IDeleteUserEndpointRequest,
  IOAuthPostEndpointRequest,
  IInvite,
  ICreateInviteRequest,
  IUpdateInviteEndpointRequest,
  IDeleteInviteEndpointRequest,
  IGetCompletedAppointmentsForClinicClientAndRangeEndpointRequest,
  IClinic,
  ICreateClientFromScratchEndpointRequest,
} from '@finni-health/atlas-shared';
import * as backend from './backend';
import _ from 'lodash';
import moment from 'moment';

export const db = firebase.firestore();

export const ENDPOINTS = {
  getCreateAppointmentEndpoint: () =>
    backend.endpoint('/appointments/create', 'post'),
  getGetAppointmentByIdEndpoint: () =>
    backend.endpoint('/appointments/get', 'post'),
  getGetAppointmentsForWeekEndpoint: () =>
    backend.endpoint('/appointments/get-for-week', 'post'),
  getUpdateAppointmentEndpoint: () =>
    backend.endpoint('/appointments/update', 'post'),
  getDeleteAppointmentEndpoint: () =>
    backend.endpoint('/appointments/delete', 'post'),
  getCreateClientEndpoint: () => backend.endpoint('/clients/create', 'post'),
  getUpdateClientEndpoint: () => backend.endpoint('/clients/update', 'post'),
  getUpdateClinicEndpoint: () => backend.endpoint('/clinics/update', 'post'),
  getGetClinicByAccessCodeEndpoint: () =>
    backend.endpoint('/clinics/getByAccessCode', 'post'),
  getGetBilledAppointmentsForClinicAndRangeEndpoint: () =>
    backend.endpoint('/billed-appointments/get-for-clinic-and-range', 'post'),
  getBatchBillEndpoint: () => backend.endpoint('/billing/batch-bill', 'post'),
  getCreateIndirectEndpoint: () =>
    backend.endpoint('/indirects/create', 'post'),
  getUpdateIndirectEndpoint: () =>
    backend.endpoint('/indirects/update', 'post'),
  getGetIndirectEndpoint: () => backend.endpoint('/indirects/get', 'post'),
  getGetIndirectForWeekAndClinicIdEndpoint: () =>
    backend.endpoint('/indirects/get-for-week-and-clinic-id', 'post'),
  getDeleteIndirectEndpoint: () =>
    backend.endpoint('/indirects/delete', 'post'),
  getCreateInquiryEndpoint: () => backend.endpoint('/inquiries/create', 'post'),
  getUpdateInquiryEndpoint: () => backend.endpoint('/inquiries/update', 'post'),
  getDeleteInquiryEndpoint: () => backend.endpoint('/inquiries/delete', 'post'),
  getUpdateGuardianEndpoint: () =>
    backend.endpoint('/guardians/update', 'post'),
  getUpdateClientFileEndpoint: () =>
    backend.endpoint('/client-files/update', 'post'),
  getConvertToPrequalifiedGuardianEndpoint: () =>
    backend.endpoint('/intake/convert-to-prequalified-guardian', 'post'),
  getCreateUserEndpoint: () => backend.endpoint('/users/create', 'post'),
  getUpdateUserEndpoint: () => backend.endpoint('/users/update', 'post'),
  getDeleteUserEndpoint: () => backend.endpoint('/users/delete', 'post'),
  getApproveNoteEndpoint: () => backend.endpoint('/motivity/put', 'put'),
  getUpdateNoteEndpoint: () => backend.endpoint('/notes/update', 'post'),
  getCreatePayerEndpoint: () => backend.endpoint('/payers/create', 'post'),
  getUpdatePayerEndpoint: () => backend.endpoint('/payers/update', 'post'),
  getCreateCompletedAppointmentEndpoint: () =>
    backend.endpoint('/completed-appointments/create', 'post'),
  getCreateCancelledCompletedAppointmentEndpoint: () =>
    backend.endpoint('/completed-appointments/create-cancelled', 'post'),
  getGetForClinicAndRangeCompletedAppointments: () =>
    backend.endpoint(
      '/completed-appointments/get-for-clinic-and-range',
      'post'
    ),
  getGetForClinicClientAndRangeCompletedAppointments: () =>
    backend.endpoint(
      '/completed-appointments/get-for-clinic-client-and-range',
      'post'
    ),
  getUpdateCompletedAppointmentEndpoint: () =>
    backend.endpoint('/completed-appointments/update', 'post'),
  getDeleteCompletedAppointmentEndpoint: () =>
    backend.endpoint('/completed-appointments/delete', 'post'),
  getGetMotivityNoteEndpoint: () => backend.endpoint('/motivity/get', 'post'),
  getOAuthURL: () => backend.endpoint('/oauth/get', 'post'),
  getPostOAuthCode: () => backend.endpoint('/oauth/post', 'post'),
  getCreateInviteEndpoint: () => backend.endpoint('/invites/create', 'post'),
  getUpdateInviteEndpoint: () => backend.endpoint('/invites/update', 'post'),
  getDeleteInviteEndpoint: () => backend.endpoint('/invites/delete', 'post'),
  getCreateClientFromScratchEndpoint: () =>
    backend.endpoint('/clients/createFromScratch', 'post'),
  getInvitesByAuthEmail: () => backend.endpoint('/invites/get', 'post'),
};

const cacheEnabled = true;
const cache: { [functionName: string]: any } = {};
/**
 * [cacheRequest use this to cache an async function call, since it's async there is a chance some calls will be made more than once but reduces number of calls overall]
 * @param  {String} functionName [name of the function]
 * @param  {Array} argList [arguments of the function]
 * @param  {Function} func [the function being called]
 * @return {Any} [returns results]
 */
const cacheManager = async (
  functionName: string,
  argumentsList: any[] = [],
  cacheInvalidation: number,
  func: () => Promise<any>
) => {
  // Try to get from cache
  const res = await (
    await cacheRequest(functionName, argumentsList, cacheInvalidation)
  )();
  //if function is not cached
  if (res === undefined) {
    let promiseResolver: any;
    const promise = new Promise<void>((resolve) => {
      promiseResolver = resolve;
    });
    //store promise in cache early to prevent multiple calls
    (
      await cacheRequest(
        functionName,
        argumentsList,
        cacheInvalidation,
        promise
      )
    )();
    //call function
    const resp = await func();
    //store function results in cache
    promiseResolver(resp);
    (
      await cacheRequest(functionName, argumentsList, cacheInvalidation, resp)
    )();
    //return results
    return resp;
  } else {
    //return value from cache
    return await res;
  }
};
const cacheRequest = async (
  func: string,
  argList: any[] = [],
  cacheInvalidation: number,
  results: any = undefined
) => {
  return async () => {
    if (!cacheEnabled) return undefined;
    //convert arguments list to string
    const args = JSON.stringify(argList);
    //attempt to return from cache
    if (results === undefined) {
      if (cache[func] && cache[func][args]) {
        const [timestamp, storedResults] = cache[func][args];
        if (timestamp + cacheInvalidation > Date.now()) {
          //cache is valid, return results
          return storedResults;
        } else {
          //cache is invalid, delete it
          delete cache[func][args];
        }
      }
      //return undefined if not in cache
      return undefined;
    } else {
      //store results in cache
      if (cache[func] === undefined) cache[func] = {};
      cache[func][args] = [Date.now(), results];
      return true;
    }
  };
};

export const createInquiry = async (req: ICreateInquiryEndpointRequest) => {
  try {
    const createInquiry = ENDPOINTS.getCreateInquiryEndpoint();
    const result = await createInquiry(req);

    return result.id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateInquiry = async (req: IUpdateInquiryEndpointRequest) => {
  try {
    const updateInquiry = ENDPOINTS.getUpdateInquiryEndpoint();
    const result = await updateInquiry(req);

    return result.id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInquiryById = async (id: string): Promise<IInquiry> => {
  try {
    const result = await db.collection('inquiries').doc(id).get();
    const inquiry = result.data() as IInquiry;
    if (!inquiry) return Promise.reject('Inquiry not found');
    if (inquiry?.deletedAt) return Promise.reject('Inquiry has been deleted');
    return inquiry;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createUser = async (req: ICreateUserEndpointRequest) => {
  try {
    const createUser = ENDPOINTS.getCreateUserEndpoint();
    await createUser(req);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUser = async (req: IUpdateUserEndpointRequest) => {
  try {
    const updateUser = ENDPOINTS.getUpdateUserEndpoint();
    await updateUser(req);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUser = async (req: IDeleteUserEndpointRequest) => {
  try {
    const deleteUser = ENDPOINTS.getDeleteUserEndpoint();
    await deleteUser(req);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserById = async (uid: string) => {
  try {
    const result = await db.collection('users').doc(uid).get();

    const user = result.data() as IUser;

    return user;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllUsersForClinic = async (
  clinicId: string,
  force: boolean = false
) => {
  try {
    return (await cacheManager(
      'getAllUsersForClinic',
      [clinicId],
      force ? 0 : 15 * 60 * 1000,
      async () => {
        const result = await db
          .collection('users')
          .where('clinicId', '==', clinicId)
          .get();
        let users: IUser[] = [];
        result.forEach((doc) => users.push(doc.data() as IUser));

        users = users.filter((user) => !user?.deletedAt);
        if (users.length === 0) {
          Promise.reject(`No users found for clinic (${clinicId})`);
        }
        return users as IUser[];
      }
    )) as IUser[];
  } catch {
    return Promise.reject('No users found for clinic');
  }
};

export const getClinicById = async (id: string) => {
  try {
    const getClinicReq = await db.collection('clinics').doc(id).get();
    const clinic = getClinicReq.data() as IClinic;
    if (!clinic) return Promise.reject('Clinic not found');
    if (clinic?.deletedAt) return Promise.reject('Clinic has been deleted');
    return clinic;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClinicByAccessCode = async (accessCode: string) => {
  try {
    const getClinicByAccessCode = ENDPOINTS.getGetClinicByAccessCodeEndpoint();
    const clinic = await getClinicByAccessCode({ accessCode });

    if (!clinic || _.isEmpty(clinic)) {
      return Promise.reject(`No clinic found with access code ${accessCode}`);
    }

    return clinic;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateClinic = async (req: IUpdateClinicEndpointRequest) => {
  try {
    const updateClinic = ENDPOINTS.getUpdateClinicEndpoint();
    const result = await updateClinic(req);

    return Promise.resolve(result.id);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createClient = async (req: ICreateClientEndpointRequest) => {
  try {
    const createClient = ENDPOINTS.getCreateClientEndpoint();
    const result = await createClient(req);

    return result.id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientById = async (id: string): Promise<IClient> => {
  try {
    const query = db.collection('clients').doc(id);
    const result = await query.get();
    const client = result.data() as IClient;
    if (!client) return Promise.reject('Client not found');
    if (client?.deletedAt) return Promise.reject('Client has been deleted');
    return client;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientByGuardianId = async (
  guardianId: string,
  clinicId: string
): Promise<IClient> => {
  try {
    const clientFile = await getClientFileByGuardianId(guardianId, clinicId);

    return await getClientById(clientFile.clientId);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateClient = async (req: IUpdateClientEndpointRequest) => {
  try {
    const updateClient = ENDPOINTS.getUpdateClientEndpoint();
    const result = await updateClient(req);

    return result.id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllClientsForClinic = async (
  clinicId: string,
  force: boolean = false
) => {
  try {
    return (await cacheManager(
      'getAllClientsForClinic',
      [clinicId],
      force ? 0 : 15 * 60 * 1000,
      async () => {
        const result = await db
          .collection('clients')
          .where('clinicId', '==', clinicId)
          .orderBy('createdAt', 'desc')
          .get();
        let clients: IClient[] = [];
        result.forEach((doc) => clients.push(doc.data() as IClient));

        clients = clients.filter((client) => !client?.deletedAt);
        return clients as IClient[];
      }
    )) as IClient[];
  } catch {
    return Promise.reject('No clients found for clinic');
  }
};

export const updateGuardian = async (req: IUpdateGuardianEndpointRequest) => {
  try {
    const updateGuardian = ENDPOINTS.getUpdateGuardianEndpoint();
    const result = await updateGuardian(req);

    return result.id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGuardianById = async (id: string): Promise<IGuardian> => {
  try {
    const query = db.collection('guardians').doc(id);
    const result = await query.get();
    const guardian = result.data() as IGuardian;
    if (guardian?.deletedAt) return Promise.reject('Guardian has been deleted');
    if (!guardian) return Promise.reject('Guardian not found');
    return guardian;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGuardianByClientId = async (
  clientId: string,
  clinicId: string
): Promise<IGuardian> => {
  try {
    const clientFile = await getClientFileByClientId(clientId, clinicId);

    return await getGuardianById(clientFile.guardianId);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllGuardiansForClinic = async (
  clinicId: string
): Promise<IGuardian[]> => {
  try {
    const query = db
      .collection('guardians')
      .where('clinicId', '==', clinicId)
      .orderBy('createdAt', 'desc');
    const result = await query.get();

    let guardians = result.docs.map((docSnapshot: DocumentSnapshot) =>
      docSnapshot.data()
    ) as IGuardian[];

    guardians = guardians.filter((guardian) => !guardian?.deletedAt);

    return guardians;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllInquiriesForClinic = async (
  clinicId: string
): Promise<IInquiry[]> => {
  try {
    const query = db
      .collection('inquiries')
      .where('clinicId', 'in', [clinicId, '00000000000000000001'])
      .orderBy('createdAt', 'desc');
    const result = await query.get();

    let inquiries = result.docs.map((docSnapshot: DocumentSnapshot) =>
      docSnapshot.data()
    ) as IInquiry[];

    inquiries = inquiries.filter((inquiry) => !inquiry?.deletedAt);

    return inquiries;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteInquiry = async (id: string): Promise<string> => {
  try {
    const deleteInquiry = ENDPOINTS.getDeleteInquiryEndpoint();
    const result = deleteInquiry({ id });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

//TODO: route to backend
export const deleteGuardian = async (guardianId: string): Promise<string> => {
  try {
    const ref = db.collection('guardians').doc(guardianId);

    await ref.update({
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    return guardianId;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAppointment = async (
  req: ICalendarCreateAppointmentEndpointRequest
): Promise<string> => {
  try {
    const createAppointment = ENDPOINTS.getCreateAppointmentEndpoint();
    const appointmentId = await createAppointment(req);
    return Promise.resolve(appointmentId);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAppointmentById = async (
  req: ICalendarGetAppointmentEndpointRequest
): Promise<IAppointment> => {
  try {
    const getAppointmentById = ENDPOINTS.getGetAppointmentByIdEndpoint();
    const appointment = await getAppointmentById(req);

    return appointment;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAppointmentsForClinicIdAndWeek = async (
  req: ICalendarGetAppointmentsForClinicIdAndWeekEndpointRequest
): Promise<IAppointment[]> => {
  try {
    const getAppointmentsForWeek =
      ENDPOINTS.getGetAppointmentsForWeekEndpoint();
    const appointments = await getAppointmentsForWeek(req);

    return appointments;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAppointment = async (
  req: ICalendarUpdateAppointmentEndpointRequest
) => {
  try {
    const updateAppointment = ENDPOINTS.getUpdateAppointmentEndpoint();
    await updateAppointment(req);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAppointment = async (
  req: ICalendarDeleteAppointmentEndpointRequest
) => {
  try {
    const deleteAppointment = ENDPOINTS.getDeleteAppointmentEndpoint();
    await deleteAppointment(req);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const convertToPrequalifiedGuardian = async (
  req: IConvertInquiryEndpointRequest
) => {
  try {
    const convertToPrequalifiedGuardian =
      ENDPOINTS.getConvertToPrequalifiedGuardianEndpoint();
    await convertToPrequalifiedGuardian({ req });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientFileByGuardianId = async (
  guardianId: string,
  clinicId: string
): Promise<IClientFile> => {
  try {
    const query = await db
      .collection('client-files')
      .where('clinicId', '==', clinicId)
      .where('guardianId', '==', guardianId)
      .get();

    let clientFiles = query.docs.map((doc: DocumentSnapshot) =>
      doc.data()
    ) as IClientFile[];

    clientFiles = clientFiles.filter((clientFile) => !clientFile?.deletedAt);

    return clientFiles[0];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClientFileByClientId = async (
  clientId: string,
  clinicId: string
): Promise<IClientFile> => {
  try {
    const query = await db
      .collection('client-files')
      .where('clinicId', '==', clinicId)
      .where('clientId', '==', clientId)
      .get();

    let clientFiles = query.docs.map((doc: DocumentSnapshot) =>
      doc.data()
    ) as IClientFile[];

    clientFiles = clientFiles.filter((clientFile) => !clientFile?.deletedAt);

    return clientFiles[0];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClientFile = async (
  req: IUpdateClientFileEndpointRequest
) => {
  try {
    const updateClientFile = ENDPOINTS.getUpdateClientFileEndpoint();
    const result = await updateClientFile(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPayerById = async (id: string): Promise<IPayer> => {
  try {
    const result = await db.collection('payers').doc(id).get();
    const payer = result.data() as IPayer;
    if (!payer) return Promise.reject('Payer not found');
    if (payer?.deletedAt) return Promise.reject('Payer has been deleted');
    return payer;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllPayersByState = async (
  state: USStateCode,
  clinicId: string
) => {
  const result = await db
    .collection('payers')
    .where('clinicId', '==', clinicId)
    .where('state', '==', state)
    .orderBy('name', 'asc')
    .get();

  const payers = result.docs.map((doc: DocumentSnapshot) =>
    doc.data()
  ) as any[];
  return payers;
};

export const getAllNotesByAppointmentId = async (
  id: string,
  clinicId: string,
  force: boolean = false
): Promise<INote[]> => {
  return (await cacheManager(
    'getAllNotesByAppointmentId',
    [id, clinicId],
    force ? 0 : 10 * 1000,
    async () => {
      const result = await db
        .collection('notes')
        .where('clinicId', '==', clinicId)
        .where('appointmentId', '==', id)
        .orderBy('startMs', 'asc')
        .get();

      const notes = result.docs.map((doc: any) => doc.data()) as INote[];
      return notes;
    }
  )) as INote[];
};

export const getAllUnmatchedNotesByClinicId = async (
  clinicId: string
): Promise<INote[]> => {
  try {
    const result = await db
      .collection('notes')
      .where('clinicId', '==', clinicId)
      .where('appointmentId', '==', '')
      .orderBy('startMs', 'asc')
      .get();

    const notes = result.docs.map((doc: any) => doc.data()) as INote[];
    return notes;
  } catch (error) {
    return Promise.reject(`Error getting notes ${error}`);
  }
};

export const approveNote = async (noteId: string) => {
  try {
    const approveNote = ENDPOINTS.getApproveNoteEndpoint();
    const result = await approveNote({ noteId });

    return result == 'success' ? true : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNote = async (req: any) => {
  try {
    const updateNote = ENDPOINTS.getUpdateNoteEndpoint();
    const result = await updateNote(req);

    return result == 'success' ? true : false;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPayer = async (req: ICreatePayerEndpointRequest) => {
  try {
    const createPayer = ENDPOINTS.getCreatePayerEndpoint();
    const result = await createPayer(req);

    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePayer = async (req: IUpdatePayerEndpointRequest) => {
  try {
    const updatePayer = ENDPOINTS.getUpdatePayerEndpoint();
    const result = await updatePayer(req);

    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createIndirect = async (req: ICreateIndirectEndpointRequest) => {
  try {
    const createIndirect = ENDPOINTS.getCreateIndirectEndpoint();
    const result = await createIndirect(req);

    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getIndirectsForWeekAndClinicId = async (
  req: IGetIndirectsForWeekAndClinicIdEndpointRequest
): Promise<IIndirect[]> => {
  try {
    const getIndirectsForWeekAndClinicId =
      ENDPOINTS.getGetIndirectForWeekAndClinicIdEndpoint();
    const indirects = await getIndirectsForWeekAndClinicId(req);

    return indirects;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteIndirect = async (req: IDeleteIndirectEndpointRequest) => {
  try {
    const deleteIndirect = ENDPOINTS.getDeleteIndirectEndpoint();
    const result = await deleteIndirect(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateIndirect = async (req: IUpdateIndirectEndpointRequest) => {
  try {
    const updateIndirect = ENDPOINTS.getUpdateIndirectEndpoint();
    const result = await updateIndirect(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createCompletedAppointment = async (
  req: ICreateCompletedAppointmentEndpointRequest
): Promise<string> => {
  try {
    const createCompletedAppointment =
      ENDPOINTS.getCreateCompletedAppointmentEndpoint();
    const result = await createCompletedAppointment(req);

    return Promise.resolve(result.id);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createCancelledCompletedAppointment = async (
  req: ICreateCancelledCompletedAppointmentEndpointRequest
): Promise<string> => {
  try {
    const createCancelledCompletedAppointment =
      ENDPOINTS.getCreateCancelledCompletedAppointmentEndpoint();
    const result = await createCancelledCompletedAppointment(req);

    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCompletedAppointmentByClinicAndRange = async (
  req: IGetCompletedAppointmentsForClinicAndRangeEndpointRequest
): Promise<ICompletedAppointment[]> => {
  try {
    const getCompletedAppointmentByClinicAndRange =
      ENDPOINTS.getGetForClinicAndRangeCompletedAppointments();
    const result = await getCompletedAppointmentByClinicAndRange(req);

    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCompletedAppointmentByClinicClientAndRange = async (
  req: IGetCompletedAppointmentsForClinicClientAndRangeEndpointRequest
): Promise<ICompletedAppointment[]> => {
  try {
    const getCompletedAppointments =
      ENDPOINTS.getGetForClinicClientAndRangeCompletedAppointments();
    const result = await getCompletedAppointments(req);

    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllBillableCompletedAppointmentsByClinicId = async (
  clinicId: string
): Promise<ICompletedAppointment[]> => {
  const result = await db
    .collection('completed-appointments')
    .where('clinicId', '==', clinicId)
    .where('isBilled', '==', false)
    .orderBy('startMs', 'desc')
    .get();

  const unbilledCompletedAppointments = result.docs.reduce((acc, doc) => {
    const completedAppointment = doc.data() as ICompletedAppointment;
    if (
      !completedAppointment?.deletedAt &&
      !completedAppointment?.cancelledAt
    ) {
      acc.push(completedAppointment);
    }
    return acc;
  }, [] as Array<ICompletedAppointment>);

  return unbilledCompletedAppointments;
};

export const updateCompletedAppointment = async (
  req: IUpdateCompletedAppointmentEndpointRequest
): Promise<ICompletedAppointment> => {
  try {
    const updateCompletedAppointment =
      ENDPOINTS.getUpdateCompletedAppointmentEndpoint();
    const result = await updateCompletedAppointment(req);

    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCompletedAppointment = async (
  req: IDeleteCompletedAppointmentEndpointRequest
) => {
  try {
    const deleteCompletedAppointment =
      ENDPOINTS.getDeleteCompletedAppointmentEndpoint();
    const result = await deleteCompletedAppointment(req);

    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const splitAppointmentByNoteAndAppointment = async (
  note: INote,
  appointment: IAppointment
): Promise<string> => {
  try {
    //create appointment
    const req: ICalendarCreateAppointmentEndpointRequest = {
      clinicId: note.clinicId,
      clientId: note.clientId,
      attendeeEmails: appointment.attendees.map((a) => a.email),
      billingCode: note.billingCode as BillingCode,
      modifiers: note.modifiers as Modifier[],
      location: note.location,
      summary: appointment.summary,
      description: appointment.description,
      startMs: note.startMs,
      endMs: note.endMs,
      timeZone: moment.tz.guess(), //note.startMs & note.endMs are stored in utc
      //skip RRule as it's not needed for single appointment
      hasMeets: appointment.meetsLink ? true : false,
    };
    const appointmentId = await createAppointment(req);

    //update note with appointmentId
    const successfulUpdate = await updateNote({
      id: note.id,
      appointmentId: appointmentId,
      manualOverride: true,
    });

    //return newly created appointmentId
    return Promise.resolve(appointmentId);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const reloadNoteById = async (id: string): Promise<INote> => {
  try {
    const getMotivityNote = await ENDPOINTS.getGetMotivityNoteEndpoint();
    const note = await getMotivityNote({ noteIds: [id] });
    return Promise.resolve(note);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAllBilledAppointmentsByClinicIdAndRange = async (
  req: IGetBilledAppointmentsForClinicAndRangeEndpointRequest
) => {
  try {
    const getAllBilledAppointmentsByClinicIdAndRange =
      ENDPOINTS.getGetBilledAppointmentsForClinicAndRangeEndpoint();
    const billedAppointments = await getAllBilledAppointmentsByClinicIdAndRange(
      req
    );

    return billedAppointments;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const batchBill = async (req: IBatchBillEndpointRequest) => {
  try {
    const batchBill = ENDPOINTS.getBatchBillEndpoint();
    const result = await batchBill(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOAuthConsentURL = async () => {
  try {
    const oauthGet = ENDPOINTS.getOAuthURL();
    const result = await oauthGet({});

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postOAuthCode = async (req: IOAuthPostEndpointRequest) => {
  try {
    const oauthPost = ENDPOINTS.getPostOAuthCode();
    const result = await oauthPost(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllInvitesForClinic = async (clinicId: string) => {
  const result = await db
    .collection('invites')
    .where('clinicId', '==', clinicId)
    .get();
  let invites: IInvite[] = [];
  result.forEach((doc) => invites.push(doc.data() as IInvite));

  invites = invites.filter((user) => !user?.deletedAt);
  if (invites.length === 0) {
    console.log(`No invites found for clinic (${clinicId})`);
  }
  return invites as IInvite[];
};

export const createInvite = async (req: ICreateInviteRequest) => {
  try {
    const createInvite = ENDPOINTS.getCreateInviteEndpoint();
    const result = await createInvite(req).catch((error) => {
      throw error;
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateInvite = async (req: IUpdateInviteEndpointRequest) => {
  try {
    const updateInvite = ENDPOINTS.getUpdateInviteEndpoint();
    const result = await updateInvite(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteInvite = async (req: IDeleteInviteEndpointRequest) => {
  try {
    const deleteInvite = ENDPOINTS.getDeleteInviteEndpoint();
    const result = await deleteInvite(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createClientFromScratch = async (
  req: ICreateClientFromScratchEndpointRequest
) => {
  try {
    const createClientFromScratch =
      ENDPOINTS.getCreateClientFromScratchEndpoint();
    const result = await createClientFromScratch(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInvites = async () => {
  try {
    const getInvitesList = ENDPOINTS.getInvitesByAuthEmail();
    const result = await getInvitesList({});

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersWithExpiredCredentialsForClinic = async (
  clinicId: string
) => {
  try {
    const users = await getAllUsersForClinic(clinicId);
    const now = moment().valueOf();
    const result = users.filter((user) =>
      user.credentials?.some((credential) => now >= credential.expiryMs)
    );

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersWithWarningCredentialsForClinic = async (
  clinicId: string
) => {
  try {
    const users = await getAllUsersForClinic(clinicId);
    const now = moment().valueOf();
    const result = users.filter((user) =>
      user.credentials?.some(
        (credential) => now >= credential.expiryWarningOffsetMs
      )
    );

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};
