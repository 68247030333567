import React, { useState } from 'react';
import {
  Badge,
  Button,
  Card,
  message,
  Row,
  Select,
  Skeleton,
  TimePicker,
  Tooltip,
} from 'antd';
import {
  stringToColor,
  IAppointment,
  IUser,
  AttendeeStatus,
  IClientFile,
  ICalendarCreateAppointmentEndpointRequest,
  IClient,
  BillingCode,
  Modifier,
  AppointmentLocation,
  getAppointmentSummary,
  Weekday,
} from '@finni-health/atlas-shared';
import * as FirestoreService from '../../services/firestore';
import { StopOutlined } from '@ant-design/icons';
import { COLORS, DISPLAY_TIME_FORMAT, ERROR_MESSAGE } from '../../consts';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { RiSaveFill } from 'react-icons/ri';

interface IProps {
  therapists: IUser[];
  client: IClient;
  clientFile: IClientFile;
  weekday: Weekday;
  refreshCallback: () => Promise<IAppointment[]>;
  cancelAddScheduleBlock: () => void;
}

const DEFAULT_TIME = moment('8:00am', DISPLAY_TIME_FORMAT);

export const AddScheduleBlock: React.FC<IProps> = ({
  therapists,
  client,
  clientFile,
  weekday,
  refreshCallback,
  cancelAddScheduleBlock,
}: IProps) => {
  const [tempAppointment, setTempAppointment] = useState<IAppointment>(
    {} as IAppointment
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDiscardChanges = () => {
    setTempAppointment({} as IAppointment);
    cancelAddScheduleBlock();
  };

  const handleSave = async () => {
    if (_.isEmpty(tempAppointment.attendees)) {
      message.error('Please select a therapist');
      return;
    }
    if (!tempAppointment.startMs || !tempAppointment.endMs) {
      message.error('Please enter start and end times');
      return;
    }

    setIsLoading(true);

    const yearWeek = document.URL.split('/').slice(-1)[0];
    const year = +yearWeek.split('-')[0];
    const week = +yearWeek.split('-')[1];

    const startDatetime = moment(tempAppointment.startMs)
      .year(year)
      .week(week)
      .day(weekday);
    const endDatetime = moment(tempAppointment.endMs)
      .year(year)
      .week(week)
      .day(weekday);

    //assume auth ends in 6 months from this date
    const authEndDate = moment(endDatetime).add(6, 'M');

    // End time rolled over to the next day in UTC
    if (endDatetime.isBefore(startDatetime)) {
      endDatetime.add(1, 'days');
    }

    const startMs = startDatetime.valueOf();
    const endMs = endDatetime.valueOf();

    const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest =
      {
        clinicId: client.clinicId,
        clientId: clientFile.clientId,
        attendeeEmails: [tempAppointment.attendees[0].email],
        billingCode: BillingCode.CODE_97153,
        modifiers: [Modifier.U1],
        location: AppointmentLocation.HOME,
        summary: getAppointmentSummary(
          client,
          BillingCode.CODE_97153,
          AppointmentLocation.HOME,
          Modifier.U1
        ),
        description: 'Ongoing session created by Atlas.',
        startMs,
        endMs,
        timeZone: moment.tz.guess(),
        rrule: `RRULE:FREQ=WEEKLY;BYDAY=${weekday
          .slice(0, 2)
          .toUpperCase()};UNTIL=${authEndDate.format('YYYYMMDD')}T000000Z`,
      };

    try {
      await FirestoreService.createAppointment(createAppointmentRequest);
      await refreshCallback();
      cancelAddScheduleBlock();
      message.success('Appointments created for 6 months');
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsLoading(false);
  };

  const handleSelectTherapist = (email: string) => {
    const newAppointment = _.cloneDeep(tempAppointment);
    newAppointment.attendees = [{ email, status: AttendeeStatus.ACCEPTED }];
    setTempAppointment(newAppointment);
  };

  const handleTimeSelect = (key: string) => {
    return (datetime: Moment | null) => {
      if (
        key === 'endMs' &&
        datetime &&
        tempAppointment.startMs >= datetime?.valueOf()
      ) {
        message.warn("End time can't be before start");
        return;
      }

      if (datetime) {
        const newAppointment = _.cloneDeep(tempAppointment);
        _.set(newAppointment, key, datetime.valueOf());
        setTempAppointment(newAppointment);
      }
    };
  };

  return (
    <Card
      size="small"
      bodyStyle={{ paddingTop: 5, paddingBottom: 7 }}
      style={{
        marginBottom: 3,
        borderColor: COLORS.PRIMARY,
      }}
    >
      {isLoading ? (
        <Row justify="center" align="middle" style={{ height: 135 }}>
          <Skeleton />
        </Row>
      ) : (
        <>
          <Row justify="space-between">
            <Tooltip title="Cancel">
              <Button
                icon={<StopOutlined style={{ fontSize: 12 }} />}
                size="small"
                type="text"
                onClick={handleDiscardChanges}
              />
            </Tooltip>
          </Row>
          <Row style={{ width: '100%', marginBottom: 5 }}>
            <Select
              allowClear
              showSearch
              placeholder="Therapist"
              optionFilterProp="key"
              style={{ width: '100%' }}
              suffixIcon={<></>}
              onSelect={handleSelectTherapist}
            >
              {therapists.map((therapist) => (
                <Select.Option
                  key={`${therapist.firstName} ${therapist.lastName}`}
                  value={therapist.email}
                >
                  <Badge
                    color={stringToColor(therapist.id)}
                    text={`${therapist.firstName} ${therapist.lastName}`}
                  />
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row style={{ width: '100%', marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="Start"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              defaultOpenValue={DEFAULT_TIME}
              value={
                tempAppointment.startMs
                  ? moment(tempAppointment.startMs)
                  : undefined
              }
              style={{ width: '100%' }}
              onSelect={handleTimeSelect('startMs')}
              onChange={handleTimeSelect('startMs')}
            />
          </Row>
          <Row style={{ width: '100%', marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="End"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              defaultOpenValue={
                tempAppointment.startMs
                  ? moment(tempAppointment.startMs).add(1, 'hour')
                  : DEFAULT_TIME
              }
              value={
                tempAppointment.endMs
                  ? moment(tempAppointment.endMs)
                  : undefined
              }
              style={{ width: '100%' }}
              onSelect={handleTimeSelect('endMs')}
              onChange={handleTimeSelect('endMs')}
            />
          </Row>
          <Row>
            <Tooltip title="Save">
              <Button
                style={{ marginLeft: 'auto', marginRight: 6, marginTop: 5 }}
                icon={
                  <RiSaveFill
                    style={{
                      position: 'relative',
                      top: 3,
                      fontSize: 16,
                      marginRight: 5,
                      color: 'white',
                    }}
                  />
                }
                type="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Tooltip>
          </Row>
        </>
      )}
    </Card>
  );
};
