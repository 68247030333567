import { Button, Form, Input, Typography, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  SignUpStates,
  PHONE_NUMBER_PLACEHOLDER,
  VALID_PHONE_NUMBER_REGEX,
} from '../../consts';
import Lottie from 'react-lottie-player';
import foxCelebrateAnimation from '../../lottie/fox-celebrate.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';
import { PhoneNumberInput } from '../PhoneNumberInput';

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
  invite: any;
};

export const EnterPhoneNumber: React.FC<IProps> = ({
  setInquiryStatus,
  updateInquiry,
  invite,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(isScreenMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  const submitPhoneNumber = () => {
    setInquiryStatus(SignUpStates.ENTER_PERSONAL_ADDRESS);
  };

  return (
    <>
      <Title
        style={{ fontFamily: 'Reckless Neue' }}
      >{`What is your phone number?`}</Title>
      <Text
        style={{
          display: 'block',
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {`We take your privacy seriously. Your details will only be stored for use by ${invite.clinicName} users.`}
      </Text>
      <Form onFinish={() => submitPhoneNumber()}>
        <Form.Item
          name="phoneNumberPrimary"
          rules={[
            {
              required: true,
              pattern: VALID_PHONE_NUMBER_REGEX,
              message: 'Please provide a valid Phone Number',
            },
          ]}
        >
          <PhoneNumberInput
            onChange={(value: any) => {
              updateInquiry({ phoneNumber: value.target.value });
            }}
            placeholder={PHONE_NUMBER_PLACEHOLDER}
            style={{ width: 250, marginLeft: 6 }}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            height: 44,
          }}
        >
          Continue
        </Button>
      </Form>
    </>
  );
};
