import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from 'antd';
import {
  CountryCode,
  Sex,
  USStateCode,
  Credential,
  ICredential,
} from '@finni-health/atlas-shared';
import * as FirestoreService from '../services/firestore';
import { AuthContext } from '../components/AuthProvider';
import _ from 'lodash';
import { Loading } from './Loading';
import moment, { Moment } from 'moment';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import {
  DB_DATE_FORMAT,
  ERROR_MESSAGE,
  VALID_FINNI_EMAIL_REGEX,
  VALID_ZIP_CODE_REGEX,
} from '../consts';
import { CredentialInput } from '../components/CredentialInput';

const { TextArea } = Input;

interface IUserProfileFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: Moment;
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
  country: CountryCode;
  addressNotes: string;
  npi: string;
  credentials: ICredential[];
}

export const UserProfile: React.FC = () => {
  const { user, refresh: refreshContext } = useContext(AuthContext);

  const [userForm] = Form.useForm<IUserProfileFormValues>();
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!_.isEmpty(user)) {
      setIsLoading(false);
    }
  }, [user]);

  const isNewPasswordValid = () => {
    return newPassword.length >= 6;
  };

  const isNewPasswordConfirmed = () => {
    return !_.isEmpty(newPasswordConfirm) && newPassword === newPasswordConfirm;
  };

  const saveUser = async () => {
    setIsSaving(true);

    const values = userForm.getFieldsValue();
    const credentials = userForm.getFieldValue('credentials'); //arrays don't work properly with getFieldsValue

    try {
      await FirestoreService.updateUser({
        id: user.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        sex: Sex.UNKNOWN,
        dateOfBirth: moment(values.dateOfBirth).format(DB_DATE_FORMAT),
        address: {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
        },
        addressNotes: values.addressNotes,
        npi: values.npi,
        credentials,
      });
      message.success(`Profile successfully updated`);
      refreshContext();
    } catch (error) {
      console.error(error);
      message.error(
        'Error updating user, please check that all required fields are filled and correct'
      );
    }

    setIsSaving(false);
  };

  const handleUpdateCredential = (credential: ICredential) => {
    if (credential) {
      const currCredentials = userForm.getFieldValue('credentials') || [];
      const credIndex = currCredentials?.findIndex(
        (cred: ICredential) => cred.type === credential.type
      );
      if (credIndex === -1) {
        currCredentials.push(credential);
      } else {
        currCredentials[credIndex] = credential;
      }
      userForm.setFieldValue('credentials', currCredentials);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row style={{ margin: 'auto', maxWidth: 800 }}>
        <Col style={{ width: '100%' }}>
          <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            form={userForm}
            onFinish={saveUser}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={isSaving}
                style={{ float: 'right' }}
              >
                Save Changes
              </Button>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Divider orientation="left">Profile Information</Divider>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  initialValue={user.firstName}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  initialValue={user.lastName}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  initialValue={user.phoneNumber}
                >
                  <PhoneNumberInput disabled={isSaving} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date of Birth"
                  name="dateOfBirth"
                  initialValue={moment(user.dateOfBirth)}
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    allowClear={false}
                    autoComplete="off"
                    disabledDate={(curr) =>
                      curr && curr > moment().startOf('day')
                    }
                    disabled={isSaving}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">Address</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Address Line 1"
                  name="line1"
                  initialValue={user.address?.line1}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Address Line 2"
                  name="line2"
                  initialValue={user.address?.line2}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="City"
                  name="city"
                  initialValue={user.address?.city}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="State"
                  name="state"
                  initialValue={user.address?.state}
                >
                  <Select showSearch disabled={isSaving}>
                    {Object.values(USStateCode).map((state) => (
                      <Select.Option value={state} key={state}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Zip Code"
                  name="zipCode"
                  initialValue={user.address?.zipCode}
                  rules={[
                    {
                      pattern: VALID_ZIP_CODE_REGEX,
                      message: 'Please enter a 5 digit zip code',
                    },
                  ]}
                >
                  <Input disabled={isSaving} maxLength={5} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Country"
                  name="country"
                  initialValue={CountryCode.US}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Address Notes"
                  name="addressNotes"
                  initialValue={user.addressNotes}
                >
                  <TextArea autoSize={true} disabled={isSaving} />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">Credentials</Divider>
            <Col span={24}>
              <Form.Item label="NPI" name="npi" initialValue={user.npi}>
                <TextArea autoSize={true} disabled={isSaving} />
              </Form.Item>
              {Object.values(Credential).map((credentialType) => (
                <Form.Item
                  label={credentialType}
                  name={credentialType}
                  initialValue={user?.credentials?.find(
                    (cred) => cred.type === credentialType
                  )}
                >
                  <CredentialInput
                    autoSize={true}
                    disabled={isSaving}
                    type={credentialType}
                    credential={user?.credentials?.find(
                      (cred) => cred.type === credentialType
                    )}
                    updateCredential={handleUpdateCredential}
                  />
                </Form.Item>
              ))}
            </Col>
          </Form>
        </Col>
      </Row>
    </>
  );
};
