import { IAddress } from '@finni-health/atlas-shared';

export const formatAddress = ({
  line1,
  line2,
  city,
  state,
  zipCode,
  country,
}: IAddress) => {
  let address = '';
  if (!line1 && !line2) return address;
  if (line1) address += line1;
  if (line2) address += ` ${line2}`;
  if (city && state && zipCode) address += `,\n${city}, ${state} ${zipCode}`;
  if (country) address += `\n${country}`;
  return address;
};
