import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { SignUpStates, VALID_ZIP_CODE_REGEX } from '../../consts';
import Lottie from 'react-lottie-player';
import foxCelebrateAnimation from '../../lottie/fox-celebrate.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
};

export const EnterPracticeName: React.FC<IProps> = ({
  setInquiryStatus,
  updateInquiry,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(isScreenMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  const submitClinicName = () => {
    setInquiryStatus(SignUpStates.ENTER_PRACTICE_STATE);
  };

  return (
    <>
      <div style={{ marginTop: isMobile ? -20 : -60, marginBottom: 40 }}>
        <Lottie
          play
          loop={false}
          animationData={foxCelebrateAnimation}
          style={{
            width: 125,
            height: 125,
            margin: 'auto',
            marginTop: -70,
            marginBottom: 20,
          }}
        />
      </div>
      <Title
        style={{ fontFamily: 'Reckless Neue' }}
      >{`Get Atlas for your practice!`}</Title>
      <Text
        style={{
          display: 'block',
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        What is your practice called?
      </Text>
      <Form onFinish={() => submitClinicName()}>
        <Form.Item
          name="clinicName"
          rules={[
            {
              required: true,
              message: `Please enter your practice name`,
            },
          ]}
          style={{ display: 'inline-block', width: 200, margin: 'auto' }}
        >
          <Input
            name="clinicName"
            onChange={(value) => {
              updateInquiry({ clinicName: value.target.value });
            }}
            style={{
              textAlign: 'center',
              borderRadius: 50,
              padding: 10,
            }}
            placeholder="Doe's Practice"
          />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            marginLeft: 20,
            height: 44,
          }}
        >
          Get Started
        </Button>
      </Form>
    </>
  );
};
