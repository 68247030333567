import { Modal, Space, Row } from 'antd';
import _ from 'lodash';
import * as FirestoreService from '../services/firestore';
import { IClientFile, IGuardian } from '@finni-health/atlas-shared';
import { ClientPayerCard } from './ClientPayerCard';
import { useEffect, useState } from 'react';

interface IProps {
  guardian: IGuardian;
  hideModal: () => void;
  isVisible: boolean;
}

export const InsuranceCardModal = ({
  guardian,
  hideModal,
  isVisible,
}: IProps) => {
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);

  const fetchClientFile = async () => {
    const clientFile = await FirestoreService.getClientFileByGuardianId(
      guardian.id,
      guardian.clinicId
    );

    setClientFile(clientFile);
  };

  useEffect(() => {
    if (!_.isEmpty(guardian)) {
      fetchClientFile();
    }
  });

  return (
    <Modal
      title={`Insurance for ${guardian.firstName} ${guardian.lastName}`}
      onCancel={hideModal}
      open={isVisible}
      width={475}
      style={{ top: 20 }}
      footer={null}
    >
      <Row justify="center" style={{ width: '100%' }}>
        {!_.isEmpty(clientFile.payers) && (
          <Space direction="vertical" size={'middle'}>
            {clientFile.payers.primary && (
              <ClientPayerCard
                clientId={clientFile.clientId}
                clientPayer={clientFile.payers.primary}
              />
            )}
            {clientFile.payers.secondary && (
              <ClientPayerCard
                clientId={clientFile.clientId}
                clientPayer={clientFile.payers.secondary}
              />
            )}
          </Space>
        )}
      </Row>
    </Modal>
  );
};
