import React, { useEffect, useState } from 'react';
import firebase from '../services/firebase';
import * as FirestoreService from '../services/firestore';
import { IClinic, IUser } from '@finni-health/atlas-shared';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export const AuthContext = React.createContext({} as Context);

interface Context {
  user: IUser;
  clinic: IClinic;
  refresh: () => void;
}

export const AuthProvider = ({ children }: any) => {
  const [key, setKey] = useState<string>('');
  const [context, setContext] = useState({
    user: {},
    clinic: {},
  } as Context);

  const refresh = () => {
    setKey(uuidv4());
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser && !_.isEmpty(firebaseUser)) {
        const uid = firebaseUser.uid;

        const fetchContext = async () => {
          try {
            const user = await FirestoreService.getUserById(uid);
            const clinic = await FirestoreService.getClinicById(user.clinicId);

            setContext({ user, clinic, refresh });
          } catch (err) {
            if (uid) {
              setContext({
                user: {
                  uid,
                  email: firebaseUser.email,
                  name: firebaseUser.displayName,
                } as any as IUser,
                clinic: {} as any as IClinic,
                refresh,
              });
              //Oauth sign up flow not complete
              const redirectUrl = window.location.origin + '/signup';
              if (window.location.href !== redirectUrl) {
                window.location.replace(redirectUrl);
              }
            } else {
              console.log(`Error setting auth: ${err}`);
            }
          }
        };

        fetchContext();
      } else {
        setContext({
          user: {} as any as IUser,
          clinic: {} as any as IClinic,
          refresh,
        });
      }
    });
  }, [key]);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
