import { Row, Anchor, Col, Button, message, Form } from 'antd';
import _ from 'lodash';
import { IClientFile } from '@finni-health/atlas-shared';
import { useEffect, useState } from 'react';
import * as FirestoreService from '../../services/firestore';
import { ClientProvidersSection } from './ClientProvidersSection';
import { ClientTherapyPreferencesSection } from './ClientTherapyPreferencesSection';
import { ClientBehaviorsSection } from './ClientBehaviorsSection';
import { ClientMedicalHistorySection } from './ClientMedicalHistorySection';
import { ERROR_MESSAGE } from '../../consts';

const { Link } = Anchor;

interface Props {
  clientFile: IClientFile;
  refreshCallback: () => Promise<void>;
}

export const ClientIntakeForm = ({ clientFile, refreshCallback }: Props) => {
  const [tempClientFile, setTempClientFile] = useState<IClientFile>(
    {} as IClientFile
  );
  const [isEditingDisabled, setIsEditingDisabled] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setTempClientFile(_.cloneDeep(clientFile));
  }, [clientFile]);

  const updateTempClientFile = (source: Partial<IClientFile>) => {
    const merged = _.merge(_.cloneDeep(tempClientFile), source);
    setTempClientFile(merged);
  };

  const handleCancelEditing = async () => {
    setIsSaving(true);
    await refreshCallback();
    setIsEditingDisabled(true);
    setIsSaving(false);
  };

  const handleSaveClientFile = async () => {
    setIsSaving(true);
    try {
      await FirestoreService.updateClientFile(tempClientFile);
      await refreshCallback();
      setIsEditingDisabled(true);
      message.success('Changes saved');
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Row gutter={24}>
      <Col span={20}>
        <Form layout="vertical" labelCol={{ span: 24 }}>
          <ClientProvidersSection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
          />
          <ClientTherapyPreferencesSection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
            setTempClientFile={setTempClientFile}
          />
          <ClientBehaviorsSection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
            setTempClientFile={setTempClientFile}
          />
          <ClientMedicalHistorySection
            isEditingDisabled={isEditingDisabled}
            tempClientFile={tempClientFile}
            updateTempClientFile={updateTempClientFile}
            setTempClientFile={setTempClientFile}
          />
        </Form>
      </Col>
      <Col span={4}>
        <div style={{ position: 'sticky', top: 0 }}>
          <Anchor>
            {isEditingDisabled && (
              <Button
                type="primary"
                loading={isSaving}
                style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
                disabled={!isEditingDisabled}
                onClick={() => {
                  setIsEditingDisabled(false);
                }}
              >
                Edit Intake
              </Button>
            )}
            {!isEditingDisabled && (
              <Row>
                <Button
                  disabled={isSaving}
                  style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
                  onClick={handleCancelEditing}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  loading={isSaving}
                  disabled={_.isEqual(tempClientFile, clientFile)}
                  style={{ marginLeft: 16, marginBottom: 10, marginTop: 10 }}
                  onClick={handleSaveClientFile}
                >
                  Save
                </Button>
              </Row>
            )}
            <Link href="#providers" title="Providers">
              <Link href="#diagnostician" title="Diagnostician" />
              <Link href="#pediatrician" title="Pediatrician" />
              <Link href="#diagnosisReports" title="DiagnosisReports" />
            </Link>
            <Link href="#therapyPreferences" title="Therapy Preferences">
              <Link
                href="#primaryTherapyAddress"
                title="Primary Therapy Address"
              />
              <Link href="#language" title="Language" />
              <Link href="#genderPreference" title="Gender Preference" />
              <Link href="#availability" title="Availability" />
            </Link>
            <Link href="#behaviors" title="Behaviors">
              <Link href="#selfHarm" title="Self Harm" />
              <Link href="#aggression" title="Aggression" />
              <Link href="#runAway" title="Run Away" />
              <Link href="#destroyProperty" title="Destroy Property" />
              <Link href="#flopOnGround" title="Flop On Ground" />
              <Link href="#takeOffClothes" title="Take Off Clothes" />
              <Link href="#goals" title="Goals" />
            </Link>
            <Link href="#medicalHistory" title="Medical History">
              <Link
                href="#diagnosesAndMedications"
                title="Diagnoses/Medications"
              />
              <Link href="#family" title="Family" />
              <Link href="#services" title="Services" />
              <Link href="#impairments" title="Impairments" />
            </Link>
          </Anchor>
        </div>
      </Col>
    </Row>
  );
};
