import * as FirestoreService from '../../services/firestore';
import { Modal, Button, Form, Row, message, Tabs, Spin, Space } from 'antd';
import _ from 'lodash';
import { useEffect, useState, useContext } from 'react';
import { ERROR_MESSAGE, DB_DATE_FORMAT } from '../../consts';
import {
  IClient,
  IGuardian,
  IClientFile,
  ICreateClientFromScratchEndpointRequest,
  IntakeStatus,
  IPhoneNumber,
  InquiryStatus,
  Sex,
  IAddress,
} from '@finni-health/atlas-shared';
import { BasicInfoForm } from './BasicInfoForm';
import { ClientInfoForm } from './ClientInfoForm';
import { AuthContext } from '../AuthProvider';
import moment from 'moment';

export const enum EDIT_CLIENT_FILE_MODAL_TABLE_TABS {
  CLIENT = 'client',
  GUARDIAN = 'guardian',
}

interface Props {
  clientFile: IClientFile;
  refreshCallback: () => Promise<void>;
  hideModal: () => void;
  isVisible: boolean;
  defaultTab?: EDIT_CLIENT_FILE_MODAL_TABLE_TABS;
}

export const CreateClientModal = ({
  clientFile,
  refreshCallback,
  hideModal,
  isVisible,
  defaultTab,
}: Props) => {
  const user = useContext(AuthContext).user;

  const [client, setClient] = useState<IClient>({} as IClient);
  const [tempClient, setTempClient] = useState<IClient>({} as IClient);

  const [guardian, setGuardian] = useState<IGuardian>({} as IGuardian);
  const [tempGuardian, setTempGuardian] = useState<IGuardian>({} as IGuardian);

  const [tempClientFile, setTempClientFile] = useState<IClientFile>(
    {} as IClientFile
  );

  const [selectedTab, setSelectedTab] =
    useState<EDIT_CLIENT_FILE_MODAL_TABLE_TABS>(
      defaultTab || EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT
    );
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const updateTempGuardian = (source: Partial<IGuardian>) => {
    const merged = _.merge(_.cloneDeep(tempGuardian), source);
    setTempGuardian(merged);
  };

  const updateTempClient = (source: Partial<IClient>) => {
    const merged = _.merge(_.cloneDeep(tempClient), source);
    setTempClient(merged);
  };

  const updateTempClientFile = (source: Partial<IClientFile>) => {
    const merged = _.merge(_.cloneDeep(tempClientFile), source);
    setTempClientFile(merged);
  };

  const handleHideModal = () => {
    setTempClient({} as IClient);
    setTempGuardian({} as IGuardian);
    setTempClientFile({} as IClientFile);
    setClient({} as IClient);
    setGuardian({} as IGuardian);
    hideModal();
  };

  const handleSave = async () => {
    // if
    setIsSaving(true);

    try {
      const createClientFromScratchRequest: ICreateClientFromScratchEndpointRequest =
        {
          clinicId: user.clinicId,
          clientFirstName: tempClient.firstName,
          clientMiddleName: tempClient.middleName || '',
          clientLastName: tempClient.lastName,
          clientAlias: tempClient.alias,
          clientDateOfBirth:
            tempClient.dateOfBirth || moment().format(DB_DATE_FORMAT),
          clientSex: tempClient.sex || Sex.UNKNOWN,
          guardianFirstName: tempGuardian.firstName || 'No',
          guardianMiddleName: tempGuardian.middleName || '',
          guardianLastName: tempGuardian.lastName || 'Guardian',
          guardianEmail: tempGuardian.email || '',
          guardianPhoneNumber: tempGuardian.phoneNumber || '',
          guardianAddress: tempClientFile.address || ({} as IAddress),
          guardianAddressNotes: '',

          //default to IN_PROCESS & hot for now
          intakeStatus: IntakeStatus.IN_PROCESS,
          isHot: true,
        };
      const result = await FirestoreService.createClientFromScratch(
        createClientFromScratchRequest
      );

      await refreshCallback();
      message.success(`Client ${tempClient.alias} created successfully`);
      handleHideModal();
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title={
        client && !_.isEmpty(client)
          ? `Edit File for ${client.alias}`
          : 'Create New Client'
      }
      onCancel={handleHideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      {isLoading ? (
        <Row justify="center" align="middle">
          <Spin size="large" />
        </Row>
      ) : (
        <Form layout="vertical" labelCol={{ span: 24 }} onFinish={handleSave}>
          <Tabs
            style={{ marginTop: -15 }}
            activeKey={selectedTab}
            onChange={(key) => {
              setSelectedTab(key as EDIT_CLIENT_FILE_MODAL_TABLE_TABS);
            }}
          >
            <Tabs.TabPane
              tab="Client"
              key={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
            >
              <ClientInfoForm
                tempClient={tempClient}
                updateTempClient={updateTempClient}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Guardian"
              key={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.GUARDIAN}
            >
              <BasicInfoForm
                clientFile={clientFile}
                tempClientFile={tempClientFile}
                updateTempClientFile={updateTempClientFile}
                tempGuardian={tempGuardian}
                updateTempGuardian={updateTempGuardian}
              />
            </Tabs.TabPane>
          </Tabs>
          <Row justify="end" style={{ marginTop: 15 }}>
            <Space>
              <Button disabled={isSaving} onClick={handleHideModal}>
                Cancel
              </Button>
              <Button
                loading={isSaving}
                type="primary"
                htmlType="submit"
                disabled={
                  _.isEqual(tempGuardian, guardian) &&
                  _.isEqual(tempClient, client) &&
                  _.isEqual(tempClientFile, clientFile)
                }
              >
                Create
              </Button>
            </Space>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
