import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Skeleton, Tag, Typography } from 'antd';
import {
  stringToColor,
  IClient,
  IClientPayer,
  IPayer,
} from '@finni-health/atlas-shared';
import * as FirestoreService from '../services/firestore';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { COLORS, DISPLAY_DATE_FORMAT } from '../consts';
import moment from 'moment';

const { Text } = Typography;

interface IProps {
  clientId: string;
  clientPayer: IClientPayer;
}

export const ClientPayerCard: React.FC<IProps> = ({
  clientId,
  clientPayer,
}: IProps) => {
  const [client, setClient] = useState<IClient>({} as IClient);
  const [payer, setPayer] = useState<IPayer>({} as IPayer);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = async () => {
    const client = await FirestoreService.getClientById(clientId);
    const payer = await FirestoreService.getPayerById(clientPayer.payerId);

    setClient(client);
    setPayer(payer);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [clientPayer]);

  // blaze it up lol
  return (
    <Card size="small" style={{ borderRadius: 10, borderWidth: 5, width: 420 }}>
      <Row justify="center">
        <Row
          justify="space-between"
          align="middle"
          style={{ width: '100%', flexWrap: 'nowrap' }}
        >
          <Skeleton
            loading={isLoading}
            style={{ width: 100 }}
            paragraph={false}
          >
            <Tag
              color={stringToColor(payer.name || '')}
              style={{ fontSize: 15, lineHeight: 1.4 }}
            >
              {payer.name}
            </Tag>
          </Skeleton>
          <Text style={{ fontSize: 15, textAlign: 'right' }}>
            <strong>Coverage for: </strong>
            {client.alias}
            <br />
            <strong>DOB: </strong>
            {client.dateOfBirth
              ? moment(client.dateOfBirth).format(DISPLAY_DATE_FORMAT)
              : ''}
          </Text>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row justify="space-between" style={{ width: '100%' }}>
          <Col style={{ textOverflow: 'ellipsis' }}>
            <Text>
              <strong>Member Name: </strong>
              {clientPayer.policyHolder?.firstName || ''}{' '}
              {clientPayer.policyHolder?.lastName || ''}
            </Text>
          </Col>
          <Col style={{ textOverflow: 'ellipsis' }}>
            <Text>
              <strong>Deductible: </strong>${clientPayer.deductible || 0}
            </Text>
          </Col>
        </Row>
        <Row justify="space-between" style={{ width: '100%' }}>
          <Col style={{ textOverflow: 'ellipsis' }}>
            <Text>
              <strong>Member ID: </strong>
              {clientPayer.memberNum || ''}
            </Text>
          </Col>
          <Col style={{ textOverflow: 'ellipsis' }}>
            <Text>
              <strong>Copay: </strong>${clientPayer.copay || 0}
            </Text>
          </Col>
        </Row>
        <Row justify="space-between" style={{ width: '100%' }}>
          <Text>
            <strong>Group Number: </strong>
            {clientPayer.groupNum || ''}
          </Text>
        </Row>
        <Row justify="space-between" style={{ marginTop: 15, width: '80%' }}>
          <Text>
            <strong>Pre-Auth Number: </strong>
          </Text>
          <Text>{clientPayer.preAuthPhone || ''}</Text>
        </Row>
        <Row justify="space-between" style={{ width: '80%' }}>
          <Text>
            <strong>Customer Service Number: </strong>
          </Text>
          <Text>{clientPayer.customerServicePhone || ''}</Text>
        </Row>
        <Row justify="center" style={{ width: '100%', marginTop: 15 }}>
          <Col>
            <Text>Covers ABA?</Text>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {clientPayer.coversAba ? (
                <CheckCircleOutlined
                  style={{ fontSize: 16, color: COLORS.PRIMARY }}
                />
              ) : (
                <CloseCircleOutlined style={{ fontSize: 16 }} />
              )}
            </div>
          </Col>
        </Row>
      </Row>
    </Card>
  );
};
