import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  AttendeeStatus,
  getSessionNameFromBilling,
  IAppointment,
  IClient,
} from '@finni-health/atlas-shared';
import { DISPLAY_TIME_FORMAT } from '../../consts';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IoSchoolOutline } from 'react-icons/io5';
import { CloseOutlined, VideoCameraOutlined } from '@ant-design/icons';
import {
  isAppointmentCancelled,
  getAppointmentLocationText,
} from '../../helpers/appointments';
import { getBillingCodeColor } from '../../helpers/colors';
import { IRbtCalendarEvent } from '../../pages/types';

const { Text, Title } = Typography;

const CANCELLED_TEXT = '(CANCELLED) ';

interface IProps {
  client: IClient;
  appointment: IAppointment;
  forceOpen: boolean;
  setSelectedEvent: (event: IRbtCalendarEvent) => void;
}

export const RbtAppointmentCard: React.FC<IProps> = ({
  client,
  appointment,
  forceOpen,
  setSelectedEvent,
}: IProps) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<any>();

  const hidePopover = () => {
    setSelectedEvent({} as IRbtCalendarEvent);
    setOpen(false);
  };

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setSelectedEvent(appointment);
      setOpen(true);
    } else {
      setSelectedEvent({} as IRbtCalendarEvent);
      setOpen(false);
    }
  };

  useEffect(() => {
    setRef(React.createRef());
  }, []);

  useEffect(() => {
    if (forceOpen && ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [forceOpen, ref]);

  useEffect(() => {
    if (forceOpen) {
      handlePopoverOpenChange(true);
    }
  }, [forceOpen]);

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <Tooltip placement="bottom" title="Close">
            <Button
              type="text"
              size="small"
              style={{ marginLeft: 12 }}
              onClick={hidePopover}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {client && !_.isEmpty(client) ? (
              <Link to={`/client-profile/${client.id}`}>
                <IoSchoolOutline
                  style={{ fontSize: 18, marginBottom: -1, marginRight: 3 }}
                />
                {client.alias}
              </Link>
            ) : (
              appointment.summary
            )}
          </Title>
        </Row>
        <Row>
          <Text>
            {getSessionNameFromBilling(
              appointment.billingCode,
              appointment.modifiers
            )}
          </Text>
          <Text type="secondary" style={{ marginLeft: 7 }}>{`${
            appointment.billingCode
          }, ${appointment.modifiers.join(', ')}`}</Text>
        </Row>
        <Row>
          <Text>{`${moment(appointment.startMs).format(
            DISPLAY_TIME_FORMAT
          )} — ${moment(appointment.endMs).format(DISPLAY_TIME_FORMAT)}`}</Text>
        </Row>
        {appointment.meetsLink ? (
          <Row align="middle" style={{ marginTop: 8 }}>
            <Tooltip title="Join Google Meet">
              <Button
                type="primary"
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Link to={{ pathname: appointment.meetsLink }} target="_blank">
                  <VideoCameraOutlined style={{ marginRight: 3 }} />
                  {getAppointmentLocationText(appointment.location)}
                </Link>
              </Button>
            </Tooltip>
          </Row>
        ) : (
          <Row align="middle">
            <Text>{getAppointmentLocationText(appointment.location)}</Text>
          </Row>
        )}
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {appointment.attendees.map((attendee) => (
          <Tag style={{ marginBottom: 2, fontSize: 12 }}>
            <Badge
              status={
                attendee.status === AttendeeStatus.ACCEPTED
                  ? 'success'
                  : attendee.status === AttendeeStatus.DECLINED
                  ? 'error'
                  : attendee.status === AttendeeStatus.TENTATIVE
                  ? 'warning'
                  : 'default'
              }
              style={{ marginRight: 3 }}
            />
            {attendee.email}
          </Tag>
        ))}
        {appointment.description && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Description</Text>
            </Row>
            <Row>
              <Text>{appointment.description}</Text>
            </Row>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        //@ts-ignore
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <Card
          ref={ref}
          hoverable
          style={{
            marginBottom: 5,
            minWidth: 'min-content',
            boxShadow: open
              ? '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'
              : undefined,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: '100%', height: 24 }}>
            <Text
              strong
              style={{
                textDecoration: isAppointmentCancelled(appointment)
                  ? 'line-through'
                  : undefined,
                opacity: isAppointmentCancelled(appointment) ? 0.5 : 1,
              }}
            >
              {`${isAppointmentCancelled(appointment) ? CANCELLED_TEXT : ''}${
                client && !_.isEmpty(client)
                  ? client.alias
                  : appointment.summary
              }`}
            </Text>
          </Row>
          <Tag
            color={getBillingCodeColor(
              appointment.billingCode,
              appointment.modifiers
            )}
            style={{ marginTop: 2, marginBottom: 2 }}
          >
            {getSessionNameFromBilling(
              appointment.billingCode,
              appointment.modifiers
            )}
          </Tag>
          <br />
          <Text>
            {appointment.meetsLink && (
              <VideoCameraOutlined style={{ marginRight: 3 }} />
            )}
            {getAppointmentLocationText(appointment.location)}
          </Text>
          <br />
          <Text>{`${moment(appointment.startMs).format(
            DISPLAY_TIME_FORMAT
          )} — ${moment(appointment.endMs).format(DISPLAY_TIME_FORMAT)}`}</Text>
          <br />
          {appointment.attendees.slice(0, 2).map((attendee) => (
            <Tag style={{ marginBottom: 2, fontSize: 11 }}>
              <Badge
                status={
                  attendee.status === AttendeeStatus.ACCEPTED
                    ? 'success'
                    : attendee.status === AttendeeStatus.DECLINED
                    ? 'error'
                    : attendee.status === AttendeeStatus.TENTATIVE
                    ? 'warning'
                    : 'default'
                }
                style={{ marginRight: 3 }}
              />
              {attendee.email}
            </Tag>
          ))}
          <br />
          {appointment.attendees.slice(2).length > 0 && (
            <Text style={{ fontSize: 12 }}>
              {`And ${appointment.attendees.slice(2).length} other guest${
                appointment.attendees.slice(2).length > 1 ? 's' : ''
              }`}
            </Text>
          )}
        </Card>
      </Popover>
    </>
  );
};
