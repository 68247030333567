import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import { IGuardian, IClientFile } from '@finni-health/atlas-shared';
import * as FirestoreService from '../services/firestore';
import { Loading } from './Loading';
import { ClientIntakeForm } from '../components/ClientIntakeForm/ClientIntakeForm';
import { GuardianDetailsSection } from '../components/GuardianProfile/GuardianDetailsSection';
import { IoHeartCircleOutline } from 'react-icons/io5';
import { ClientPayerCard } from '../components/ClientPayerCard';
import _ from 'lodash';

const { Title, Link: LinkText } = Typography;

export enum TABLE_TABS {
  OVERVIEW = 'overview',
}

export const GuardianProfile: React.FC = () => {
  // @ts-ignore
  const { guardianId } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [guardian, setGuardian] = useState<IGuardian>({} as IGuardian);
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(
    TABLE_TABS.OVERVIEW
  );

  const fetchData = async () => {
    if (guardianId) {
      const guardian = await FirestoreService.getGuardianById(guardianId);
      const clientFile = await FirestoreService.getClientFileByGuardianId(
        guardianId,
        guardian.clinicId
      );

      setGuardian(guardian);
      setClientFile(clientFile);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [guardianId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row align="middle">
        <Space size="middle">
          <Button onClick={history.goBack}>
            <LinkText>Back</LinkText>
          </Button>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'/intake'}>Intake</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`${guardian.firstName} ${guardian.lastName}`}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Space>
      </Row>
      <Row style={{ marginTop: 25 }} align="middle">
        <Title level={2}>{`${guardian.firstName} ${guardian.lastName}`}</Title>
        <Tag
          color={'purple'}
          style={{
            position: 'relative',
            left: 10,
            bottom: 9,
            fontSize: 15,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <IoHeartCircleOutline
            style={{ fontSize: 17, marginRight: 2, marginBottom: -3 }}
          />
          Guardian
        </Tag>
      </Row>
      <Tabs
        type="card"
        defaultActiveKey={selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
      >
        <Tabs.TabPane tab="Overview" key={TABLE_TABS.OVERVIEW} />
      </Tabs>
      {selectedTab === TABLE_TABS.OVERVIEW && (
        <>
          <GuardianDetailsSection
            guardian={guardian}
            refreshCallback={fetchData}
          />
          {!_.isEmpty(clientFile.payers) && (
            <>
              <Title level={4}>Payers</Title>
              <Row
                justify={clientFile.payers.secondary ? 'space-around' : 'start'}
              >
                {clientFile.payers.primary && (
                  <Col>
                    <ClientPayerCard
                      clientId={clientFile.clientId}
                      clientPayer={clientFile.payers.primary}
                    />
                  </Col>
                )}
                {clientFile.payers.secondary && (
                  <Col>
                    <ClientPayerCard
                      clientId={clientFile.clientId}
                      clientPayer={clientFile.payers.secondary}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};
