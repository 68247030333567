import React, { useContext } from 'react';
import '../css/NotesPopover.css';
import {
  Button,
  Row,
  Tooltip,
  Typography,
  Tabs,
  Col,
  Divider,
  Descriptions,
} from 'antd';
import {
  getSessionNameFromBilling,
  IAppointment,
  UserPermission,
  INote,
  BillingCode,
  Modifier,
} from '@finni-health/atlas-shared';
import {
  DISPLAY_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  COLORS,
  MOTIVITY_FORMS_URL,
} from '../../consts';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IoSchoolOutline } from 'react-icons/io5';
import {
  CheckOutlined,
  CheckCircleFilled,
  DisconnectOutlined,
  PlusOutlined,
  SplitCellsOutlined,
  ReloadOutlined,
  CheckSquareFilled,
  CloseSquareFilled,
} from '@ant-design/icons';
import { getAppointmentLocationText } from '../../helpers/appointments';
import { AuthContext } from '../AuthProvider';
import { FaStamp } from 'react-icons/fa';
import DescriptionsItem from 'antd/lib/descriptions/Item';

const { Paragraph } = Typography;

interface IProps {
  notes: INote[];
  appointment: IAppointment;
  isLoading: boolean;
  handleUnmatchNote: (id: string) => Promise<void>;
  handleReloadNote: (id: string) => Promise<void>;
  handleApproveNote: (id: string) => Promise<void>;
  handleCompleteNote: (id: string) => Promise<void>;
  handleSplitAppointment: (
    note: INote,
    appointment: IAppointment
  ) => Promise<void>;
  setDisplayCompletionEffect: (value: boolean) => void;
  setDisplaySplitEffectNote: (note: INote) => void;
  openMatchNotesDrawer: () => void;
  hidePopover: () => void;
}

export const NotesTabs: React.FC<IProps> = ({
  notes,
  appointment,
  isLoading,
  handleUnmatchNote,
  handleReloadNote,
  handleApproveNote,
  handleCompleteNote,
  handleSplitAppointment,
  setDisplayCompletionEffect,
  setDisplaySplitEffectNote,
  openMatchNotesDrawer,
  hidePopover,
}: IProps) => {
  const user = useContext(AuthContext).user;

  const getTabContent = () => {
    const tabContent = notes.map((note: INote) => {
      if (
        user?.permissions?.includes(UserPermission.ADMIN) ||
        user?.id == note.userId
      ) {
        return {
          label: (
            <>
              {note.approved == true && (
                <Tooltip title={note.approved && 'Approved'}>
                  <CheckCircleFilled
                    style={{ marginRight: 3, color: COLORS.GREEN }}
                  />
                </Tooltip>
              )}
              {moment(note.startMs).format(DISPLAY_TIME_FORMAT)}
            </>
          ),
          key: note.id,
          children: (
            <Col>
              <Row style={{ maxHeight: 400, overflowY: 'auto' }}>
                <Row
                  style={{ marginBottom: 5, width: '100%' }}
                  align="bottom"
                  justify="space-between"
                >
                  <div>
                    <Link
                      to={{
                        pathname: `${MOTIVITY_FORMS_URL + note.id}`,
                      }}
                      target="_blank"
                      style={{ fontWeight: 'bold' }}
                    >
                      {note.noteType}
                    </Link>
                  </div>
                  <div>
                    <Tooltip title="Reload this note from Motivity">
                      <Button
                        size="small"
                        loading={isLoading}
                        onClick={() => handleReloadNote(note.id)}
                      >
                        <ReloadOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Un-link note from this session">
                      <Button
                        size="small"
                        loading={isLoading}
                        onClick={() => handleUnmatchNote(note.id)}
                      >
                        <DisconnectOutlined />
                      </Button>
                    </Tooltip>
                  </div>
                </Row>
                <Descriptions labelStyle={{ fontWeight: 600 }} column={1}>
                  <DescriptionsItem label="Session">
                    {getSessionNameFromBilling(
                      note.billingCode as BillingCode,
                      note.modifiers as Modifier[]
                    )}
                  </DescriptionsItem>
                  <DescriptionsItem label="Client">
                    <Link to={`client-profile/${note.clientId}`}>
                      <IoSchoolOutline
                        style={{
                          fontSize: 18,
                          marginBottom: -3,
                          marginRight: 3,
                        }}
                      />
                      {note.clientFullName}
                    </Link>
                  </DescriptionsItem>
                  <DescriptionsItem label="Provider">
                    {note.providerFullName}
                  </DescriptionsItem>
                  <DescriptionsItem label="Date">
                    {moment(note.startMs).format(DISPLAY_DATE_FORMAT)}
                  </DescriptionsItem>
                  <DescriptionsItem label="Start">
                    {moment(note.startMs).format(DISPLAY_TIME_FORMAT)}
                  </DescriptionsItem>
                  <DescriptionsItem label="End">
                    {moment(note.endMs).format(DISPLAY_TIME_FORMAT)}
                  </DescriptionsItem>
                  <DescriptionsItem label="Location">
                    {getAppointmentLocationText(note.location)}
                  </DescriptionsItem>
                  <DescriptionsItem label="Provider Signed">
                    {note.providerSignedMs ? (
                      <CheckSquareFilled style={{ color: COLORS.GREEN }} />
                    ) : (
                      <CloseSquareFilled style={{ color: COLORS.RED }} />
                    )}
                  </DescriptionsItem>
                  <DescriptionsItem label="Client Signed">
                    {note.clientSignedMs ? (
                      <CheckSquareFilled style={{ color: COLORS.GREEN }} />
                    ) : (
                      <CloseSquareFilled style={{ color: COLORS.RED }} />
                    )}
                  </DescriptionsItem>
                  <DescriptionsItem label="Narrative">
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: 'more',
                      }}
                    >
                      {note.narrative}
                    </Paragraph>
                  </DescriptionsItem>
                </Descriptions>
              </Row>
              {note.approved === false ? (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Row justify="end">
                    <Button
                      type="primary"
                      style={{ padding: '0px 10px 0px 10px' }}
                      onClick={() => {
                        hidePopover();
                        handleApproveNote(note.id);
                      }}
                    >
                      <CheckOutlined />
                      Approve
                    </Button>
                  </Row>
                </>
              ) : notes.length == 1 ? (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Row justify="end">
                    <Button
                      type="primary"
                      style={{ padding: '0px 10px 0px 10px' }}
                      onClick={() => {
                        hidePopover();
                        handleCompleteNote(note.id);
                      }}
                      onMouseEnter={() => setDisplayCompletionEffect(true)}
                      onMouseLeave={() => setDisplayCompletionEffect(false)}
                    >
                      <FaStamp
                        style={{
                          marginRight: 7,
                          marginBottom: -1,
                          fontSize: 14,
                        }}
                      />
                      Complete Appointment
                    </Button>
                  </Row>
                </>
              ) : (
                notes.length > 1 && (
                  <>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Row justify="end">
                      <Button
                        type="primary"
                        style={{ padding: '0px 10px 0px 10px' }}
                        onClick={() => {
                          hidePopover();
                          handleSplitAppointment(note, appointment);
                        }}
                        onMouseEnter={() => setDisplaySplitEffectNote(note)}
                        onMouseLeave={() =>
                          setDisplaySplitEffectNote({} as INote)
                        }
                      >
                        <SplitCellsOutlined />
                        Split note into new appointment
                      </Button>
                    </Row>
                  </>
                )
              )}
            </Col>
          ),
        };
      }
    });

    return tabContent.filter((item) => item !== undefined);
  };

  return (
    <Tabs
      size="small"
      style={{ marginTop: 15 }}
      //@ts-ignore
      items={getTabContent()}
      tabBarExtraContent={
        <Tooltip title="Link another note">
          <Button size="small" onClick={openMatchNotesDrawer}>
            <PlusOutlined />
          </Button>
        </Tooltip>
      }
    />
  );
};
