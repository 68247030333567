import { Form, Row, Col, Input, DatePicker, Select } from 'antd';
import _ from 'lodash';
import {
  DB_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_PLACEHOLDER,
  MIDDLE_NAME_PLACEHOLDER,
} from '../../consts';
import {
  IClient,
  Sex,
  capitalizeFirstLetter,
} from '@finni-health/atlas-shared';
import moment from 'moment';

interface IProps {
  tempClient: IClient;
  updateTempClient: (value: any) => void;
}

export const ClientInfoForm: React.FC<IProps> = ({
  tempClient,
  updateTempClient,
}: IProps) => (
  <>
    <Row gutter={24}>
      <Col span={3}>
        <Form.Item label="Alias" required>
          <Input
            required
            tabIndex={1}
            value={tempClient.alias}
            placeholder="JoDo"
            onChange={(e) =>
              updateTempClient({
                alias: e.target.value,
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="First Name" required>
          <Input
            required
            tabIndex={1}
            placeholder={FIRST_NAME_PLACEHOLDER}
            value={tempClient.firstName}
            onChange={(e) =>
              updateTempClient({
                firstName: capitalizeFirstLetter(e.target.value),
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="Middle Name">
          <Input
            tabIndex={1}
            placeholder={MIDDLE_NAME_PLACEHOLDER}
            value={tempClient.middleName}
            onChange={(e) =>
              updateTempClient({
                middleName: capitalizeFirstLetter(e.target.value),
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="Last Name" required>
          <Input
            required
            tabIndex={2}
            placeholder={LAST_NAME_PLACEHOLDER}
            value={tempClient.lastName}
            onChange={(e) =>
              updateTempClient({
                lastName: capitalizeFirstLetter(e.target.value),
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Sex at Birth">
          <Select
            tabIndex={3}
            value={tempClient.sex}
            onChange={(sex) => updateTempClient({ sex: sex })}
          >
            <Select.Option value={Sex.FEMALE}>{Sex.FEMALE}</Select.Option>;
            <Select.Option value={Sex.MALE}>{Sex.MALE}</Select.Option>;
            <Select.Option value={Sex.UNKNOWN}>{Sex.UNKNOWN}</Select.Option>;
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Date of Birth">
          <DatePicker
            tabIndex={4}
            placeholder={DISPLAY_DATE_FORMAT}
            format={DISPLAY_DATE_FORMAT}
            allowClear={false}
            autoComplete="off"
            disabledDate={(curr) => curr && curr > moment().startOf('day')}
            value={
              tempClient.dateOfBirth
                ? moment(tempClient.dateOfBirth)
                : undefined
            }
            onChange={(date) =>
              date &&
              updateTempClient({ dateOfBirth: date.format(DB_DATE_FORMAT) })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);
