import * as FirestoreService from '../../services/firestore';
import {
  Modal,
  Button,
  message,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  Typography,
  Switch,
  Input,
} from 'antd';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  AppointmentLocation,
  BillingCode,
  getSessionNameFromBilling,
  ICalendarCreateAppointmentEndpointRequest,
  IClient,
  IUser,
  Modifier,
  UserPermission,
} from '@finni-health/atlas-shared';
import {
  AM_HOURS,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  ERROR_MESSAGE,
} from '../../consts';
import { Moment } from 'moment';
import * as momentTz from 'moment-timezone';
import { AuthContext } from '../AuthProvider';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getAppointmentLocationText } from '../../helpers/appointments';

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  refreshCallback: () => void;
  hideModal: () => void;
  isVisible: boolean;
  users: IUser[];
}

interface ICreateAppointmentFormValues {
  clientId: string;
  billingCode: BillingCode;
  mod1: string;
  mod2: string;
  date: Moment;
  start: Moment;
  end: Moment;
  location: AppointmentLocation;
  hasMeets: boolean;
  attendeeEmails: string[];
  description: string;
}

export const CreateAppointmentModal = ({
  refreshCallback,
  hideModal,
  isVisible,
  users,
}: Props) => {
  const user = useContext(AuthContext).user;

  const [form] = Form.useForm<ICreateAppointmentFormValues>();
  const attendeeEmails = Form.useWatch('attendeeEmails', form);
  const clientId = Form.useWatch('clientId', form);
  const billingCode = Form.useWatch('billingCode', form);
  const mod1 = Form.useWatch('mod1', form);
  const mod2 = Form.useWatch('mod2', form);
  const start = Form.useWatch('start', form);
  const end = Form.useWatch('end', form);
  const location = Form.useWatch('location', form);

  const [selectedClient, setSelectedClient] = useState<IClient>({} as IClient);
  const [clients, setClients] = useState<IClient[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const fetchData = async () => {
    const clients = await FirestoreService.getAllClientsForClinic(
      user.clinicId
    );
    setClients(clients);
  };

  const fetchClient = async () => {
    const client = await FirestoreService.getClientById(clientId);

    setSelectedClient(client);
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    fetchClient();
  }, [clientId]);

  useEffect(() => {
    if (location === AppointmentLocation.TELEHEALTH) {
      form.setFieldValue('hasMeets', true);
    }
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(attendeeEmails)) {
      for (const email of attendeeEmails) {
        const user = users.find((user) => user.email === email);
        if (
          !_.isEmpty(user) &&
          user?.permissions.includes(UserPermission.RBT)
        ) {
          _.isEmpty(mod1) ||
          [Modifier.U1, Modifier.U3].includes(mod1 as Modifier)
            ? form.setFieldValue('mod1', Modifier.U1)
            : form.setFieldValue('mod2', Modifier.U1);
        }
        if (
          !_.isEmpty(user) &&
          user?.permissions.includes(UserPermission.BCBA)
        ) {
          _.isEmpty(mod1) ||
          [Modifier.U1, Modifier.U3].includes(mod1 as Modifier)
            ? form.setFieldValue('mod1', Modifier.U3)
            : form.setFieldValue('mod2', Modifier.U3);
          break;
        }
      }
    }
  }, [attendeeEmails]);

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const saveAppointment = async () => {
    setIsSaving(true);
    try {
      const values = form.getFieldsValue();

      const modifiers = [];
      !_.isEmpty(mod1) && modifiers.push(mod1);
      !_.isEmpty(mod2) && modifiers.push(mod2);

      const date = {
        year: values.date.year(),
        month: values.date.month(),
        date: values.date.date(),
      };

      const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest =
        {
          clinicId: user.clinicId,
          clientId: values.clientId,
          attendeeEmails: values.attendeeEmails.map((email) => email.trim()),
          billingCode: values.billingCode,
          modifiers: modifiers as Modifier[],
          location: values.location,
          summary: getAppointmentSummary(),
          description: values.description,
          timeZone: momentTz.tz.guess(),
          startMs: values.start.set(date).valueOf(),
          endMs: values.end.set(date).valueOf(),
          hasMeets: values.hasMeets,
        };

      await FirestoreService.createAppointment(createAppointmentRequest);

      form.resetFields();
      message.success('Appointment created');
      refreshCallback();
      hideModal();
    } catch (err) {
      message.error(ERROR_MESSAGE);
      console.error(err);
    }
    setIsSaving(false);
  };

  const hideModalIfNotSaving = () => {
    if (!isSaving) {
      hideModal();
    }
  };

  const getAppointmentSummary = () => {
    return `${!_.isEmpty(selectedClient) ? `${selectedClient.alias} ` : ''}${
      billingCode && billingCode !== BillingCode.CODE_T1026
        ? getSessionNameFromBilling(
            billingCode as BillingCode,
            []
          )?.toLowerCase()
        : billingCode === BillingCode.CODE_T1026 &&
          (mod1 !== undefined || mod2 !== undefined)
        ? getSessionNameFromBilling(billingCode as BillingCode, [
            mod1 as Modifier,
            mod2 as Modifier,
          ])?.toLowerCase()
        : 'appointment'
    } ${
      location === AppointmentLocation.TELEHEALTH ? '(remote)' : '(in-person)'
    }`;
  };

  return (
    <Modal
      title={`New ${getAppointmentSummary()}`}
      closable={!isSaving}
      footer={null}
      destroyOnClose={true}
      onCancel={hideModalIfNotSaving}
      open={isVisible}
      width={500}
      bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
    >
      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={saveAppointment}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="clientId"
              rules={[{ required: true, message: 'Please select a client' }]}
            >
              <Select
                placeholder="Client"
                showSearch
                allowClear
                optionFilterProp="children"
              >
                {clients
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((client) => (
                    <Select.Option key={client.id} value={client.id}>
                      {`(${client.alias}) ${client.firstName} ${client.lastName}`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="attendeeEmails"
              rules={[{ required: true, message: 'Please add guests' }]}
            >
              <Select
                placeholder="Attendee Email Addresses"
                mode="tags"
                allowClear
              >
                {users
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((user) => (
                    <Select.Option key={user.email} value={user.email}>
                      <Text strong>{`${user.firstName} ${user.lastName}`}</Text>
                      <br />
                      <Text>{`${user.email}`}</Text>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="billingCode"
              rules={[{ required: true, message: 'Please select a service' }]}
            >
              <Select
                placeholder="Service"
                showSearch
                allowClear
                optionFilterProp="children"
              >
                {Object.values(BillingCode).map((code) => (
                  <Select.Option key={code} value={code}>
                    {code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="mod1">
              <Select
                placeholder="Mod"
                showSearch
                allowClear
                optionFilterProp="children"
              >
                {Object.values(Modifier)
                  .filter((mod) => mod !== mod2)
                  .map((mod) => (
                    <Select.Option key={mod} value={mod}>
                      {mod}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="mod2">
              <Select
                placeholder="Mod"
                showSearch
                allowClear
                optionFilterProp="children"
              >
                {Object.values(Modifier)
                  .filter((mod) => mod !== mod1)
                  .map((mod) => (
                    <Select.Option key={mod} value={mod}>
                      {mod}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="location"
              rules={[{ required: true, message: 'Please select a location' }]}
            >
              <Select placeholder="Location" showSearch allowClear>
                {Object.values(AppointmentLocation).map((location) => (
                  <Select.Option key={location} value={location}>
                    {getAppointmentLocationText(location)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {location === AppointmentLocation.TELEHEALTH && (
            <Col span={12}>
              <Row>
                <Form.Item name="hasMeets">
                  <Switch
                    defaultChecked
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
                <Text style={{ marginTop: 6, marginLeft: 8 }}>Google Meet</Text>
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="date"
              rules={[
                { required: true, message: 'Please enter a session date' },
              ]}
            >
              <DatePicker
                placeholder="Date"
                style={{ padding: 0 }}
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="start"
              rules={[{ required: true, message: 'Please enter a start time' }]}
            >
              <TimePicker
                placeholder="Start"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                onSelect={(time) => {
                  form.setFieldValue('start', time);
                  if (_.isEmpty(end) || end.isBefore(time)) {
                    form.setFieldValue('end', time.clone().add(30, 'minutes'));
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="end"
              rules={[{ required: true, message: 'Please enter an end time' }]}
            >
              <TimePicker
                disabled={_.isEmpty(start)}
                placeholder="End"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                disabledTime={() => ({
                  disabledHours: getDisabledEndTimeHours,
                  disabledMinutes: getDisabledEndTimeMinutes,
                })}
                onSelect={(time) => {
                  form.setFieldValue('end', time);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="description">
              <TextArea
                placeholder="Description"
                allowClear
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isSaving}
              style={{ float: 'right' }}
            >
              Book
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
