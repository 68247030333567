import React, { useEffect, useState } from 'react';
import { Card, Progress, Row, Skeleton, Typography, Tooltip } from 'antd';
import {
  IClient,
  BillingCode,
  IAppointment,
  Modifier,
  IClientFile,
  ICompletedAppointment,
} from '@finni-health/atlas-shared';
import { isAppointmentCancelled } from '../../helpers/appointments';
import * as FirestoreService from '../../services/firestore';
import { ProfileOutlined } from '@ant-design/icons';
import { ICalendarRow, WeekDays, ICalendarEvent } from '../../pages/types';
import { Link } from 'react-router-dom';
import { getBillingCodeColor } from '../../helpers/colors';
import { getWeeklyHoursFromAuth } from '../../helpers/schedules';
import { COLORS } from '../../consts';
import _ from 'lodash';
import { weekdays } from 'moment';
import { IoSchoolOutline } from 'react-icons/io5';

const { Title, Text } = Typography;

interface IProps {
  data: ICalendarRow;
}

export const ClientSummary: React.FC<IProps> = ({ data }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [total97153, setTotal97153] = useState<number>(0);
  const [total97155, setTotal97155] = useState<number>(0);
  const [totalT1026UC, setTotalT1026UC] = useState<number>(0);
  const [totalT1026UD, setTotalT1026UD] = useState<number>(0);
  const [completed97153, setCompleted97153] = useState<number>(0);
  const [completed97155, setCompleted97155] = useState<number>(0);
  const [completedT1026UC, setCompletedT1026UC] = useState<number>(0);
  const [completedT1026UD, setCompletedT1026UD] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, [data]);

  const fetchData = async () => {
    const clientFile = await FirestoreService.getClientFileByClientId(
      data.person.id,
      data.person.clinicId
    );

    setClientFile(clientFile);

    //totals
    setTotal97153(getTotalHoursFromCalendar(BillingCode.CODE_97153));
    setTotal97155(getTotalHoursFromCalendar(BillingCode.CODE_97155));
    setTotalT1026UC(
      getTotalHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UC)
    );
    setTotalT1026UD(
      getTotalHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UD)
    );

    //completed
    setCompleted97153(getCompletedHoursFromCalendar(BillingCode.CODE_97153));
    setCompleted97155(getCompletedHoursFromCalendar(BillingCode.CODE_97155));
    setCompletedT1026UC(
      getCompletedHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UC)
    );
    setCompletedT1026UD(
      getCompletedHoursFromCalendar(BillingCode.CODE_T1026, Modifier.UD)
    );

    setIsLoading(false);
  };

  const roundToNearestHalf = (num: number) => {
    return Number((Math.round(num * 2) / 2).toFixed(1));
  };

  const getTotalHoursFromCalendar = (
    billingCode: BillingCode,
    modifier?: Modifier
  ) => {
    let totalHours = 0;
    for (const day of Object.values(WeekDays)) {
      data[day]?.map((appt: ICalendarEvent) => {
        if ((appt as any).isBilled === undefined) {
          //Regular Appointment
          appt = appt as IAppointment;
          if (
            // Allow cancelled in Total hours
            // !isAppointmentCancelled(appt) &&
            appt.billingCode === billingCode &&
            (!modifier || appt.modifiers.includes(modifier)) &&
            data[day].filter((a) => a.id === appt.id).length == 1
          ) {
            totalHours += (appt.endMs - appt.startMs) / (1000 * 60 * 60);
          }
        } else {
          //Completed Appointment
          appt = appt as ICompletedAppointment;
          if (
            // Allow cancelled in Total hours
            // _.isEmpty(
            //   (appt as unknown as ICompletedAppointment).cancelledAt
            // ) &&
            appt.billingCode === billingCode &&
            (!modifier || appt.modifiers.includes(modifier))
          ) {
            totalHours += (appt.endMs - appt.startMs) / (1000 * 60 * 60);
          }
        }
      });
    }
    //round to nearest 0.5
    return roundToNearestHalf(totalHours);
  };

  const getCompletedHoursFromCalendar = (
    billingCode: BillingCode,
    modifier?: Modifier
  ) => {
    let completedHours = 0;
    for (const day of Object.values(WeekDays)) {
      data[day]?.map((appt: ICalendarEvent) => {
        appt = appt as ICompletedAppointment;
        if (
          appt.isBilled !== undefined &&
          _.isEmpty(appt.cancelledAt) &&
          appt.billingCode === billingCode &&
          (!modifier || appt.modifiers.includes(modifier))
        ) {
          completedHours += (appt.endMs - appt.startMs) / (1000 * 60 * 60);
        }
      });
    }
    //round to nearest 0.5
    return roundToNearestHalf(completedHours);
  };

  return (
    <Card size="small" bordered={false}>
      <Row>
        <Title underline level={5}>
          <Link
            to={`/client-profile/${data.person.id}`}
            style={{ whiteSpace: 'nowrap' }}
          >
            <IoSchoolOutline
              style={{
                fontSize: 18,
                marginBottom: -3,
                marginRight: 5,
              }}
            />
            {(data.person as IClient).alias}
          </Link>
        </Title>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Text>{`${data.person.firstName} ${data.person.lastName}`}</Text>
      </Row>
      {isLoading || !clientFile ? (
        <Skeleton />
      ) : (
        <>
          {total97153 > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${total97153} hours scheduled`}
                style={{ whiteSpace: 'pre' }}
              >
                <Progress
                  type="dashboard"
                  width={50}
                  success={{
                    percent:
                      (completed97153 /
                        roundToNearestHalf(
                          getWeeklyHoursFromAuth(
                            clientFile.payers.primary?.auth,
                            BillingCode.CODE_97153
                          )
                        )) *
                      100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_97153),
                  }}
                  percent={
                    (total97153 /
                      roundToNearestHalf(
                        getWeeklyHoursFromAuth(
                          clientFile.payers.primary?.auth,
                          BillingCode.CODE_97153
                        )
                      )) *
                    100
                  }
                  trailColor={COLORS.GREY}
                  strokeColor={COLORS.NEUTRAL_BLUE}
                  format={(_) => (
                    <>
                      <Text>{total97153}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>
                  {BillingCode.CODE_97153}
                </Text>
              </Tooltip>
            </Row>
          )}
          {total97155 > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${total97155} hours scheduled`}
              >
                <Progress
                  type="dashboard"
                  width={50}
                  success={{
                    percent:
                      (completed97155 /
                        (getWeeklyHoursFromAuth(
                          clientFile.payers.primary?.auth,
                          BillingCode.CODE_97153
                        ) *
                          0.1)) *
                      100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_97155),
                  }}
                  percent={
                    (total97155 /
                      (getWeeklyHoursFromAuth(
                        clientFile.payers.primary?.auth,
                        BillingCode.CODE_97153
                      ) *
                        0.1)) *
                    100
                  }
                  trailColor={COLORS.GREY}
                  strokeColor={COLORS.NEUTRAL_BLUE}
                  format={(_) => (
                    <>
                      <Text>{total97155}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text style={{ marginLeft: 5, fontSize: 12 }}>
                  {BillingCode.CODE_97155}
                </Text>
              </Tooltip>
            </Row>
          )}
          {totalT1026UC > 0 && (
            <Row align="middle" style={{ marginBottom: 15 }}>
              <Tooltip
                placement="right"
                title={`${totalT1026UC} hours scheduled`}
              >
                <Progress
                  type="dashboard"
                  width={50}
                  success={{
                    percent:
                      (completedT1026UC /
                        (getWeeklyHoursFromAuth(
                          clientFile.payers.primary?.auth,
                          BillingCode.CODE_97153
                        ) *
                          0.1)) *
                      100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_T1026, [
                      Modifier.UC,
                    ]),
                  }}
                  percent={
                    (totalT1026UC /
                      (getWeeklyHoursFromAuth(
                        clientFile.payers.primary?.auth,
                        BillingCode.CODE_97153
                      ) *
                        0.1)) *
                    100
                  }
                  trailColor={COLORS.GREY}
                  strokeColor={COLORS.NEUTRAL_BLUE}
                  format={(_) => (
                    <>
                      <Text>{totalT1026UC}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text
                  style={{ marginLeft: 5, fontSize: 12 }}
                >{`${BillingCode.CODE_T1026}UC`}</Text>
              </Tooltip>
            </Row>
          )}
          {totalT1026UD > 0 && (
            <Row align="middle">
              <Tooltip
                placement="right"
                title={`${totalT1026UD} hours scheduled`}
              >
                <Progress
                  type="dashboard"
                  width={50}
                  success={{
                    percent:
                      (completedT1026UD /
                        (getWeeklyHoursFromAuth(
                          clientFile.payers.primary?.auth,
                          BillingCode.CODE_97153
                        ) *
                          0.1)) *
                      100,
                    strokeColor: getBillingCodeColor(BillingCode.CODE_T1026, [
                      Modifier.UD,
                    ]),
                  }}
                  percent={
                    (totalT1026UD /
                      (getWeeklyHoursFromAuth(
                        clientFile.payers.primary?.auth,
                        BillingCode.CODE_97153
                      ) *
                        0.1)) *
                    100
                  }
                  trailColor={COLORS.GREY}
                  strokeColor={COLORS.NEUTRAL_BLUE}
                  format={(_) => (
                    <>
                      <Text>{totalT1026UD}</Text>
                      <br />
                      <Text style={{ fontSize: 10 }}>hours</Text>
                    </>
                  )}
                />
                <Text
                  style={{ marginLeft: 5, fontSize: 12 }}
                >{`${BillingCode.CODE_T1026}UD`}</Text>
              </Tooltip>
            </Row>
          )}
        </>
      )}
    </Card>
  );
};
