import { Modal, Space, Row, message, Typography, Radio, Button } from 'antd';
import _ from 'lodash';
import * as FirestoreService from '../../services/firestore';
import { useState } from 'react';
import { IAppointment } from '@finni-health/atlas-shared';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { APPOINTMENT_DELETE_TYPES, ERROR_MESSAGE } from '../../consts';
import { addUntilRrule } from '../../helpers/appointments';

const { Text, Title } = Typography;

interface IProps {
  appointment: IAppointment;
  hideModal: () => void;
  isVisible: boolean;
  hidePopover: () => void;
  refreshCallback: () => void;
}

export const ConfirmDeleteAppointmentModal = ({
  appointment,
  isVisible,
  hideModal,
  refreshCallback,
  hidePopover,
}: IProps) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeleteType, setSelectedDeleteType] =
    useState<APPOINTMENT_DELETE_TYPES>(APPOINTMENT_DELETE_TYPES.THIS);

  const onSelectedDeleteTypeChange = (e: any) => {
    setSelectedDeleteType(
      APPOINTMENT_DELETE_TYPES[e.target.value as APPOINTMENT_DELETE_TYPES]
    );
  };

  const handleDeleteAppointment = async () => {
    setIsDeleting(true);

    let idToDelete = appointment.id;
    let clientFileId;
    try {
      if (
        idToDelete.includes('_') &&
        selectedDeleteType === APPOINTMENT_DELETE_TYPES.THIS_AND_FUTURE
      ) {
        // Get the main instance containing RRULE
        try {
          const mainAppointment = await FirestoreService.getAppointmentById({
            id: appointment.id.split('_')[0],
            clinicId: appointment.clinicId,
          });
          const payload = addUntilRrule(mainAppointment, appointment);

          await FirestoreService.updateAppointment(payload);
        } catch (err) {
          //pass assuming forward appointments are deleted
        }

        try {
          await FirestoreService.deleteAppointment({
            id: idToDelete,
            clinicId: appointment.clinicId,
          });
        } catch (err) {
          //pass assuming original appointment was deleted
        }
      } else {
        if (
          idToDelete.includes('_') &&
          selectedDeleteType === APPOINTMENT_DELETE_TYPES.ALL
        ) {
          // If the appointment is recurring and user wants to delete all appointments,
          // send the appointmentId w/o instance timestamp to the backend
          idToDelete = idToDelete.split('_')[0];
          clientFileId = (
            await FirestoreService.getClientFileByClientId(
              appointment.clientId,
              appointment.clinicId
            )
          ).id;
        }

        await FirestoreService.deleteAppointment({
          id: idToDelete,
          ...(clientFileId && { clientFileId }),
          clinicId: appointment.clinicId,
        });
      }

      message.success('Appointment deleted');
      refreshCallback();
      hideModal();
      hidePopover();
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsDeleting(false);
  };

  return (
    <Modal
      closable={false}
      zIndex={9999}
      onCancel={hideModal}
      open={isVisible}
      width={400}
      footer={null}
    >
      <Row align="middle" style={{ width: '100%' }}>
        <ExclamationCircleOutlined
          style={{ color: 'orange', marginRight: 10, fontSize: 22 }}
        />
        <Title level={5} style={{ marginBottom: 0 }}>
          {`Permanently delete ${
            appointment.id.includes('_') ? 'recurring ' : ''
          }appointment`}
        </Title>
      </Row>
      <Row
        justify="start"
        style={{ width: '100%', marginTop: 15, paddingLeft: 32 }}
      >
        <Text>Deleting will remove this event for all guests.</Text>
        {appointment.id.includes('_') && (
          <Radio.Group
            value={selectedDeleteType}
            onChange={onSelectedDeleteTypeChange}
            style={{ marginTop: 10 }}
          >
            <Space direction="vertical">
              <Radio value={APPOINTMENT_DELETE_TYPES.THIS}>This event</Radio>
              <Radio value={APPOINTMENT_DELETE_TYPES.THIS_AND_FUTURE}>
                This and all future events
              </Radio>
              <Radio value={APPOINTMENT_DELETE_TYPES.ALL}>All events</Radio>
            </Space>
          </Radio.Group>
        )}
      </Row>
      <Row
        justify="end"
        style={{ width: '100%', marginTop: 15, paddingLeft: 32 }}
      >
        <Space>
          <Button onClick={hideModal} disabled={isDeleting}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleDeleteAppointment}
            loading={isDeleting}
          >
            Delete
          </Button>
        </Space>
      </Row>
    </Modal>
  );
};
