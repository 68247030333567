import { Button, Form, Input, Typography, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { SignUpStates, VALID_ZIP_CODE_REGEX } from '../../consts';
import Lottie from 'react-lottie-player';
import foxCelebrateAnimation from '../../lottie/fox-celebrate.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';
import { IUserSignupForm } from '../../pages/SignUpFlow';
import { USStateCode } from '@finni-health/atlas-shared';

const { Title, Text } = Typography;

type IProps = {
  inquiry: IUserSignupForm;
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
};

export const EnterPracticeState: React.FC<IProps> = ({
  inquiry,
  setInquiryStatus,
  updateInquiry,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(isScreenMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  const submitClinicState = () => {
    setInquiryStatus(SignUpStates.COMPLETE);
  };

  return (
    <>
      <Title
        style={{ fontFamily: 'Reckless Neue' }}
      >{`Which state is ${inquiry.clinicName} situated in?`}</Title>
      <Text
        style={{
          display: 'block',
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        This helps us localize your settings
      </Text>
      <Form onFinish={() => submitClinicState()}>
        <Form.Item name="state" initialValue={'NM'}>
          State
          <Select
            showSearch
            style={{ width: 100, marginLeft: 6 }}
            value={inquiry.state}
            onChange={(value) => {
              updateInquiry({ state: value });
            }}
          >
            {Object.values(USStateCode).map((state) => (
              <Select.Option value={state} key={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            height: 44,
            width: 200,
          }}
        >
          Continue
        </Button>
      </Form>
    </>
  );
};
