import * as FirestoreService from '../../services/firestore';
import {
  Modal,
  Button,
  message,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  Typography,
  Input,
} from 'antd';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  IIndirect,
  IUpdateIndirectEndpointRequest,
} from '@finni-health/atlas-shared';
import {
  AM_HOURS,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  ERROR_MESSAGE,
} from '../../consts';
import { Moment } from 'moment';
import { AuthContext } from '../AuthProvider';
import moment from 'moment';

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  indirect: IIndirect;
  refreshCallback: () => void;
  hideModal: () => void;
  isOpen: boolean;
}

interface IEditIndirectFormValues {
  date: Moment;
  start: Moment;
  end: Moment;
  description: string;
}

export const EditIndirectModal = ({
  indirect,
  refreshCallback,
  hideModal,
  isOpen,
}: Props) => {
  const user = useContext(AuthContext).user;

  const [form] = Form.useForm<IEditIndirectFormValues>();
  const start = Form.useWatch('start', form);
  const end = Form.useWatch('end', form);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const saveIndirect = async () => {
    setIsSaving(true);

    try {
      const values = form.getFieldsValue();

      const date = {
        year: values.date.year(),
        month: values.date.month(),
        date: values.date.date(),
      };

      const request: IUpdateIndirectEndpointRequest = {
        id: indirect.id,
        summary: getSummary(),
        description: values.description,
        startMs: values.start.set(date).valueOf(),
        endMs: values.end.set(date).valueOf(),
      };

      await FirestoreService.updateIndirect(request);

      form.resetFields();
      message.success('Indirect updated');
      refreshCallback();
      hideModal();
    } catch (err) {
      message.error(ERROR_MESSAGE);
      console.error(err);
    }

    setIsSaving(false);
  };

  const getSummary = () => {
    return `Indirect - ${user.firstName} ${user.lastName[0]}.`;
  };

  const hideModalIfNotSaving = () => {
    if (!isSaving) {
      hideModal();
    }
  };

  return (
    <Modal
      title={`Edit ${getSummary()}`}
      closable={!isSaving}
      footer={null}
      destroyOnClose={true}
      onCancel={hideModalIfNotSaving}
      open={isOpen}
      width={500}
      bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
    >
      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={saveIndirect}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="attendeeEmails" initialValue={[user.email]}>
              <Select placeholder="Guests" mode="tags" disabled>
                <Select.Option key={user.email} value={user.email}>
                  <Text strong>{`${user.firstName} ${user.lastName}`}</Text>
                  <br />
                  <Text>{`${user.email}`}</Text>
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item
              name="date"
              initialValue={moment(indirect.startMs)}
              rules={[{ required: true, message: 'Please enter a date' }]}
            >
              <DatePicker
                placeholder="Date"
                style={{ padding: 0 }}
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="start"
              initialValue={moment(indirect.startMs)}
              rules={[{ required: true, message: 'Please enter a start time' }]}
            >
              <TimePicker
                placeholder="Start"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                onSelect={(time) => {
                  form.setFieldValue('start', time);
                  if (_.isEmpty(end) || end.isBefore(time)) {
                    form.setFieldValue('end', time.clone().add(30, 'minutes'));
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="end"
              initialValue={moment(indirect.endMs)}
              rules={[{ required: true, message: 'Please enter an end time' }]}
            >
              <TimePicker
                disabled={_.isEmpty(start)}
                placeholder="End"
                autoComplete="off"
                allowClear={false}
                bordered={false}
                format={DISPLAY_TIME_FORMAT}
                minuteStep={15}
                disabledTime={() => ({
                  disabledHours: getDisabledEndTimeHours,
                  disabledMinutes: getDisabledEndTimeMinutes,
                })}
                onSelect={(time) => {
                  form.setFieldValue('end', time);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              initialValue={indirect.description}
              rules={[
                {
                  required: true,
                  message: 'Please describe what you accomplished',
                },
              ]}
            >
              <TextArea
                placeholder="Description"
                allowClear
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isSaving}
              style={{ float: 'right' }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
