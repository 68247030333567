import { Button, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { SignUpStates } from '../../consts';
import { IInvite } from '@finni-health/atlas-shared';
import Lottie from 'react-lottie-player';
import foxCelebrateAnimation from '../../lottie/fox-celebrate.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
  invite: any;
  user: any;
};

export const InviteFound: React.FC<IProps> = ({
  setInquiryStatus,
  updateInquiry,
  invite,
  user,
}: IProps) => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [pauseAnimation, setPauseAnimation] = useState(false);

  const [isMobile, setIsMobile] = useState(isScreenMobile());

  return (
    <>
      <div style={{ marginTop: isMobile ? -20 : -60, marginBottom: 40 }}>
        {playAnimation ? (
          <Lottie
            play
            loop={false}
            animationData={foxCelebrateAnimation}
            style={{ width: 125, height: 125, margin: 'auto' }}
            onLoopComplete={() => {
              setPlayAnimation(false);
            }}
          />
        ) : (
          <Lottie
            play={!pauseAnimation}
            goTo={pauseAnimation ? 56 : undefined}
            animationData={foxCelebrateAnimation}
            segments={[27, 80]}
            style={{ width: 125, height: 125, margin: 'auto' }}
            onLoopComplete={() => {
              setPauseAnimation(true);
              setTimeout(function () {
                setPauseAnimation(false);
              }, 2000);
            }}
          />
        )}
      </div>
      <Title style={{ fontFamily: 'Reckless Neue' }}>
        {`Hi ${user.name.split(' ')[0]}, Welcome to Atlas!`}
      </Title>
      <Text style={{ display: 'block', marginBottom: 30, fontWeight: 400 }}>
        We found an invite for you to join{' '}
        <Text style={{ fontWeight: 'bold' }}>{invite.clinicName}</Text>, would
        you like to proceed?
      </Text>

      <Button
        htmlType="submit"
        shape="round"
        style={{
          height: 44,
          minWidth: 160,
        }}
        type="primary"
        onClick={() => {
          updateInquiry({
            inviteId: invite.id,
          });
          setInquiryStatus(SignUpStates.ENTER_PERSONAL_PHONE_NUMBER);
        }}
      >
        Join {invite.clinicName}
      </Button>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          marginLeft: 20,
          height: 44,
          minWidth: 160,
        }}
        onClick={() => {
          setInquiryStatus(SignUpStates.NEW_OR_EXISTING_PRACTICE);
        }}
      >
        Do something else
      </Button>
    </>
  );
};
