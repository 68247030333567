import React, { useState } from 'react';
import { Row, Button, Input, Col, Form, Divider, Tabs } from 'antd';
import * as backend from '../services/backend';
import '@firebase/firestore';
import DynamicBackendRequestFields from '../components/Development/DynamicBackendRequestFields';
import DynamicFirestoreRequestFields from '../components/Development/DynamicFirestoreRequestFields';
import firebase from 'firebase/app';
import ReactJson from 'react-json-view';

const { TextArea } = Input;
const db = firebase.firestore();

export enum TABLE_TABS {
  BACKEND = 'backend',
  FIRESTORE = 'firestore',
}

export const Development: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [backendResults, setBackendResults] = useState({});
  const [firestoreResults, setFirestoreResults] = useState({});

  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(
    TABLE_TABS.BACKEND
  );

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();

  const handleBackendRequest = async (values: any) => {
    setIsLoading(true);
    try {
      const req = await backend.endpoint(values.request, 'post');
      const parameters = values.parameters?.reduce((acc: any, entry: any) => {
        acc[entry.key] = entry.value;
        return acc;
      }, {});
      const res = await req(parameters);
      const json = JSON.parse(JSON.stringify(res, null, 2));
      setBackendResults(typeof json === 'object' ? json : { response: res });
    } catch (err: any) {
      setBackendResults({ error: err, message: err.message });
    }
    setIsLoading(false);
  };

  const handleFirestoreRequest = async (values: any) => {
    setIsLoading(true);
    try {
      const request = db.collection(values.collection);
      const filteredRequest = values.parameters.reduce(
        (acc: any, entry: any) => {
          return acc.where(entry.key, entry.condition, entry.value);
        },
        request
      );
      const res = await filteredRequest.get();
      const docs = res.docs.map((doc: any) => doc.data());
      setFirestoreResults(JSON.parse(JSON.stringify(docs, null, 2)));
    } catch (err: any) {
      setFirestoreResults({ error: err, message: err.message });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey={selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
      >
        <Tabs.TabPane tab="Backend" key={TABLE_TABS.BACKEND} />
        <Tabs.TabPane tab="Firestore" key={TABLE_TABS.FIRESTORE} />
      </Tabs>
      {selectedTab === TABLE_TABS.BACKEND && (
        <>
          <Form form={form1} onFinish={handleBackendRequest}>
            <Col span={12}>
              <Divider orientation="left">Backend Requests Testing</Divider>
              <div style={{ marginLeft: 20 }}>
                <Form.Item
                  name="request"
                  label="Request URL"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="/motivity/get"></Input>
                </Form.Item>
                <br />
                <Divider orientation="left">Parameters</Divider>
                <DynamicBackendRequestFields />
                <br />
                <Divider orientation="left">Execution</Divider>
                <ReactJson src={backendResults} />
                <br />
                <br />
                <Form.Item>
                  <Button
                    shape="default"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Execute Request
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Form>
        </>
      )}
      {selectedTab === TABLE_TABS.FIRESTORE && (
        <>
          <Form form={form2} onFinish={handleFirestoreRequest}>
            <Col span={12}>
              <Divider orientation="left">Firestore Requests Testing</Divider>
              <div style={{ marginLeft: 20 }}>
                <Form.Item
                  name="collection"
                  label="Collection"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="clinics"></Input>
                </Form.Item>
                <br />
                <Divider orientation="left">Conditions</Divider>
                <DynamicFirestoreRequestFields />
                <br />
                <Divider orientation="left">Execution</Divider>
                <ReactJson src={firestoreResults} />
                <br />
                <br />
                <Form.Item>
                  <Button
                    shape="default"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Execute Request
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Form>
        </>
      )}
    </>
  );
};
