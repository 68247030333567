import * as FirestoreService from '../../services/firestore';
import {
  Modal,
  Button,
  message,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  Typography,
  Input,
  Switch,
} from 'antd';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  AppointmentLocation,
  AttendeeStatus,
  BillingCode,
  getAppointmentSummary,
  IAppointment,
  ICalendarUpdateAppointmentEndpointRequest,
  IClient,
  IUser,
  Modifier,
  UserPermission,
} from '@finni-health/atlas-shared';
import {
  AM_HOURS,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  ERROR_MESSAGE,
} from '../../consts';
import moment, { Moment } from 'moment';
import { AuthContext } from '../AuthProvider';
import { getAppointmentLocationText } from '../../helpers/appointments';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  appointment: IAppointment;
  isOpen: boolean;
  refreshCallback: () => void;
  hideModal: () => void;
}

export interface IEditAppointmentFormValues {
  clientId: string;
  billingCode: BillingCode;
  mod1: Modifier;
  mod2: Modifier;
  date: Moment;
  start: Moment;
  end: Moment;
  location: AppointmentLocation;
  attendeeEmails: string[];
  description: string;
}

export const EditAppointmentModal = ({
  appointment,
  isOpen,
  refreshCallback,
  hideModal,
}: Props) => {
  const user = useContext(AuthContext).user;

  const [form] = Form.useForm<IEditAppointmentFormValues>();
  const attendeeEmails = Form.useWatch('attendeeEmails', form);
  const clientId = Form.useWatch('clientId', form);
  const billingCode = Form.useWatch('billingCode', form);
  const mod1 = Form.useWatch('mod1', form);
  const mod2 = Form.useWatch('mod2', form);
  const start = Form.useWatch('start', form);
  const end = Form.useWatch('end', form);
  const location = Form.useWatch('location', form);
  const createMeet = Form.useWatch<boolean | undefined>('createMeet', form);

  const [selectedClient, setSelectedClient] = useState<IClient>({} as IClient);
  const [clients, setClients] = useState<IClient[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const fetchData = async () => {
    const clients = await FirestoreService.getAllClientsForClinic(
      user.clinicId
    );
    const users = await FirestoreService.getAllUsersForClinic(user.clinicId);

    setClients(clients);
    setUsers(users);
  };

  const fetchClient = async () => {
    const client = await FirestoreService.getClientById(clientId);

    setSelectedClient(client);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (!_.isEmpty(clientId)) {
      fetchClient();
    }
  }, [clientId]);

  useEffect(() => {
    if (location === AppointmentLocation.TELEHEALTH) {
      form.setFieldValue(
        'createMeet',
        !!appointment.meetsLink ||
          appointment.location !== AppointmentLocation.TELEHEALTH
      );
    }
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(attendeeEmails)) {
      for (const email of attendeeEmails) {
        const user = users.find((user) => user.email === email);
        if (
          !_.isEmpty(user) &&
          user?.permissions.includes(UserPermission.RBT)
        ) {
          _.isEmpty(mod1) ||
          [Modifier.U1, Modifier.U3].includes(mod1 as Modifier)
            ? form.setFieldValue('mod1', Modifier.U1)
            : form.setFieldValue('mod2', Modifier.U1);
        }
        if (
          !_.isEmpty(user) &&
          user?.permissions.includes(UserPermission.BCBA)
        ) {
          _.isEmpty(mod1) ||
          [Modifier.U1, Modifier.U3].includes(mod1 as Modifier)
            ? form.setFieldValue('mod1', Modifier.U3)
            : form.setFieldValue('mod2', Modifier.U3);
          break;
        }
      }
    }
  }, [attendeeEmails]);

  const getDisabledEndTimeHours = () => {
    let hours: number[] = [];
    if (start.hour() >= 12) {
      hours = [...AM_HOURS];
    }
    for (let i = hours.length; i < start.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledEndTimeMinutes = (selectedHour: number) => {
    const minutes: number[] = [];
    if (selectedHour === start.hour()) {
      for (let i = 0; i <= start.minute(); i += 15) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const handleUpdateAppointment = async (appointmentId: string) => {
    setIsSaving(true);
    try {
      const values = form.getFieldsValue();

      const modifiers = [];
      !_.isEmpty(mod1) && modifiers.push(mod1);
      !_.isEmpty(mod2) && modifiers.push(mod2);

      let date;
      if (appointment.id.includes('_') && !appointmentId.includes('_')) {
        const originalAppt = await FirestoreService.getAppointmentById({
          id: appointmentId,
          clinicId: appointment.clinicId,
        });
        date = {
          year: moment(originalAppt.startMs).year(),
          month: moment(originalAppt.startMs).month(),
          date: moment(originalAppt.startMs).date(),
        };
      } else {
        date = {
          year: values.date.year(),
          month: values.date.month(),
          date: values.date.date(),
        };
      }

      const updateAppointmentRequest: ICalendarUpdateAppointmentEndpointRequest =
        {
          id: appointmentId,
          clinicId: user.clinicId,
          clientId: values.clientId,
          attendees: values.attendeeEmails.map((email) => ({
            email,
            status:
              appointment.attendees.find((attendee) => attendee.email === email)
                ?.status || AttendeeStatus.NEEDS_ACTION,
          })),
          billingCode: values.billingCode,
          modifiers: modifiers as Modifier[],
          location: values.location,
          summary: getAppointmentSummary(
            selectedClient,
            values.billingCode,
            values.location,
            values.mod1,
            values.mod2
          ),
          description: values.description,
          startMs: values.start.set(date).valueOf(),
          endMs: values.end.set(date).valueOf(),
          meetsLink: appointment.meetsLink,
          createMeet,
        };

      await FirestoreService.updateAppointment(updateAppointmentRequest);

      form.resetFields();
      message.success('Changes saved');
      refreshCallback();
      hideModal();
    } catch (err) {
      message.error(ERROR_MESSAGE);
      console.error(err);
    }
    setIsSaving(false);
  };

  const hideModalIfNotSaving = () => {
    if (!isSaving) {
      hideModal();
    }
  };

  return (
    <>
      <Modal
        title={`Edit ${
          !_.isEmpty(selectedClient) &&
          getAppointmentSummary(
            selectedClient,
            billingCode,
            location,
            mod1,
            mod2
          )
        }`}
        closable={!isSaving}
        footer={null}
        destroyOnClose={true}
        onCancel={hideModalIfNotSaving}
        open={isOpen}
        width={500}
        bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Form
          form={form}
          layout="vertical"
          labelWrap={false}
          labelCol={{ span: 24 }}
          onFinish={() => {
            handleUpdateAppointment(appointment.id);
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="clientId"
                initialValue={appointment.clientId}
                rules={[{ required: true, message: 'Please select a client' }]}
              >
                <Select
                  placeholder="Client"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {clients
                    .sort((a, b) => a.firstName.localeCompare(b.firstName))
                    .map((client) => (
                      <Select.Option key={client.id} value={client.id}>
                        {`(${client.alias}) ${client.firstName} ${client.lastName}`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="attendeeEmails"
                initialValue={appointment.attendees.map(
                  (attendee) => attendee.email
                )}
                rules={[{ required: true, message: 'Please add guests' }]}
              >
                <Select placeholder="Guests" mode="tags" allowClear>
                  {users
                    .sort((a, b) => a.firstName.localeCompare(b.firstName))
                    .map((user) => (
                      <Select.Option key={user.email} value={user.email}>
                        <Text
                          strong
                        >{`${user.firstName} ${user.lastName}`}</Text>
                        <br />
                        <Text>{`${user.email}`}</Text>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="billingCode"
                initialValue={appointment.billingCode}
                rules={[{ required: true, message: 'Please select a service' }]}
              >
                <Select
                  placeholder="Service"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {Object.values(BillingCode).map((code) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="mod1" initialValue={appointment.modifiers[0]}>
                <Select
                  placeholder="Mod"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {Object.values(Modifier)
                    .filter((mod) => mod !== mod2)
                    .map((mod) => (
                      <Select.Option key={mod} value={mod}>
                        {mod}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="mod2" initialValue={appointment.modifiers[1]}>
                <Select
                  placeholder="Mod"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {Object.values(Modifier)
                    .filter((mod) => mod !== mod1)
                    .map((mod) => (
                      <Select.Option key={mod} value={mod}>
                        {mod}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="location"
                initialValue={appointment.location}
                rules={[
                  { required: true, message: 'Please select a location' },
                ]}
              >
                <Select placeholder="Location" showSearch allowClear>
                  {Object.values(AppointmentLocation).map((location) => (
                    <Select.Option key={location} value={location}>
                      {getAppointmentLocationText(location)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* allow creation of links only when no prior link exists. 
            We also do not support removing links, so once created, 
            the checkbox will be disabled and set to true. */}
            {location === AppointmentLocation.TELEHEALTH && (
              <Col span={12}>
                <Row>
                  <Form.Item name="createMeet">
                    <Switch
                      defaultChecked={
                        !!appointment.meetsLink ||
                        appointment.location !== AppointmentLocation.TELEHEALTH
                      }
                      disabled={!!appointment.meetsLink}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                  <Text style={{ marginTop: 6, marginLeft: 8 }}>
                    Google Meet
                  </Text>
                </Row>
              </Col>
            )}
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="date"
                initialValue={moment(appointment.startMs)}
                rules={[
                  { required: true, message: 'Please enter a session date' },
                ]}
              >
                <DatePicker
                  placeholder="Date"
                  style={{ padding: 0 }}
                  autoComplete="off"
                  allowClear={false}
                  bordered={false}
                  format={DISPLAY_DATE_FORMAT}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="start"
                initialValue={moment(appointment.startMs)}
                rules={[
                  { required: true, message: 'Please enter a start time' },
                ]}
              >
                <TimePicker
                  placeholder="Start"
                  autoComplete="off"
                  allowClear={false}
                  bordered={false}
                  format={DISPLAY_TIME_FORMAT}
                  minuteStep={15}
                  onSelect={(time) => {
                    form.setFieldValue('start', time);
                    if (_.isEmpty(end) || end.isBefore(time)) {
                      form.setFieldValue(
                        'end',
                        time.clone().add(30, 'minutes')
                      );
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="end"
                initialValue={moment(appointment.endMs)}
                rules={[
                  { required: true, message: 'Please enter an end time' },
                ]}
              >
                <TimePicker
                  disabled={_.isEmpty(start)}
                  placeholder="End"
                  autoComplete="off"
                  allowClear={false}
                  bordered={false}
                  format={DISPLAY_TIME_FORMAT}
                  minuteStep={15}
                  disabledTime={() => ({
                    disabledHours: getDisabledEndTimeHours,
                    disabledMinutes: getDisabledEndTimeMinutes,
                  })}
                  onSelect={(time) => {
                    form.setFieldValue('end', time);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="description"
                initialValue={appointment.description}
              >
                <TextArea
                  placeholder="Description"
                  allowClear
                  autoSize={{ minRows: 2 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                loading={isSaving}
                style={{ float: 'right' }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
