import React, { useContext, useEffect, useState } from 'react';
import * as FirestoreService from '../../services/firestore';
import {
  Badge,
  Button,
  Card,
  Popover,
  Row,
  Col,
  Tag,
  Tabs,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  IClient,
  IUser,
  INote,
  ICompletedAppointment,
  getSessionNameFromBilling,
  BillingCode,
  Modifier,
  UserPermission,
  IAppointment,
  IDeleteCompletedAppointmentEndpointRequest,
  AttendeeStatus,
} from '@finni-health/atlas-shared';
import {
  DISPLAY_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  COLORS,
  MOTIVITY_FORMS_URL,
} from '../../consts';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import {
  getAppointmentLocationText,
  getCancellationReasonText,
} from '../../helpers/appointments';
import { getBillingCodeColor } from '../../helpers/colors';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IoSchoolOutline } from 'react-icons/io5';
import { RiRocket2Fill } from 'react-icons/ri';
import { FaStamp } from 'react-icons/fa';
import {
  CloseOutlined,
  UndoOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import { AuthContext } from '../AuthProvider';

const { Text, Title } = Typography;

const CANCELLED_TEXT = '(CANCELLED) ';

interface IProps {
  users: IUser[];
  client: IClient;
  completedAppointment: ICompletedAppointment;
  forceOpen: boolean;
  setSelectedEvent: (event: ICompletedAppointment) => void;
  refreshAppointments: () => Promise<void>;
  appointment: IAppointment;
  isCalendarProcessing: boolean;
}

export const CompletedAppointmentCard: React.FC<IProps> = ({
  users,
  client,
  completedAppointment,
  forceOpen,
  setSelectedEvent,
  refreshAppointments,
  appointment,
  isCalendarProcessing,
}: IProps) => {
  const user = useContext(AuthContext).user;
  const [ref, setRef] = useState<any>();

  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);

  const hidePopover = () => {
    setSelectedEvent({} as ICompletedAppointment);
    setOpen(false);
  };

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setSelectedEvent(completedAppointment);
      setOpen(true);
    } else {
      setSelectedEvent({} as ICompletedAppointment);
      setOpen(false);
    }
  };

  const isCompletedAppointmentCancelled = (
    completedAppointment: ICompletedAppointment
  ): boolean => {
    return !_.isEmpty(completedAppointment.cancellationReason);
  };

  useEffect(() => {
    setRef(React.createRef());
  }, []);

  useEffect(() => {
    if (forceOpen == true && ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [forceOpen, ref]);

  useEffect(() => {
    if (forceOpen) {
      handlePopoverOpenChange(true);
    }
  }, [forceOpen]);

  useEffect(() => {
    if (
      !_.isEmpty(user) &&
      !_.isEmpty(client) &&
      !_.isEmpty(completedAppointment)
    ) {
      fetchNotes();
    }
  }, [user, client, completedAppointment]);

  //Loading on cards
  const [sentRefreshCallback, setSentRefreshCallback] =
    useState<boolean>(false);
  useEffect(() => {
    if (sentRefreshCallback && !isCalendarProcessing) {
      setSentRefreshCallback(false);
    }
  }, [isCalendarProcessing]);
  const handleSubRefresh = () => {
    setSentRefreshCallback(true);
    refreshAppointments();
  };

  const handleUnCancelAppointment = async () => {
    setIsCancelling(true);
    setSentRefreshCallback(true);
    //GCal
    try {
      await FirestoreService.updateAppointment({
        ...appointment,
        summary: `${appointment.summary.replaceAll(CANCELLED_TEXT, '')}`,
        attendees: appointment.attendees.map((attendee) => ({
          email: attendee.email,
          status: AttendeeStatus.ACCEPTED,
        })),
      });
    } catch (err) {
      //Pass
      //This is incase GCal ever messes up and deletes appointments
    }
    try {
      const request: IDeleteCompletedAppointmentEndpointRequest = {
        clinicId: completedAppointment.clinicId,
        id: completedAppointment.id,
      };
      await FirestoreService.deleteCompletedAppointment(request);
      message.success('Appointment un-cancelled');
      refreshAppointments();
      hidePopover();
    } catch (err) {
      message.error('Failed to un-cancel appointment');
      console.error(err);
    }
    setIsCancelling(false);
  };

  const fetchNotes = async () => {
    const notes = await FirestoreService.getAllNotesByAppointmentId(
      completedAppointment.id,
      completedAppointment.clinicId
    );
    const tabify = notes.map((note: INote) => {
      if (
        user.permissions?.includes(UserPermission.ADMIN) ||
        user.id == note.userId
      ) {
        const filterNote = JSON.parse(JSON.stringify(note));
        filterNote.startTime = momentTz
          .utc(note.startMs)
          .tz(momentTz.tz.guess())
          .format(DISPLAY_TIME_FORMAT);
        filterNote.endTime = momentTz
          .utc(note.endMs)
          .tz(momentTz.tz.guess())
          .format(DISPLAY_TIME_FORMAT);
        filterNote.date = momentTz
          .utc(note.startMs)
          .tz(momentTz.tz.guess())
          .format(DISPLAY_DATE_FORMAT);
        filterNote.clientFullName = client.alias;
        filterNote.providerSigned = note.providerSignedMs ? (
          <CheckSquareOutlined style={{ color: COLORS.GREEN }} />
        ) : (
          <CloseSquareOutlined style={{ color: COLORS.RED }} />
        );
        filterNote.clientSigned = note.clientSignedMs ? (
          <CheckSquareOutlined style={{ color: COLORS.GREEN }} />
        ) : (
          ''
        ); //Optional field
        filterNote.location = getAppointmentLocationText(note.location);
        filterNote.sessionName = getSessionNameFromBilling(
          note.billingCode as BillingCode,
          note.modifiers as Modifier[]
        );
        const desiredOrdering = new Map();
        desiredOrdering.set('noteType', '');
        desiredOrdering.set('narrative', '');
        return {
          label: <></>,
          key: filterNote.id,
          children: (
            <Col>
              <Row style={{ maxHeight: 400, overflowY: 'auto' }}>
                {Array.from(desiredOrdering, ([key, value]) => {
                  return (
                    filterNote[key].length !== 0 && (
                      <Row
                        style={{ marginBottom: 5, width: '100%' }}
                        align="bottom"
                        justify="space-between"
                      >
                        <div>
                          {value.length !== 0 && (
                            <Text strong style={{ marginRight: 5 }}>
                              {value}
                            </Text>
                          )}
                          {key === 'noteType' && (
                            <Link
                              to={{
                                pathname: `${MOTIVITY_FORMS_URL + note.id}`,
                              }}
                              target="_blank"
                              style={{ fontWeight: 'bold' }}
                            >
                              {filterNote[key]}
                            </Link>
                          )}
                          {key !== 'noteType' && (
                            <Text
                              style={{
                                borderBottom: 2,
                              }}
                            >
                              {filterNote[key]}
                            </Text>
                          )}
                        </div>
                      </Row>
                    )
                  );
                })}
              </Row>
            </Col>
          ),
        };
      }
    });
    setNotes(tabify.filter((e) => e != undefined));
  };

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <>
            {isCompletedAppointmentCancelled(completedAppointment) && (
              <Tooltip placement="bottom" title="Un-cancel appointment">
                <Button
                  type="text"
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={handleUnCancelAppointment}
                  loading={isCancelling}
                >
                  <UndoOutlined style={{ margin: 0 }} />
                </Button>
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="Close">
              <Button
                type="text"
                size="small"
                style={{ marginLeft: 12 }}
                onClick={hidePopover}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          </>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {client && !_.isEmpty(client) ? (
              <Link to={`/client-profile/${client.id}`}>
                <IoSchoolOutline
                  style={{ fontSize: 18, marginBottom: -1, marginRight: 3 }}
                />
                {client?.alias}
              </Link>
            ) : (
              completedAppointment.clientId
            )}
          </Title>
        </Row>
        <Row>
          <Text>
            {getSessionNameFromBilling(
              completedAppointment.billingCode,
              completedAppointment.modifiers
            )}
          </Text>
          <Text type="secondary" style={{ marginLeft: 7 }}>{`${
            completedAppointment.billingCode
          }, ${completedAppointment.modifiers.join(', ')}`}</Text>
        </Row>
        <Row>
          <Text>{`${moment(completedAppointment.startMs).format(
            DISPLAY_TIME_FORMAT
          )} — ${moment(completedAppointment.endMs).format(
            DISPLAY_TIME_FORMAT
          )}`}</Text>
        </Row>
        <Row align="middle">
          <Text>
            {getAppointmentLocationText(completedAppointment.location)}
          </Text>
        </Row>
        <Row align="middle">
          {!isCompletedAppointmentCancelled(completedAppointment) && (
            <Text>
              {completedAppointment.isBilled ? 'Billed' : 'Not Billed'}
            </Text>
          )}
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {completedAppointment.userIds.map((userId) => (
          <Tag style={{ marginBottom: 2, fontSize: 12 }}>
            <Badge
              status={
                isCompletedAppointmentCancelled(completedAppointment)
                  ? 'error'
                  : 'success'
              }
              style={{ marginRight: 3 }}
            />
            {users.find((user) => user.id === userId)?.email}
          </Tag>
        ))}
        {isCompletedAppointmentCancelled(completedAppointment) ? (
          <Col>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Cancellation reason</Text>
            </Row>
            <Row>
              <Text>
                {getCancellationReasonText(
                  completedAppointment.cancellationReason!
                )}
              </Text>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Cancellation description</Text>
            </Row>
            <Row>
              <Text>{completedAppointment.cancellationNotes!}</Text>
            </Row>
          </Col>
        ) : (
          <Tabs size="small" style={{ marginTop: 15 }} items={notes} />
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        //@ts-ignore
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <Card
          ref={ref}
          hoverable
          loading={sentRefreshCallback}
          style={{
            marginBottom: 5,
            minWidth: 'min-content',
            boxShadow: open
              ? '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'
              : undefined,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: '100%', height: 24 }}>
            <Text
              strong
              style={{
                textDecoration: isCompletedAppointmentCancelled(
                  completedAppointment
                )
                  ? 'line-through'
                  : undefined,
                opacity: isCompletedAppointmentCancelled(completedAppointment)
                  ? 0.5
                  : 1,
              }}
            >
              {`${
                isCompletedAppointmentCancelled(completedAppointment)
                  ? CANCELLED_TEXT
                  : ''
              }${
                client && !_.isEmpty(client)
                  ? client.alias
                  : completedAppointment?.summary
              }`}
            </Text>

            {completedAppointment.isBilled ? (
              <Tooltip title={`Billed`}>
                <RiRocket2Fill
                  style={{
                    transform: 'rotate(45deg)',
                    marginRight: 4,
                    fontSize: 20,
                    color: COLORS.GREEN,
                  }}
                />
              </Tooltip>
            ) : isCompletedAppointmentCancelled(completedAppointment) ? (
              <></>
            ) : (
              <Tooltip title={`Completed`}>
                <FaStamp
                  style={{
                    position: 'relative',
                    left: 14,
                    bottom: 3,
                    color: 'white',
                    fontSize: 10,
                  }}
                />
                <InfoCircleFilled
                  style={{
                    marginRight: 4,
                    fontSize: 18,
                    color: COLORS.GREEN,
                  }}
                />
              </Tooltip>
            )}
          </Row>
          <Tag
            color={getBillingCodeColor(
              completedAppointment.billingCode,
              completedAppointment.modifiers
            )}
            style={{ marginTop: 2, marginBottom: 2 }}
          >
            {getSessionNameFromBilling(
              completedAppointment.billingCode,
              completedAppointment.modifiers
            )}
          </Tag>
          <br />
          <Text>
            {getAppointmentLocationText(completedAppointment.location)}
          </Text>
          <br />
          <Text>{`${moment(completedAppointment.startMs).format(
            DISPLAY_TIME_FORMAT
          )} — ${moment(completedAppointment.endMs).format(
            DISPLAY_TIME_FORMAT
          )}`}</Text>
          <br />
          {completedAppointment.userIds.map((userId) => (
            <Tag style={{ marginBottom: 2, fontSize: 11 }}>
              <Badge
                status={
                  isCompletedAppointmentCancelled(completedAppointment)
                    ? 'error'
                    : 'success'
                }
                style={{ marginRight: 3 }}
              />
              {users.find((user) => user.id === userId)?.email}
            </Tag>
          ))}
        </Card>
      </Popover>
    </>
  );
};
