import { Form, Row, Col, Input, Collapse, Divider, Select } from 'antd';
import _ from 'lodash';
import {
  ADDRESS_PLACEHOLDER,
  CITY_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_PLACEHOLDER,
  PHONE_NUMBER_PLACEHOLDER,
  ZIP_CODE_PLACEHOLDER,
  VALID_EMAIL_REGEX,
} from '../../consts';
import {
  ActiveStatus,
  IntakeStatus,
  IGuardian,
  IClientFile,
  PrequalifiedStatus,
  capitalizeFirstLetter,
  USStateCode,
  CountryCode,
} from '@finni-health/atlas-shared';
import { IntakeStatusTag } from '../IntakeStatusTag';
import { PhoneNumberInput } from '../PhoneNumberInput';
import { isPrequalifiedStatus } from '../../helpers/isPrequalifiedStatus';

const { TextArea } = Input;

interface IProps {
  clientFile: IClientFile;
  tempClientFile: IClientFile;
  updateTempClientFile: (value: any) => void;
  tempGuardian: IGuardian;
  updateTempGuardian: (value: any) => void;
}

export const BasicInfoForm: React.FC<IProps> = ({
  clientFile,
  tempClientFile,
  updateTempClientFile,
  tempGuardian,
  updateTempGuardian,
}: IProps) => (
  <>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          label="First Name"
          rules={[
            {
              required: false,
              message: 'Please provide a First Name',
            },
          ]}
        >
          <Input
            tabIndex={1}
            placeholder={FIRST_NAME_PLACEHOLDER}
            value={tempGuardian.firstName}
            onChange={(e) =>
              updateTempGuardian({
                firstName: capitalizeFirstLetter(e.target.value),
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Last Name"
          rules={[
            {
              required: false,
              message: 'Please provide a Last Name',
            },
          ]}
        >
          <Input
            tabIndex={2}
            placeholder={LAST_NAME_PLACEHOLDER}
            value={tempGuardian.lastName}
            onChange={(e) =>
              updateTempGuardian({
                lastName: capitalizeFirstLetter(e.target.value),
              })
            }
          />
        </Form.Item>
      </Col>
    </Row>
    <Divider orientation="left">Guardian Address</Divider>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          label="Address Line 1"
          rules={[
            {
              required: false,
              message: 'Please provide an Address',
            },
          ]}
        >
          <Input
            tabIndex={3}
            placeholder={ADDRESS_PLACEHOLDER}
            value={tempClientFile.address?.line1 || ''}
            onChange={(e) =>
              updateTempClientFile({
                address: { ...tempClientFile.address, line1: e.target.value },
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Address Line 2">
          <Input
            tabIndex={4}
            placeholder={ADDRESS_PLACEHOLDER}
            value={tempClientFile.address?.line2 || ''}
            onChange={(e) =>
              updateTempClientFile({
                address: { ...tempClientFile.address, line2: e.target.value },
              })
            }
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={6}>
        <Form.Item
          label="City"
          rules={[
            {
              required: false,
              message: 'Please provide city',
            },
          ]}
        >
          <Input
            tabIndex={5}
            placeholder={CITY_PLACEHOLDER}
            value={tempClientFile.address?.city || ''}
            onChange={(e) =>
              updateTempClientFile({
                address: { ...tempClientFile.address, city: e.target.value },
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="State"
          rules={[
            {
              required: false,
              message: 'Please provide state',
            },
          ]}
        >
          <Select
            showSearch
            tabIndex={6}
            value={tempClientFile.address?.state || ''}
            onChange={(value) =>
              updateTempClientFile({
                address: { ...tempClientFile.address, state: value },
              })
            }
          >
            {Object.values(USStateCode).map((state) => (
              <Select.Option value={state} key={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Country">
          <Input disabled={true} value={CountryCode.US} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Zip Code">
          <Input
            tabIndex={7}
            maxLength={5}
            placeholder={ZIP_CODE_PLACEHOLDER}
            value={tempClientFile.address?.zipCode || ''}
            onChange={(e) =>
              updateTempClientFile({
                address: {
                  ...tempClientFile.address,
                  zipCode: e.target.value,
                },
              })
            }
          />
        </Form.Item>
      </Col>
    </Row>
    <Divider orientation="left">Contact Information</Divider>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          label="Email"
          rules={[
            {
              pattern: VALID_EMAIL_REGEX,
              message: 'Please provide a valid Email',
            },
          ]}
        >
          <Input
            tabIndex={9}
            type="email"
            placeholder={EMAIL_PLACEHOLDER}
            value={tempGuardian.email}
            onChange={(e) =>
              updateTempGuardian({
                email: e.target.value,
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Primary Phone Number">
          <PhoneNumberInput
            placeholder={PHONE_NUMBER_PLACEHOLDER}
            value={tempGuardian.phoneNumber?.primary || ''}
            onChange={(e: any) =>
              updateTempGuardian({
                phoneNumber: {
                  ...tempGuardian.phoneNumber,
                  primary: e.target.value,
                },
              })
            }
          />
        </Form.Item>
      </Col>
    </Row>
    <Row justify="end" gutter={24} style={{ marginTop: -15 }}>
      <Col span={12}>
        <Collapse ghost>
          <Collapse.Panel header="Additional Phone Numbers" key="1">
            <Form.Item label="Home Phone Number">
              <PhoneNumberInput
                placeholder={PHONE_NUMBER_PLACEHOLDER}
                value={tempGuardian.phoneNumber?.home || ''}
                onChange={(e: any) =>
                  updateTempGuardian({
                    phoneNumber: {
                      ...tempGuardian.phoneNumber,
                      home: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Work Phone Number">
              <PhoneNumberInput
                placeholder={PHONE_NUMBER_PLACEHOLDER}
                value={tempGuardian.phoneNumber?.work || ''}
                onChange={(e: any) =>
                  updateTempGuardian({
                    phoneNumber: {
                      ...tempGuardian.phoneNumber,
                      work: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
            <Form.Item label="Cell Phone Number" style={{ marginBottom: 0 }}>
              <PhoneNumberInput
                placeholder={PHONE_NUMBER_PLACEHOLDER}
                value={tempGuardian.phoneNumber?.cell || ''}
                onChange={(e: any) =>
                  updateTempGuardian({
                    phoneNumber: {
                      ...tempGuardian.phoneNumber,
                      cell: e.target.value,
                    },
                  })
                }
              />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  </>
);
