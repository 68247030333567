import * as FirestoreService from '../../services/firestore';
import {
  Modal,
  Button,
  Form,
  Row,
  message,
  Tabs,
  Spin,
  Space,
  Switch,
  Typography,
} from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ERROR_MESSAGE } from '../../consts';
import {
  IClient,
  IClientPayer,
  IGuardian,
  IClientFile,
  ICreateClientEndpointRequest,
} from '@finni-health/atlas-shared';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { BasicInfoForm } from './BasicInfoForm';
import { ClientInfoForm } from './ClientInfoForm';
import { PayersForm } from './PayersForm';

const { Text } = Typography;

export const enum EDIT_CLIENT_FILE_MODAL_TABLE_TABS {
  CLIENT = 'client',
  GUARDIAN = 'guardian',
}

interface Props {
  clientFile: IClientFile;
  refreshCallback: () => Promise<void>;
  hideModal: () => void;
  isVisible: boolean;
  defaultTab?: EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT;
}

export const EditClientFileModal = ({
  clientFile,
  refreshCallback,
  hideModal,
  isVisible,
  defaultTab,
}: Props) => {
  const [client, setClient] = useState<IClient>({} as IClient);
  const [tempClient, setTempClient] = useState<IClient>({} as IClient);

  const [guardian, setGuardian] = useState<IGuardian>({} as IGuardian);
  const [tempGuardian, setTempGuardian] = useState<IGuardian>({} as IGuardian);

  const [tempClientFile, setTempClientFile] = useState<IClientFile>(
    {} as IClientFile
  );

  const [selectedTab, setSelectedTab] =
    useState<EDIT_CLIENT_FILE_MODAL_TABLE_TABS>(
      defaultTab || EDIT_CLIENT_FILE_MODAL_TABLE_TABS.GUARDIAN
    );
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(clientFile)) {
      fetchData();
    }
  }, [clientFile]);

  const fetchData = async () => {
    setIsLoading(true);

    const guardian = await FirestoreService.getGuardianById(
      clientFile.guardianId
    );

    let client;
    try {
      client = await FirestoreService.getClientByGuardianId(
        guardian.id,
        guardian.clinicId
      );
    } catch (_) {
      // ClientFile doesn't have a client yet, swallow the error and continue
    }

    setClient(client || ({} as IClient));
    setTempClient(_.cloneDeep(client) || ({} as IClient));
    setGuardian(guardian);
    setTempGuardian(_.cloneDeep(guardian));
    setTempClientFile(_.cloneDeep(clientFile));
    setIsLoading(false);
  };

  const updateTempGuardian = (source: Partial<IGuardian>) => {
    const merged = _.merge(_.cloneDeep(tempGuardian), source);
    setTempGuardian(merged);
  };

  const updateTempClient = (source: Partial<IClient>) => {
    const merged = _.merge(_.cloneDeep(tempClient), source);
    setTempClient(merged);
  };

  const updateTempClientFile = (source: Partial<IClientFile>) => {
    const merged = _.merge(_.cloneDeep(tempClientFile), source);
    setTempClientFile(merged);
  };

  const handleSave = async () => {
    setIsSaving(true);

    try {
      if (!_.isEqual(tempGuardian, guardian)) {
        await FirestoreService.updateGuardian({
          ...tempGuardian,
          id: guardian.id,
        });
      }

      if (!_.isEqual(tempClientFile, clientFile)) {
        await FirestoreService.updateClientFile({
          ...tempClientFile,
          id: clientFile.id,
        });
      }

      if (_.isEmpty(client) && !_.isEmpty(tempClient)) {
        const createClientRequest: ICreateClientEndpointRequest = {
          clinicId: guardian.clinicId,
          firstName: tempClient.firstName,
          middleName: '',
          lastName: tempClient.lastName,
          alias: '',
          dateOfBirth: tempClient.dateOfBirth,
          sex: tempClient.sex,
        };

        const clientId = await FirestoreService.createClient(
          createClientRequest
        );
        await FirestoreService.updateClientFile({
          id: clientFile.id,
          clientId,
        });
      } else if (!_.isEqual(client, tempClient)) {
        await FirestoreService.updateClient({ ...tempClient, id: client.id });
      }

      await refreshCallback();
      message.success('Changes saved');
      hideModal();
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title={
        client && !_.isEmpty(client)
          ? `Edit File for ${client.alias}`
          : 'Edit Client File'
      }
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      {isLoading ? (
        <Row justify="center" align="middle">
          <Spin size="large" />
        </Row>
      ) : (
        <Form layout="vertical" labelCol={{ span: 24 }} onFinish={handleSave}>
          <Tabs
            style={{ marginTop: -15 }}
            activeKey={selectedTab}
            onChange={(key) => {
              setSelectedTab(key as EDIT_CLIENT_FILE_MODAL_TABLE_TABS);
            }}
          >
            <Tabs.TabPane
              tab="Client"
              key={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
            >
              <ClientInfoForm
                tempClient={tempClient}
                updateTempClient={updateTempClient}
              />
              <Row>
                <Text
                  style={{
                    fontSize: 14,
                    color: 'rgba(0, 0, 0, 0.85)',
                    marginBottom: 8,
                  }}
                >{`Active Status`}</Text>
              </Row>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={tempClientFile.isHot}
                onChange={(checked) => {
                  updateTempClientFile({ isHot: checked });
                }}
                style={{ width: 65, marginLeft: 8 }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Guardian"
              key={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.GUARDIAN}
            >
              <BasicInfoForm
                clientFile={clientFile}
                tempClientFile={tempClientFile}
                updateTempClientFile={updateTempClientFile}
                tempGuardian={tempGuardian}
                updateTempGuardian={updateTempGuardian}
              />
            </Tabs.TabPane>
          </Tabs>
          <Row justify="end" style={{ marginTop: 15 }}>
            <Space>
              <Button disabled={isSaving} onClick={hideModal}>
                Cancel
              </Button>
              <Button
                loading={isSaving}
                type="primary"
                htmlType="submit"
                disabled={
                  _.isEqual(tempGuardian, guardian) &&
                  _.isEqual(tempClient, client) &&
                  _.isEqual(tempClientFile, clientFile)
                }
              >
                Save
              </Button>
            </Space>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
