import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  Input,
  Statistic,
  Tabs,
  Collapse,
  Tag,
  Button,
} from 'antd';
import { Timestamp } from '@firebase/firestore-types';
import { AuthContext } from '../components/AuthProvider';
import * as FirestoreService from '../services/firestore';
import _ from 'lodash';
import {
  IntakeStatus,
  IClient,
  IGuardian,
  IPayer,
  IAddress,
  ActiveStatus,
  PrequalifiedStatus,
  stringToColor,
  IClientFile,
} from '@finni-health/atlas-shared';
import { Link, useParams } from 'react-router-dom';
import {
  EditOutlined,
  IdcardOutlined,
  RightOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { IoSchoolOutline, IoHeartCircleOutline } from 'react-icons/io5';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../consts';
import { IntakeStatusTag } from '../components/IntakeStatusTag';
import { InsuranceCardModal } from '../components/InsuranceCardModal';
import {
  EditClientFileModal,
  EDIT_CLIENT_FILE_MODAL_TABLE_TABS,
} from '../components/EditClientFileModal/EditClientFileModal';
import { CreateClientModal } from '../components/EditClientFileModal/CreateClientModal';

const { Link: LinkText, Text } = Typography;
const { Search } = Input;

const enum TABLE_TABS {
  ACTIVE = 'active',
  INACTIVE = 'prequalified',
}

export const Clients: React.FC = () => {
  const user = useContext(AuthContext).user;
  const { tab } = useParams<{
    tab?: string;
  }>();

  // Client Table
  const [allClients, setAllClients] = useState<IClient[]>([]);
  const [activeClients, setActiveClients] = useState<IClient[]>([]);
  const [prequalifiedClients, setPrequalifiedClients] = useState<IClient[]>([]);
  const [dataSource, setDataSource] = useState<IClient[]>([]);
  const [hiddenDataSource, setHiddenDataSource] = useState<IClient[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(TABLE_TABS.ACTIVE);

  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] =
    useState<boolean>(false);
  const [editClientFileModalClientFile, setEditClientFileModalClientFile] =
    useState<IClientFile>({} as IClientFile);
  const hideEditClientFileModal = () => {
    setEditClientFileModalClientFile({} as IClientFile);
    setIsEditClientFileModalVisible(false);
  };

  // Create Client Modal
  const [isCreateClientFileModalVisible, setIsCreateClientFileModalVisible] =
    useState<boolean>(false);
  const [createClientFileModalClientFile, setCreateClientFileModalClientFile] =
    useState<IClientFile>({} as IClientFile);
  const hideCreateClientFileModal = () => {
    setCreateClientFileModalClientFile({} as IClientFile);
    setIsCreateClientFileModalVisible(false);
  };

  // View Insurance Modal
  const [isViewInsuranceModalVisible, setIsViewInsuranceModalVisible] =
    useState<boolean>(false);
  const [viewInsuranceModalGuardian, setViewInsuranceModalGuardian] =
    useState<IGuardian>({} as IGuardian);
  const hideViewInsuranceModalGuardian = () => {
    setViewInsuranceModalGuardian({} as IGuardian);
    setIsViewInsuranceModalVisible(false);
  };

  //todo add client here
  const handleAddClient = () => {
    setIsCreateClientFileModalVisible(true);
  };

  //set active tab on URL change
  useEffect(() => {
    //get URL
    const url = window.location.pathname;

    //get tab from URL
    const tab = url.split('/')[2];

    //set active tab
    if (tab === 'create') {
      setIsCreateClientFileModalVisible(true);
    }
  }, []);

  //set URL on active tab change
  useEffect(() => {
    let url = '/clients';

    //Add tab to URL
    url += isCreateClientFileModalVisible ? `/create` : '';

    //apply URL to browser if not already there
    if (location.pathname !== url) {
      window.history.replaceState(null, 'Atlas: ABA Scheduling Software', url);
    }
  }, [isCreateClientFileModalVisible]);

  const fetchClients = async () => {
    setIsTableLoading(true);
    let clients = await FirestoreService.getAllClientsForClinic(
      user.clinicId,
      true
    );

    clients = await Promise.all(
      clients.map(async (client) => {
        try {
          const clientFile = await FirestoreService.getClientFileByClientId(
            client.id,
            client.clinicId
          );

          const guardian = await FirestoreService.getGuardianById(
            clientFile.guardianId
          );

          const name = `${client.firstName} ${client.lastName}`;

          return {
            ...client,
            name,
            guardian,
            clientFile,
          };
        } catch (err) {
          return client;
        }
      })
    );

    const allClients = clients;
    const activeClients = clients.filter(
      (client: any) => client.clientFile.isHot
    );
    const prequalifiedClients = clients.filter(
      (client: any) => !client.clientFile.isHot
    );

    setAllClients(allClients);
    setActiveClients(activeClients);
    setPrequalifiedClients(prequalifiedClients);
    setDataSource(activeClients);
    setHiddenDataSource(
      clients.filter((client: any) =>
        [IntakeStatus.UNQUALIFIED, IntakeStatus.CHURNED].includes(
          client.clientFile?.intakeStatus
        )
      )
    );
    setIsTableLoading(false);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchClients();
    }
  }, [user]);

  useEffect(() => {
    switch (selectedTab) {
      case TABLE_TABS.ACTIVE:
        setDataSource(activeClients);
        break;
      case TABLE_TABS.INACTIVE:
        setDataSource(prequalifiedClients);
        break;
    }
  }, [prequalifiedClients, activeClients, allClients, selectedTab]);

  const tableColumns = [
    {
      title: 'Client',
      dataIndex: 'name',
      sorter: (a: IClient, b: IClient) => a.lastName.localeCompare(b.lastName),
      render: (name: string, row: any) => (
        <Tooltip title="View Client">
          <Link to={`/client-profile/${row.id}`}>
            <IoSchoolOutline
              style={{ fontSize: 18, marginBottom: -3, marginRight: 5 }}
            />
            {`${name} (${row.alias})`}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: 'Guardian',
      dataIndex: 'guardian',
      render: (guardian: IGuardian) =>
        !_.isEmpty(guardian) && (
          <Tooltip title="View Guardian">
            <Link to={`/guardian-profile/${guardian.id}`}>
              <IoHeartCircleOutline
                style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }}
              />
              {`${guardian.lastName}, ${guardian.firstName}`}
            </Link>
          </Tooltip>
        ),
    },
    {
      title: 'Address',
      dataIndex: ['clientFile', 'address'],
      render: (address: IAddress) => (
        <p>
          {`${address.line1 ? address.line1 : ''}${
            address.line2 ? ', ' + address.line2 : ''
          }${address.city ? ', ' + address.city : ''}`}
        </p>
      ),
    },
    // {
    //   title: 'Phone Number',
    //   dataIndex: 'guardian',
    //   sorter: (a: any, b: any) =>
    //     a.clientFile.intakeStatus.localeCompare(b.clientFile.intakeStatus),
    //   render: (status: IntakeStatus) => <IntakeStatusTag status={status} />,
    // },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => (
        <p>{`${moment(createdAt.toDate()).format(DISPLAY_DATE_FORMAT)}`}</p>
      ),
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
    },
    {
      width: 15,
      align: 'center' as any,
      render: (_text: string, row: any) => (
        <Row wrap={false} justify="center" align="middle">
          <Space size="large">
            <div
              onClick={() => {
                setEditClientFileModalClientFile(row.clientFile);
                setIsEditClientFileModalVisible(true);
              }}
            >
              <Tooltip title="Edit">
                <LinkText>
                  <EditOutlined style={{ fontSize: 16 }} />
                  Edit
                </LinkText>
              </Tooltip>
            </div>
          </Space>
        </Row>
      ),
    },
  ];

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);

    let unfilteredData;
    switch (selectedTab) {
      case TABLE_TABS.ACTIVE:
        unfilteredData = activeClients;
        break;
      case TABLE_TABS.INACTIVE:
        unfilteredData = prequalifiedClients;
        break;
      default:
        unfilteredData = allClients;
        break;
    }

    const filteredData = unfilteredData.filter(
      (client: any) =>
        client.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
        client.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
        client.alias.toLowerCase().includes(searchString.toLowerCase()) ||
        client.guardian.firstName
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        client.guardian.lastName
          .toLowerCase()
          .includes(searchString.toLowerCase())
    );
    setDataSource(filteredData);
  };

  return (
    <>
      <Row align="middle" justify="space-between">
        <Tabs
          defaultActiveKey={selectedTab.valueOf()}
          onChange={(key) => {
            setSelectedTab(key as TABLE_TABS);
          }}
        >
          <Tabs.TabPane
            tab={
              <Statistic
                title="Active"
                value={activeClients.length}
                loading={isTableLoading}
              />
            }
            key={TABLE_TABS.ACTIVE}
          />
          <Tabs.TabPane
            tab={
              <Statistic
                title="Inactive"
                value={prequalifiedClients.length}
                loading={isTableLoading}
              />
            }
            key={TABLE_TABS.INACTIVE}
          />
        </Tabs>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={handleAddClient}
        >
          <PlusOutlined style={{ marginLeft: -3 }} />
          Create
        </Button>
      </Row>
      <Search
        placeholder="Search by First or Last Name"
        allowClear
        value={searchString}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
      />
      <Table
        columns={tableColumns}
        dataSource={dataSource}
        loading={isTableLoading}
      />
      {!_.isEmpty(hiddenDataSource) && selectedTab !== TABLE_TABS.ACTIVE && (
        <Collapse
          ghost
          expandIcon={({ isActive }) => (
            <RightOutlined
              rotate={isActive ? 90 : 0}
              style={{ fontSize: 15, position: 'relative', top: 3 }}
            />
          )}
        >
          <Collapse.Panel
            header={
              <Text
                style={{ fontSize: 17, fontWeight: 500 }}
              >{`${hiddenDataSource.length} hidden`}</Text>
            }
            key={1}
          >
            <Table
              columns={tableColumns}
              dataSource={hiddenDataSource}
              loading={isTableLoading}
            />
          </Collapse.Panel>
        </Collapse>
      )}
      <EditClientFileModal
        hideModal={hideEditClientFileModal}
        isVisible={isEditClientFileModalVisible}
        clientFile={editClientFileModalClientFile}
        refreshCallback={fetchClients}
        defaultTab={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
      />
      <CreateClientModal
        hideModal={hideCreateClientFileModal}
        isVisible={isCreateClientFileModalVisible}
        clientFile={createClientFileModalClientFile}
        refreshCallback={fetchClients}
      />
      <InsuranceCardModal
        guardian={viewInsuranceModalGuardian}
        hideModal={hideViewInsuranceModalGuardian}
        isVisible={isViewInsuranceModalVisible}
      />
    </>
  );
};
