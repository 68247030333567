import { Row, Col, Typography, Form, Input, Select, DatePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { DB_DATE_FORMAT } from '../../consts';
import {
  DayBlock,
  IContact,
  IClientFile,
  TherapistGender,
  WeekBlock,
  USStateCode,
  CountryCode,
} from '@finni-health/atlas-shared';
import { PhoneNumberInput } from '../PhoneNumberInput';

const { Title } = Typography;
const { TextArea } = Input;

interface Props {
  isEditingDisabled: boolean;
  tempClientFile: IClientFile;
  updateTempClientFile: (source: Partial<IClientFile>) => void;
  setTempClientFile: (source: IClientFile) => void;
}

export const ClientTherapyPreferencesSection = ({
  isEditingDisabled,
  tempClientFile,
  updateTempClientFile,
  setTempClientFile,
}: Props) => {
  const updateSelectedAvailability = (
    weekBlock: WeekBlock,
    dayBlocks: DayBlock[]
  ) => {
    const availability = tempClientFile?.therapyPreferences?.availability || {};
    if (dayBlocks.length === 0) {
      delete availability[weekBlock];
    } else {
      availability[weekBlock] = dayBlocks;
    }
    setTempClientFile({
      ...tempClientFile,
      therapyPreferences: {
        ...tempClientFile?.therapyPreferences,
        availability: availability,
      },
    });
  };

  return (
    <>
      <Title id={'therapyPreferences'} level={2} style={{ marginTop: 40 }}>
        Therapy Preferences
      </Title>
      <Title id={'primaryTherapyAddress'} level={3}>
        Primary Therapy Address
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Address Line 1">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.address?.line1}
              onChange={(e) => {
                updateTempClientFile({
                  address: {
                    ...tempClientFile?.address,
                    line1: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address Line 2">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.address?.line2}
              onChange={(e) => {
                updateTempClientFile({
                  address: {
                    ...tempClientFile?.address,
                    line2: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="City">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.address?.city}
              onChange={(e) => {
                updateTempClientFile({
                  address: {
                    ...tempClientFile?.address,
                    city: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="State">
            <Select
              disabled={isEditingDisabled}
              value={tempClientFile?.address?.state}
              onChange={(value) => {
                updateTempClientFile({
                  address: {
                    ...tempClientFile?.address,
                    state: value,
                  },
                });
              }}
            >
              {Object.values(USStateCode).map((state) => (
                <Select.Option value={state} key={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Country">
            <Select
              disabled={true}
              value={CountryCode.US}
              onChange={(_) => {
                updateTempClientFile({
                  address: {
                    ...tempClientFile?.address,
                    country: CountryCode.US,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zip Code">
            <Input
              type="number"
              maxLength={5}
              disabled={isEditingDisabled}
              value={tempClientFile?.address?.zipCode}
              onChange={(e) => {
                updateTempClientFile({
                  address: {
                    ...tempClientFile?.address,
                    zipCode: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Address Notes">
            <TextArea
              disabled={isEditingDisabled}
              autoSize={true}
              value={tempClientFile?.addressNotes}
              onChange={(e) => {
                updateTempClientFile({
                  addressNotes: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'secondaryContact'} level={3}>
        Secondary Contact
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="First Name">
            <Input
              disabled={isEditingDisabled}
              value={
                tempClientFile?.therapyPreferences?.secondaryContact?.firstName
              }
              onChange={(e) => {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    secondaryContact: {
                      ...tempClientFile?.therapyPreferences?.secondaryContact,
                      firstName: e.target.value,
                    } as IContact,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Last Name">
            <Input
              disabled={isEditingDisabled}
              value={
                tempClientFile?.therapyPreferences?.secondaryContact?.lastName
              }
              onChange={(e) => {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    secondaryContact: {
                      ...tempClientFile?.therapyPreferences?.secondaryContact,
                      lastName: e.target.value,
                    } as IContact,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Form.Item label="Date of Birth">
          <DatePicker
            disabled={isEditingDisabled}
            placeholder="YYYY-MM-DD"
            format="YYYY-MM-DD"
            disabledDate={(curr) => curr && curr > moment().startOf('day')}
            value={
              tempClientFile?.therapyPreferences?.secondaryContact?.dateOfBirth
                ? moment(
                    tempClientFile.therapyPreferences.secondaryContact
                      .dateOfBirth
                  )
                : null
            }
            onChange={(date) => {
              if (date === null) {
                const tempSecondaryContact =
                  tempClientFile?.therapyPreferences?.secondaryContact;
                delete tempSecondaryContact?.dateOfBirth;
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    secondaryContact: tempSecondaryContact,
                  },
                });
              } else {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    secondaryContact: {
                      ...tempClientFile?.therapyPreferences?.secondaryContact,
                      dateOfBirth: moment(date).format(DB_DATE_FORMAT),
                    } as IContact,
                  },
                });
              }
            }}
          />
        </Form.Item>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Phone Number">
            <PhoneNumberInput
              disabled={isEditingDisabled}
              value={
                tempClientFile?.therapyPreferences?.secondaryContact
                  ?.phoneNumber
              }
              onChange={(e: any) => {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    secondaryContact: {
                      ...tempClientFile?.therapyPreferences?.secondaryContact,
                      phoneNumber: e.target.value,
                    } as IContact,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Email">
            <Input
              disabled={isEditingDisabled}
              value={
                tempClientFile?.therapyPreferences?.secondaryContact?.email
              }
              onChange={(e) => {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    secondaryContact: {
                      ...tempClientFile?.therapyPreferences?.secondaryContact,
                      email: e.target.value,
                    } as IContact,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'language'} level={3}>
        Language
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Primary Language">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.therapyPreferences?.primaryLanguage}
              onChange={(e) => {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    primaryLanguage: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Preferred Therapy Language">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.therapyPreferences?.preferredLanguage}
              onChange={(e) => {
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile?.therapyPreferences,
                    preferredLanguage: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'genderPreference'} level={3}>
        Therapist Gender Preference
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Preferred Therapist Gender">
            <Select
              showSearch
              disabled={isEditingDisabled}
              value={
                tempClientFile?.therapyPreferences?.preferredTherapistGender
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(e: TherapistGender) =>
                updateTempClientFile({
                  therapyPreferences: {
                    ...tempClientFile.therapyPreferences,
                    preferredTherapistGender: TherapistGender[e],
                  },
                })
              }
            >
              {Object.keys(TherapistGender).map((gender) => (
                <Select.Option key={gender}>{gender}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Title id={'availability'} level={3}>
        Availability
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Monday, Wednesday, Friday Availability">
            <Select
              showSearch
              disabled={isEditingDisabled}
              mode="multiple"
              value={
                tempClientFile?.therapyPreferences?.availability?.monWedFri
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(e: DayBlock[]) => {
                updateSelectedAvailability(WeekBlock.MON_WED_FRI, e);
              }}
            >
              {Object.keys(DayBlock).map((dayBlock) => (
                <Select.Option key={dayBlock}>{dayBlock}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tuesday, Thursday Availability">
            <Select
              showSearch
              disabled={isEditingDisabled}
              mode="multiple"
              value={
                tempClientFile?.therapyPreferences?.availability?.tuesThurs
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(e: DayBlock[]) => {
                updateSelectedAvailability(WeekBlock.TUES_THURS, e);
              }}
            >
              {Object.keys(DayBlock).map((dayBlock) => (
                <Select.Option key={dayBlock}>{dayBlock}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
