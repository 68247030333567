import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Select,
  DatePicker,
  Divider,
  Radio,
} from 'antd';
import _ from 'lodash';
import { useEffect, useState, useContext } from 'react';
import * as FirestoreService from '../services/firestore';
import {
  CountryCode,
  IUser,
  UserPermission,
  USStateCode,
  Credential,
  ICredential,
} from '@finni-health/atlas-shared';
import moment, { Moment } from 'moment';
import {
  DB_DATE_FORMAT,
  ERROR_MESSAGE,
  VALID_EMAIL_REGEX,
  VALID_ZIP_CODE_REGEX,
} from '../consts';
import { PhoneNumberInput } from './PhoneNumberInput';
import { CredentialInput } from './CredentialInput';
import { AuthContext } from './AuthProvider';

const { TextArea } = Input;

interface Props {
  user: IUser;
  isVisible: boolean;
  hideModal: () => void;
  refreshCallback: () => Promise<void>;
}

interface IEditUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: Moment;
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
  country: CountryCode;
  addressNotes: string;
  permissions: UserPermission[];
  isFullTime: boolean;
  npi: string;
  credentials: ICredential[];
}

export const EditUserModal = ({
  user,
  isVisible,
  hideModal,
  refreshCallback,
}: Props) => {
  const refreshAuthContext = useContext(AuthContext).refresh;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [form] = Form.useForm<IEditUserFormValues>();

  useEffect(() => {
    form.setFieldsValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      dateOfBirth: moment(user.dateOfBirth),
      line1: user.address?.line1,
      line2: user.address?.line2,
      city: user.address?.city,
      state: user.address?.state,
      zipCode: user.address?.zipCode,
      country: user.address?.country,
      addressNotes: user.addressNotes,
      permissions: user?.permissions?.filter(
        (p) => p !== UserPermission.SUPERADMIN
      ),
      isFullTime: user.isFullTime,
      npi: user.npi,
      credentials: user.credentials || [],
    });
  }, [user]);

  const handleFormOnFinish = async () => {
    setIsSaving(true);

    const values = form.getFieldsValue();
    const credentials = form.getFieldValue('credentials'); //arrays don't work properly with getFieldsValue

    try {
      await FirestoreService.updateUser({
        id: user.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        dateOfBirth: values.dateOfBirth.format(DB_DATE_FORMAT),
        address: {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
        },
        addressNotes: values.addressNotes,
        permissions: values.permissions,
        isFullTime: values.isFullTime,
        npi: values.npi,
        credentials,
      });
      await refreshCallback();
      await refreshAuthContext();
      hideModal();
      message.success('Changes saved');
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  const handleUpdateCredential = (credential: ICredential) => {
    if (credential) {
      const currCredentials = form.getFieldValue('credentials') || [];
      const credIndex = currCredentials?.findIndex(
        (cred: ICredential) => cred.type === credential.type
      );
      if (credIndex === -1) {
        currCredentials.push(credential);
      } else {
        currCredentials[credIndex] = credential;
      }
      form.setFieldValue('credentials', currCredentials);
    }
  };

  return (
    <Modal
      title="Edit Staff Profile"
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Form
        layout="vertical"
        labelCol={{ span: 24 }}
        onFinish={handleFormOnFinish}
        form={form}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                },
              ]}
              initialValue={user.firstName}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              initialValue={user.lastName}
            >
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              initialValue={user.email}
              rules={[
                {
                  required: true,
                  pattern: VALID_EMAIL_REGEX,
                  message: 'Please enter a valid email address',
                },
              ]}
            >
              <Input disabled={isSaving} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              initialValue={user.phoneNumber}
            >
              <PhoneNumberInput disabled={isSaving} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              initialValue={moment(user.dateOfBirth)}
            >
              <DatePicker
                placeholder="YYYY-MM-DD"
                allowClear={false}
                autoComplete="off"
                disabledDate={(curr) => curr && curr > moment().startOf('day')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Roles"
              name="permissions"
              initialValue={user?.permissions?.filter(
                (p) => p !== UserPermission.SUPERADMIN
              )}
            >
              <Select
                mode="multiple"
                onChange={(value) => {
                  if (
                    value.includes(UserPermission.BCBA) &&
                    value.includes(UserPermission.RBT)
                  ) {
                    const perms = user.permissions.filter(
                      (permission) => permission !== UserPermission.RBT
                    );
                    form.setFieldsValue({
                      permissions: perms,
                    });
                  }
                }}
              >
                <Select.Option
                  key={UserPermission.RBT}
                  value={UserPermission.RBT}
                >
                  {UserPermission.RBT}
                </Select.Option>
                <Select.Option
                  key={UserPermission.BCBA}
                  value={UserPermission.BCBA}
                >
                  {UserPermission.BCBA}
                </Select.Option>
                <Select.Option
                  key={UserPermission.ADMIN}
                  value={UserPermission.ADMIN}
                >
                  {UserPermission.ADMIN}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employment Type"
              name="isFullTime"
              initialValue={user.isFullTime}
            >
              <Radio.Group>
                <Radio value={false}> Part-time </Radio>
                <Radio value={true}> Full-time </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Staff Address</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Address Line 1"
              name="line1"
              initialValue={user.address?.line1}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Address Line 2"
              name="line2"
              initialValue={user.address?.line2}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="City"
              name="city"
              initialValue={user.address?.city}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="State"
              name="state"
              initialValue={user.address?.state}
            >
              <Select showSearch disabled={isSaving}>
                {Object.values(USStateCode).map((state) => (
                  <Select.Option value={state} key={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              initialValue={user.address?.zipCode}
              rules={[
                {
                  pattern: VALID_ZIP_CODE_REGEX,
                  message: 'Please enter a 5 digit zip code',
                },
              ]}
            >
              <Input maxLength={5} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Country"
              name="country"
              initialValue={CountryCode.US}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Address Notes"
              name="addressNotes"
              initialValue={user.addressNotes}
            >
              <TextArea autoSize={true} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Credentials</Divider>
        <Col span={24}>
          <Form.Item label="NPI" name="npi" initialValue={user.npi}>
            <TextArea autoSize={true} disabled={isSaving} />
          </Form.Item>
          {Object.values(Credential).map((credentialType) => (
            <Form.Item
              label={credentialType}
              name={credentialType}
              initialValue={user?.credentials?.find(
                (cred) => cred.type === credentialType
              )}
            >
              <CredentialInput
                autoSize={true}
                disabled={isSaving}
                type={credentialType}
                credential={user?.credentials?.find(
                  (cred) => cred.type === credentialType
                )}
                updateCredential={handleUpdateCredential}
              />
            </Form.Item>
          ))}
        </Col>
        <Row justify="end">
          <Button loading={isSaving} type="primary" htmlType="submit">
            Save changes
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
