import React, { useContext, useEffect, useState } from 'react';
import {
  TeamOutlined,
  CalendarOutlined,
  SettingOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { CgUserList } from 'react-icons/cg';
import { IoFlaskOutline } from 'react-icons/io5';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import './css/Sidebar.css';
import { UserPermission } from '@finni-health/atlas-shared';
import _ from 'lodash';

const { Sider } = Layout;

interface Props {
  onSidebarCollapse: () => void;
  isSidebarCollapsed: boolean;
}

export const Sidebar: React.FC<Props> = ({
  isSidebarCollapsed,
  onSidebarCollapse,
}) => {
  const user = useContext(AuthContext).user;
  const location = useLocation() as any;

  const [selectedTab, setSelectedTab] = useState<number>(1);

  useEffect(() => {
    handleSelectedTab();
  }, [location]);

  const handleSelectedTab = () => {
    const pathname = location.pathname;

    if (pathname.includes('/dev')) {
      setSelectedTab(8);
    } else if (pathname.includes('/settings')) {
      setSelectedTab(7);
    } else if (pathname.includes('/calendar')) {
      setSelectedTab(4);
    } else if (pathname.includes('/schedules')) {
      setSelectedTab(3);
    } else if (/^\/client-profile\/[a-zA-Z0-9]+$/.test(pathname)) {
      setSelectedTab(1);
    } else if (pathname.includes('/clients')) {
      setSelectedTab(1);
    } else if (pathname === '/') {
      setSelectedTab(1);
    } else {
      setSelectedTab(-1);
    }
  };

  return (
    <Sider
      theme="light"
      collapsible
      onCollapse={onSidebarCollapse}
      collapsed={isSidebarCollapsed}
      collapsedWidth={60}
      className="sidebar"
    >
      <Menu
        mode="inline"
        selectedKeys={[selectedTab.toString()]}
        style={{
          height: '100%',
          borderRight: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {user.permissions?.includes(UserPermission.BCBA) ||
        user.permissions?.includes(UserPermission.ADMIN) ||
        user.permissions?.includes(UserPermission.SUPERADMIN) ? (
          <>
            <Menu.Item key="1" icon={<TeamOutlined />}>
              <Link to="/clients">Clients</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<ScheduleOutlined />}>
              <Link to="/schedules">Schedules</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<CalendarOutlined />}>
              <Link to="/calendar">Calendar</Link>
            </Menu.Item>
            <Menu.Item
              key="6"
              style={{ marginTop: 'auto' }}
              icon={
                <CgUserList
                  style={{
                    fontSize: 16,
                    marginLeft: 2,
                  }}
                />
              }
            >
              <Link to="/staff">Staff</Link>
            </Menu.Item>
            <Menu.Item key="7" icon={<SettingOutlined />}>
              <Link to="/settings">Settings</Link>
            </Menu.Item>
            {/* {user.permissions?.includes(UserPermission.SUPERADMIN) && (
              <Menu.Item key="8" icon={<IoFlaskOutline />}>
                <Link to="/dev">Development</Link>
              </Menu.Item>
            )} */}
          </>
        ) : (
          <>
            <Menu.Item key="3" icon={<ScheduleOutlined />}>
              <Link to="/schedules">Schedule</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<CalendarOutlined />}>
              <Link to="/calendar">Calendar</Link>
            </Menu.Item>
            <Menu.Item
              key="7"
              style={{ marginTop: 'auto' }}
              icon={<SettingOutlined />}
            >
              <Link to="/profile">Profile</Link>
            </Menu.Item>
          </>
        )}
      </Menu>
    </Sider>
  );
};
