import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Statistic,
  Space,
  Button,
  Col,
  message,
  Divider,
  Form,
  Input,
  Spin,
  Select,
  Tag,
  Popconfirm,
} from 'antd';
import { Timestamp } from '@firebase/firestore-types';
import { AuthContext } from './AuthProvider';
import * as FirestoreService from '../services/firestore';
import _ from 'lodash';
import {
  CountryCode,
  IUpdateClinicEndpointRequest,
  USStateCode,
  UserPermission,
} from '@finni-health/atlas-shared';
import moment from 'moment';
import { COLORS, VALID_EMAIL_REGEX, VALID_ZIP_CODE_REGEX } from '../consts';
import { PhoneNumberInput } from './PhoneNumberInput';
import { ERROR_MESSAGE } from '../consts';
import firebase from '../services/firebase';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IEditClinicFormValues {
  name: string;
  displayName: string;
  phoneNumber: string;
  email: string;
  serviceAreas: string[];
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
  country: CountryCode;
}

export const EditClinicForm: React.FC = () => {
  const user = useContext(AuthContext).user;
  const clinic = useContext(AuthContext).clinic;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [form] = Form.useForm<IEditClinicFormValues>();
  const serviceAreas = Form.useWatch('serviceAreas', form);

  const handleUpdateClinic = async () => {
    setIsSaving(true);

    const values = form.getFieldsValue();

    const updateClinicRequest: IUpdateClinicEndpointRequest = {
      id: clinic.id,
      name: values.name,
      displayName: values.displayName,
      accessCode: '',
      phoneNumber: values.phoneNumber || '',
      email: values.email || '',
      serviceAreas: values.serviceAreas,
      address: {
        line1: values.line1 || '',
        line2: values.line2 || '',
        city: values.city || '',
        state: values.state || '',
        zipCode: values.zipCode || '',
        country: values.country,
      },
    };

    try {
      await FirestoreService.updateClinic(updateClinicRequest);
      message.success(`Changed saved`);
    } catch (err) {
      message.error(ERROR_MESSAGE);
      console.error(err);
    }

    setIsSaving(false);
  };

  const handleCalendarIntegration = async () => {
    const url = await FirestoreService.getOAuthConsentURL();
    window.location.replace(url);
  };

  const handleDeleteClinic = async () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        message.info('Successfully logged out');
        window.location.replace('/');
      })
      .catch((error) => {
        message.info('Failed to log out');
      });
  };

  useEffect(() => {
    if (!_.isEmpty(clinic)) {
      setIsLoading(false);
    }
  }, [clinic]);

  useEffect(() => {
    if (!_.isEmpty(serviceAreas)) {
      const newServiceAreas = serviceAreas.filter((zipCode) => {
        if (zipCode.length !== 5) {
          message.warn('Please enter a valid 5 digit Zip code');
          return false;
        } else {
          return true;
        }
      });
      form.setFieldValue('serviceAreas', newServiceAreas);
    }
  }, [serviceAreas]);

  return (
    <>
      {isLoading ? (
        <Row justify="center" align="middle" style={{ height: 484.84 }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            onFinish={handleUpdateClinic}
            form={form}
          >
            <Col style={{ width: '100%' }}>
              <Row justify="space-between" style={{ marginBottom: 20 }}>
                <Space size="large">
                  <Statistic
                    title="Active Since"
                    value={`${moment(
                      (clinic.createdAt as Timestamp).toDate()
                    ).format('MMMM DD, YYYY')}`}
                  />
                </Space>
                <Button loading={isSaving} type="primary" htmlType="submit">
                  Save Changes
                </Button>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Divider orientation="left">Practice Information</Divider>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Practice Name"
                    name="clinicName"
                    initialValue={clinic.name}
                    rules={[
                      {
                        required: true,
                        message: "Please input your clinic's Name",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Display Name"
                    name="displayName"
                    initialValue={clinic.displayName}
                    rules={[
                      {
                        required: true,
                        message: "Please input your clinic's Display Name",
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    initialValue={clinic.phoneNumber}
                  >
                    <PhoneNumberInput disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Practice Email"
                    name="email"
                    initialValue={clinic.email}
                    rules={[
                      {
                        //valid email regex
                        pattern: VALID_EMAIL_REGEX,
                        message:
                          'Please provide a valid Email, or leave this field blank',
                      },
                    ]}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                {!clinic.calendarId && (
                  <Form.Item
                    label="Google Calendar"
                    name="gcal"
                    style={{ marginLeft: 10 }}
                  >
                    <Button
                      disabled={isSaving}
                      onClick={handleCalendarIntegration}
                      type="primary"
                    >
                      Enable Calendar Integration
                    </Button>
                  </Form.Item>
                )}
              </Row>
              <Divider orientation="left">Address</Divider>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Address Line 1"
                    name="line1"
                    initialValue={clinic.address?.line1}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Address Line 2"
                    name="line2"
                    initialValue={clinic.address?.line2}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    label="City"
                    name="city"
                    initialValue={clinic.address?.city}
                  >
                    <Input disabled={isSaving} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="State"
                    name="state"
                    initialValue={clinic.address?.state}
                  >
                    <Select showSearch disabled={isSaving}>
                      {Object.values(USStateCode).map((state) => (
                        <Select.Option value={state} key={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Zip Code"
                    name="zipCode"
                    initialValue={clinic.address?.zipCode}
                    rules={[
                      {
                        pattern: VALID_ZIP_CODE_REGEX,
                        message: 'Please enter a 5 digit zip code',
                      },
                    ]}
                  >
                    <Input disabled={isSaving} maxLength={5} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Country"
                    name="country"
                    initialValue={CountryCode.US}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              {user.permissions?.includes(UserPermission.SUPERADMIN) && (
                <>
                  <Col span={24}>
                    <Divider orientation="left">Admin Panel</Divider>
                  </Col>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Popconfirm
                        title={`Are you sure you want to schedule permanent deletion of all data?`}
                        onConfirm={handleDeleteClinic}
                        okText="Delete all data"
                        cancelText="No"
                        placement="bottomLeft"
                        okType="danger"
                        icon={
                          <ExclamationCircleOutlined style={{ color: 'red' }} />
                        }
                      >
                        <Button
                          style={{
                            marginLeft: 5,
                            borderColor: 'red',
                            color: 'red',
                          }}
                          loading={isSaving}
                          type="dashed"
                        >
                          Delete Clinic and All Data
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Form>
        </>
      )}
    </>
  );
};
