import React, { useEffect, useState, useContext } from 'react';
import '../components/css/Appointments.css';
import { AuthContext } from '../components/AuthProvider';
import {
  Button,
  DatePicker,
  Row,
  Space,
  Table,
  Tag,
  Input,
  Col,
  Segmented,
  Typography,
  message,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import {
  ActiveStatus,
  capitalizeFirstLetter,
  IAppointment,
  IClient,
  ICompletedAppointment,
  IIndirect,
  INote,
  IUser,
  stringToColor,
  UserPermission,
  getInitials,
} from '@finni-health/atlas-shared';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import * as momentTz from 'moment-timezone';
import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as FirestoreService from '../services/firestore';
import { CreateAppointmentModal } from '../components/Calendar/CreateAppointmentModal';
import { AppointmentCard } from '../components/Calendar/AppointmentCard';
import { filterClients } from '../helpers/filterClients';
import { ClientSummary } from '../components/Calendar/ClientSummary';
import { MatchNotesDrawer } from '../components/Calendar/MatchNotesDrawer';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CalendarType, ICalendarEvent, ICalendarRow, WeekDays } from './types';
import { datePickerFormat, getCalendarDayHeader } from '../helpers/calendar';
import { IndirectCard } from '../components/Calendar/IndirectCard';
import { CompletedAppointmentCard } from '../components/Calendar/CompletedAppointmentCard';

const { Text } = Typography;
const { Search } = Input;

const DEBOUNCE_TIME = 500;

export const isMatchingContext = React.createContext({} as IAppointment);

export const Calendar: React.FC = () => {
  const user = useContext(AuthContext).user;
  const history = useHistory();

  // Params
  const { selectedWeek, eventId } = useParams<{
    selectedWeek?: string;
    eventId?: string;
  }>();
  const [selectedFilters, setSelectedFilters] = useState<CheckboxValueType[]>(
    //parse filters from URL
    document.URL.includes('?')
      ? document.URL.split('?')[1]
          .split('=')[1]
          .split(',')
          .map((filter) => {
            return filter;
          })
      : []
  );

  // General state
  const [tableColumnsObject, setTableColumnsObject] = useState<any>();
  const [eventCardOpenedOnce, setEventCardOpenedOnce] = useState(false);
  const [matchNotedDrawerOpenedOnce, setMatchNotedDrawerOpenedOnce] =
    useState(false);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isCalendarProcessing, setIsCalendarProcessing] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  // Calendar
  const [clinicUsers, setClinicUsers] = useState<IUser[]>([]);
  const [clinicClients, setClinicClients] = useState<IClient[]>([]);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [appointmentsByClient, setAppointmentsByClient] = useState<
    ICalendarRow[]
  >([]);
  const [appointmentsByTherapist, setAppointmentsByTherapist] = useState<
    ICalendarRow[]
  >([]);
  const [completedAppointments, setCompletedAppointments] = useState<
    ICompletedAppointment[]
  >([]);
  const [dataSource, setDataSource] = useState<ICalendarRow[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const [calWeek, setCalWeek] = useState<Moment>(
    selectedWeek
      ? moment()
          .year(parseInt(selectedWeek.split('-')[0]))
          .week(parseInt(selectedWeek.split('-')[1]))
      : moment()
  );
  const [selectedCalendarType, setSelectedCalendarType] =
    useState<CalendarType>(CalendarType.CLIENTS);

  // Create appointments modal
  const [isCreateAppointmentModalVisible, setIsCreateAppointmentModalVisible] =
    useState<boolean>(false);
  const hideCreateAppointmentModal = () => {
    setIsCreateAppointmentModalVisible(false);
  };

  // Event card
  const [selectedEvent, setSelectedEvent] = useState<ICalendarEvent>();
  const [selectedClient, setSelectedClient] = useState<IClient>();

  // Match notes drawer
  const [unmatchedNotes, setUnmatchedNotes] = useState<INote[]>([]);
  const [isMatchNotesDrawerOpen, setIsMatchNotesDrawerOpen] =
    useState<boolean>(false);
  const [matchNotesDrawerAppointment, setMatchNotesDrawerAppointment] =
    useState<IAppointment>({} as IAppointment);
  const [matchNotesDrawerClient, setMatchNotesDrawerClient] = useState<IClient>(
    {} as IClient
  );
  const [matchingAppointment, setMatchingAppointment] = useState<IAppointment>(
    {} as IAppointment
  );

  // Debounce timeouts
  const [searchTimeout, setSearchTimeout] = useState<any>(0);
  const [fetchAppointmentsTimeout, setFetchAppointmentsTimeout] =
    useState<any>(0);

  // First load
  useEffect(() => {
    fetchData();
  }, []);

  // On navigate
  useEffect(() => {
    if (!isLoading) {
      setIsNavigating(true);
      throttledFetchAppointments();
    }
  }, [user, calWeek]);

  // Calendar type selector
  useEffect(() => {
    if (!_.isEmpty(searchString)) {
      // performSearch sets the correct dataSource as a side effect
      performSearch(
        searchString,
        appointmentsByClient,
        appointmentsByTherapist
      );
    } else {
      switch (selectedCalendarType) {
        case CalendarType.CLIENTS:
          setDataSource(appointmentsByClient);
          break;
        case CalendarType.THERAPISTS:
          setDataSource(appointmentsByTherapist);
          break;
      }
    }
  }, [selectedCalendarType]);

  //open match drawer from URL
  useEffect(() => {
    if (
      eventCardOpenedOnce &&
      !matchNotedDrawerOpenedOnce &&
      !_.isEmpty(selectedFilters) &&
      !_.isEmpty(selectedEvent) &&
      !_.isEmpty(selectedClient)
    ) {
      setIsMatchNotesDrawerOpen(true);
    }
  }, [selectedClient]); //only depends on selectedClient to ensure that EventCard has loaded

  //URL manager
  useEffect(() => {
    let url = '/calendar';

    //Add week number to URL
    url += `/${calWeek.year()}-${calWeek.week()}`;

    //Add appointmentId to URL
    if (!_.isEmpty(selectedEvent)) {
      url += '/' + selectedEvent.id;

      //Add filters to URL
      if (!_.isEmpty(selectedFilters) && isMatchNotesDrawerOpen) {
        url += '?filters=' + selectedFilters.join(',');
      }
    }

    //apply URL to browser
    window.history.replaceState(null, 'Atlas: ABA Scheduling Software', url);
  }, [calWeek, selectedEvent, selectedFilters, isMatchNotesDrawerOpen]);

  useEffect(() => {
    if (
      isMatchNotesDrawerOpen &&
      !_.isEmpty(selectedEvent) &&
      !_.isEmpty(selectedClient)
    ) {
      if (!matchNotedDrawerOpenedOnce) {
        setMatchNotedDrawerOpenedOnce(true);
      }
      setMatchNotesDrawerAppointment(selectedEvent! as IAppointment);
      setMatchNotesDrawerClient(selectedClient!);
    }
    setTableColumnsObject(getTableColumns());
  }, [isMatchNotesDrawerOpen]);

  //reducing re-renders
  useEffect(() => {
    setTableColumnsObject(getTableColumns());
  }, [
    isLoading,
    isNavigating,
    isCalendarProcessing,
    dataSource,
    fetchAppointmentsTimeout,
  ]);

  const fetchData = async () => {
    if (!_.isEmpty(user)) {
      // eslint-disable-next-line prefer-const
      let [clinicUsers, clinicClients, unmatchedNotes] = await Promise.all([
        FirestoreService.getAllUsersForClinic(user.clinicId),
        FirestoreService.getAllClientsForClinic(user.clinicId),
        FirestoreService.getAllUnmatchedNotesByClinicId(user.clinicId),
      ]);

      clinicUsers = clinicUsers.filter((user) =>
        [UserPermission.BCBA, UserPermission.RBT].some((permission) =>
          user.permissions.includes(permission)
        )
      );

      if (!user.permissions.includes(UserPermission.ADMIN)) {
        unmatchedNotes = unmatchedNotes.filter(
          (note) => note.userId === user.id
        );
      }

      await fetchAppointments(clinicUsers, clinicClients);

      setClinicUsers(clinicUsers);
      setClinicClients(clinicClients);
      setUnmatchedNotes(unmatchedNotes);

      setIsLoading(false);
      setHasLoadedOnce(true);
    }
  };

  const throttledFetchAppointments = async (...options: any) => {
    if (fetchAppointmentsTimeout) {
      clearTimeout(fetchAppointmentsTimeout);
    }
    setFetchAppointmentsTimeout(
      setTimeout(async () => {
        if (!_.isEmpty(options)) {
          fetchAppointments(...options);
        } else {
          fetchAppointments();
        }
      }, DEBOUNCE_TIME)
    );
  };

  const popUpCalendarIntegrationMessage = () => {
    message.open({
      content: (
        <>
          <Col>
            <Row>
              <ExclamationCircleFilled
                style={{ color: '#faad14', marginTop: 3 }}
              />
              <Text>{`Calendar integration is required to enable this functionality`}</Text>
            </Row>
            <Row style={{ width: '100%', marginTop: 5 }}>
              <Button
                type="primary"
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                onClick={() => {
                  history.push('/settings');
                  message.destroy('calendar-integration');
                }}
              >
                Settings
              </Button>
            </Row>
          </Col>
        </>
      ),
      duration: 30,
      key: 'calendar-integration',
      onClick: () => message.destroy('calendar-integration'),
    });
    throw new Error(
      'Calendar integration is required to enable this functionality'
    );
  };

  const fetchAppointments = async (users?: IUser[], clients?: IClient[]) => {
    if (!user.clinicId) return;

    setIsCalendarProcessing(true);
    let appointments: IAppointment[],
      indirects: IIndirect[],
      completedAppointments: ICompletedAppointment[];
    try {
      [appointments, indirects, completedAppointments] = await Promise.all([
        FirestoreService.getAppointmentsForClinicIdAndWeek({
          clinicId: user.clinicId,
          week: calWeek.week(),
          year: calWeek.year(),
          timeZone: momentTz.tz.guess(),
        }),
        FirestoreService.getIndirectsForWeekAndClinicId({
          week: calWeek.week(),
          year: calWeek.year(),
          clinicId: user.clinicId,
          timeZone: momentTz.tz.guess(),
        }),
        FirestoreService.getCompletedAppointmentByClinicAndRange({
          clinicId: user.clinicId,
          startMs: calWeek.startOf('week').valueOf(),
          endMs: calWeek.endOf('week').valueOf(),
        }),
      ]);
    } catch (err) {
      popUpCalendarIntegrationMessage();
      return;
    }

    // Only include active clients, or inactive clients who have appointments booked
    const filteredClients = await filterClients(
      clients || clinicClients,
      async (client: IClient) => {
        const clientFile = await FirestoreService.getClientFileByClientId(
          client.id,
          client.clinicId
        );
        const shouldKeep =
          appointments.find((appt) => appt.clientId === client.id) ||
          (clientFile?.intakeStatus as any) === ActiveStatus.ACTIVE;
        return shouldKeep;
      }
    );

    const clientEvents: Array<IAppointment | ICompletedAppointment> = [
      ...appointments,
      ...completedAppointments,
    ];

    const events: Array<ICalendarEvent> = [...indirects, ...clientEvents];

    const clientAppointments = await groupAppointmentsByClient(
      filteredClients,
      clientEvents
    );

    const therapistAppointments = await groupAppointmentsByTherapist(
      users || clinicUsers,
      events
    );

    //Open event card if eventId is in URL
    if (!eventCardOpenedOnce) {
      setSelectedEvent(events.find((appt) => appt.id === eventId));
      setEventCardOpenedOnce(true);
    } else {
      setSelectedEvent(undefined);
      setSelectedClient(undefined);
    }

    setAppointments(appointments);
    setCompletedAppointments(completedAppointments);
    setAppointmentsByClient(clientAppointments);
    setAppointmentsByTherapist(therapistAppointments);

    switch (selectedCalendarType) {
      case CalendarType.THERAPISTS:
        setDataSource(therapistAppointments);
        break;
      default:
        setDataSource(clientAppointments);
        break;
    }

    // If searchString is present, apply it to the fetch results
    if (!_.isEmpty(searchString)) {
      performSearch(searchString, clientAppointments, therapistAppointments);
    }

    setIsCalendarProcessing(false);
    setIsNavigating(false);
  };

  const groupAppointmentsByClient = async (
    clinicClients: IClient[],
    appointments: Array<IAppointment | ICompletedAppointment>
  ) => {
    const groupedByPerson = _(appointments)
      .groupBy((appointment) => appointment.clientId)
      .value();

    const result: ICalendarRow[] = [];
    for (const client of clinicClients) {
      const apptsForClient = groupedByPerson[client.id] || [];
      result.push({
        person: client,
        sunday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 0
        ),
        monday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 1
        ),
        tuesday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 2
        ),
        wednesday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 3
        ),
        thursday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 4
        ),
        friday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 5
        ),
        saturday: apptsForClient.filter(
          (appt) => moment(appt.startMs).day() === 6
        ),
      });
    }

    result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));
    return result;
  };

  const groupAppointmentsByTherapist = async (
    clinicUsers: IUser[],
    events: ICalendarEvent[]
  ) => {
    let appointmentsPerPerson: ICalendarEvent[] = [];

    events.forEach(async (event) => {
      const apptSpread: ICalendarEvent[] = [];

      if (Array.isArray((event as any).attendees)) {
        //if IAppointment or IIndirect
        event = event as IAppointment | IIndirect;
        event.attendees.forEach(async (attendee) => {
          if (clinicUsers.some((user) => user.email === attendee.email)) {
            apptSpread.push({
              ...event,
              attendee: attendee.email,
            } as any);
          }
        });
      } else {
        //if ICompletedAppointment
        event = event as ICompletedAppointment;
        event?.userIds.forEach(async (userId) => {
          const foundUser = clinicUsers.find((user) => user.id === userId);
          if (!_.isEmpty(foundUser)) {
            apptSpread.push({
              ...event,
              attendee: foundUser.email,
            } as any);
          }
        });
      }

      appointmentsPerPerson = appointmentsPerPerson.concat(apptSpread);
    });

    const groupedByPerson = _(appointmentsPerPerson)
      .groupBy((appointment: any) => appointment.attendee)
      .value();

    const result: ICalendarRow[] = [];
    for (const user of clinicUsers) {
      const apptsForEmail = groupedByPerson[user.email] || [];
      result.push({
        person: user,
        sunday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 0
        ),
        monday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 1
        ),
        tuesday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 2
        ),
        wednesday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 3
        ),
        thursday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 4
        ),
        friday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 5
        ),
        saturday: apptsForEmail.filter(
          (appt) => moment(appt.startMs).day() === 6
        ),
      });
    }

    // Sort calendars by firstName, and then put yourself at the top
    result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));
    const userIndex = result.findIndex((appt) => appt.person.id === user.id);
    userIndex !== -1 && result.splice(0, 0, result.splice(userIndex, 1)[0]);

    return result;
  };

  const hideMatchNotesDrawer = () => {
    setIsMatchNotesDrawerOpen(false);
  };

  const appointmentsTableRender = (events: ICalendarEvent[]) => {
    return events
      .sort((a, b) => a.startMs - b.startMs)
      .map((event) => {
        const forceOpen = event.id === selectedEvent?.id ? true : false;
        const client = clinicClients.find(
          (client) => client.id === (event as any).clientId
        );
        if ((event as any).isBilled !== undefined) {
          //Completed Appointment
          event = event as ICompletedAppointment;
          return (
            <CompletedAppointmentCard
              users={clinicUsers}
              client={client!}
              completedAppointment={event}
              forceOpen={forceOpen}
              setSelectedEvent={setSelectedEvent}
              refreshAppointments={throttledFetchAppointments}
              appointment={
                appointments.find((appointment) => appointment.id === event.id)!
              }
              isCalendarProcessing={isCalendarProcessing}
            />
          );
        } else if (!_.isEmpty((event as any).clientId)) {
          //GCAL Appointment
          event = event as IAppointment;
          return (
            !completedAppointments.find(
              (appt) => appt.id === (event as IAppointment).id
            ) && (
              <AppointmentCard
                client={client!}
                appointment={event}
                calendarType={selectedCalendarType}
                isMatchNotesDrawerOpen={isMatchNotesDrawerOpen}
                setIsMatchNotesDrawerOpen={setIsMatchNotesDrawerOpen}
                refreshAppointments={throttledFetchAppointments}
                refreshAll={fetchData}
                forceOpen={forceOpen}
                setSelectedAppointment={setSelectedEvent}
                setSelectedClient={setSelectedClient}
                isCalendarProcessing={isCalendarProcessing}
              />
            )
          );
        } else {
          //Indirect Appointment
          event = event as IIndirect;
          return (
            <IndirectCard
              indirect={event}
              forceOpen={forceOpen}
              setSelectedEvent={setSelectedEvent}
              refreshCallback={throttledFetchAppointments}
              isCalendarProcessing={isCalendarProcessing}
            />
          );
        }
      });
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        title: (
          <Row
            style={{ height: 54, width: '100%', overflow: 'visible' }}
            align="middle"
            justify="center"
          >
            Calendars
          </Row>
        ),
        dataIndex: 'person',
        sorter: (a: any, b: any) =>
          a.person.firstName.localeCompare(b.person.firstName),

        render: (person: IClient | IUser, row: ICalendarRow) => {
          switch (selectedCalendarType) {
            case CalendarType.CLIENTS:
              return <ClientSummary data={row} />;
            case CalendarType.THERAPISTS:
              return (
                <Tag color={stringToColor(person.id)}>
                  {`${person.firstName} ${person.lastName}`}
                </Tag>
              );
          }
        },
        width: 145,
      },
      ...Object.values(WeekDays).map((day: WeekDays) => {
        return {
          title: getCalendarDayHeader(day, calWeek),
          dataIndex: day,
          render: appointmentsTableRender,
          width: 167,
        };
      }),
    ];
    return tableColumns;
  };

  const onCalendarChange = (date: Moment | null) => {
    if (date) {
      setCalWeek(date);
    }
  };

  const onPanelChange = (value: Moment) => {
    setCalWeek(value);
  };

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    throttledSearch(searchString);
  };

  const throttledSearch = async (searchString: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        performSearch(
          searchString,
          appointmentsByClient,
          appointmentsByTherapist
        );
      }, DEBOUNCE_TIME)
    );
  };

  const performSearch = (
    searchStringOverride: string,
    appointmentsByClient: ICalendarRow[],
    appointmentsByTherapist: ICalendarRow[]
  ) => {
    // Split the search string by commas, remove empty results, and trim them
    const searchStrings = searchStringOverride
      .toLowerCase()
      .split(',')
      .map((s) => s.trim())
      .filter((s) => s);

    let unfilteredData: ICalendarRow[];
    switch (selectedCalendarType) {
      case CalendarType.CLIENTS:
        unfilteredData = appointmentsByClient;
        break;
      case CalendarType.THERAPISTS:
        unfilteredData = appointmentsByTherapist;
        break;
    }

    if (!_.isEmpty(searchStrings)) {
      const filteredData = unfilteredData.filter((appt) =>
        searchStrings.some(
          (searchFor) =>
            appt.person.firstName.toLowerCase().includes(searchFor) ||
            appt.person.lastName.toLowerCase().includes(searchFor) ||
            getInitials(appt.person.firstName, appt.person.lastName)
              .toLowerCase()
              .includes(searchFor)
        )
      );

      setDataSource(filteredData);
    } else {
      setDataSource(unfilteredData);
    }

    setTableColumnsObject(getTableColumns());
  };

  return (
    <isMatchingContext.Provider value={matchingAppointment}>
      <div style={{ minWidth: 500 }}>
        <div>
          <Row align="middle" justify="space-between">
            <Space size={'large'}>
              <Button
                onClick={() => {
                  if (calWeek.week() !== moment().week()) {
                    setCalWeek(moment());
                  }
                }}
              >
                Today
              </Button>
              <div>
                <Button
                  size="small"
                  type="text"
                  onClick={() => {
                    const newCalWeek = calWeek.clone();
                    newCalWeek.subtract(1, 'week');
                    setCalWeek(newCalWeek);
                  }}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  size="small"
                  type="text"
                  onClick={() => {
                    const newCalWeek = calWeek.clone();
                    newCalWeek.add(1, 'week');
                    setCalWeek(newCalWeek);
                  }}
                >
                  <RightOutlined />
                </Button>
              </div>
              <DatePicker
                size="large"
                allowClear={false}
                style={{ cursor: 'text' }}
                format={() => datePickerFormat(calWeek)}
                bordered={false}
                value={calWeek}
                onPanelChange={onPanelChange}
                onChange={onCalendarChange}
                picker="week"
              />
              {unmatchedNotes.length > 0 && (
                <>
                  <Text>
                    <ExclamationCircleOutlined
                      style={{
                        marginLeft: 12,
                        marginRight: 6,
                        fontSize: 12.5,
                      }}
                    />
                    {`${unmatchedNotes.length} Unmatched Note${
                      unmatchedNotes.length > 1 ? 's' : ''
                    }`}
                  </Text>
                </>
              )}
            </Space>
            <Button
              shape="round"
              size="large"
              type="primary"
              onClick={() => {
                setIsCreateAppointmentModalVisible(true);
              }}
            >
              <PlusOutlined style={{ marginLeft: -3 }} />
              Create
            </Button>
          </Row>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Col span={3} style={{ marginRight: -10 }}>
              <Segmented
                options={Object.values(CalendarType).map((val) => ({
                  value: val,
                  label: capitalizeFirstLetter(val),
                }))}
                value={selectedCalendarType}
                onChange={(value) => {
                  setSelectedCalendarType(value as CalendarType);
                }}
              />
            </Col>
            <Col span={21}>
              <Search
                placeholder={`Search calendars by First ${
                  selectedCalendarType === CalendarType.THERAPISTS
                    ? 'or'
                    : 'Name,'
                } Last Name${
                  selectedCalendarType === CalendarType.THERAPISTS
                    ? ''
                    : ', or Client Alias'
                }. Separate multiple with commas`}
                allowClear
                value={searchString}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ width: 'calc(100% + 20px)' }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Table
            // scroll={{ y: 'calc(100vh - 265px)' }}
            style={{
              width: '100%',
              verticalAlign: 'top',
              height: '100vh',
            }}
            columns={tableColumnsObject}
            dataSource={dataSource}
            loading={isNavigating || (isLoading && !hasLoadedOnce)}
            pagination={false}
            showSorterTooltip={false}
          />
        </Row>
        <CreateAppointmentModal
          isVisible={isCreateAppointmentModalVisible}
          hideModal={hideCreateAppointmentModal}
          refreshCallback={throttledFetchAppointments}
          users={clinicUsers}
        />
        <MatchNotesDrawer
          appointment={matchNotesDrawerAppointment}
          client={matchNotesDrawerClient}
          unmatchedNotes={unmatchedNotes}
          isOpen={isMatchNotesDrawerOpen}
          hideDrawer={hideMatchNotesDrawer}
          refreshCallback={fetchData}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setMatchingAppointment={setMatchingAppointment}
        />
      </div>
    </isMatchingContext.Provider>
  );
};
