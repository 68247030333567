import { Row, Col, Typography, Form, Select, Switch, Input } from 'antd';
import _ from 'lodash';
import {
  BehaviorFrequency,
  BehaviorSeverity,
  IGoals,
  IClientFile,
} from '@finni-health/atlas-shared';

const { Title } = Typography;
const { TextArea } = Input;

interface Props {
  isEditingDisabled: boolean;
  tempClientFile: IClientFile;
  updateTempClientFile: (source: Partial<IClientFile>) => void;
  setTempClientFile: (source: IClientFile) => void;
}

export const ClientBehaviorsSection = ({
  isEditingDisabled,
  tempClientFile,
  updateTempClientFile,
  setTempClientFile,
}: Props) => {
  const updateSelectedGoals = (goals: IGoals[]) => {
    const behaviors = tempClientFile?.behaviors || {};
    if (goals.length === 0) {
      delete behaviors.goals;
    } else {
      behaviors.goals = goals;
    }
    setTempClientFile({
      ...tempClientFile,
      behaviors: behaviors,
    });
  };

  return (
    <>
      <Title id={'behaviors'} level={2} style={{ marginTop: 20 }}>
        Behaviors
      </Title>
      <Title id={'selfHarm'} level={3}>
        Self Harm
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Frequency">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.selfHarm;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.selfHarm?.frequency}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(frequency: BehaviorFrequency) => {
                frequency !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      selfHarm: {
                        severity:
                          tempClientFile?.behaviors?.selfHarm?.severity ||
                          BehaviorSeverity.LOW,
                        frequency: frequency,
                        requireMedicalAttn:
                          tempClientFile?.behaviors?.selfHarm
                            ?.requireMedicalAttn || false,
                        headDirected:
                          tempClientFile?.behaviors?.selfHarm?.headDirected ||
                          false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorFrequency).map((frequency) => (
                <Select.Option key={frequency}>{frequency}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Severity">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.selfHarm;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.selfHarm?.severity}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(severity: BehaviorSeverity) => {
                severity !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      selfHarm: {
                        severity: severity,
                        frequency:
                          tempClientFile?.behaviors?.selfHarm?.frequency ||
                          BehaviorFrequency.MONTHLY,
                        requireMedicalAttn:
                          tempClientFile?.behaviors?.selfHarm
                            ?.requireMedicalAttn || false,
                        headDirected:
                          tempClientFile?.behaviors?.selfHarm?.headDirected ||
                          false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorSeverity).map((severity) => (
                <Select.Option key={severity}>{severity}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Head Directed Self Harm"
            style={{ alignItems: 'center' }}
          >
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.selfHarm?.headDirected}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    selfHarm: {
                      severity:
                        tempClientFile?.behaviors?.selfHarm?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.selfHarm?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      requireMedicalAttn:
                        tempClientFile?.behaviors?.selfHarm
                          ?.requireMedicalAttn || false,
                      headDirected: e,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="May Require Medical Attention"
            style={{ alignItems: 'center' }}
          >
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.selfHarm?.requireMedicalAttn}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    selfHarm: {
                      severity:
                        tempClientFile?.behaviors?.selfHarm?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.selfHarm?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      requireMedicalAttn: e,
                      headDirected:
                        tempClientFile?.behaviors?.selfHarm?.headDirected ||
                        false,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'aggression'} level={3}>
        Aggression
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Frequency">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.aggression;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.aggression?.frequency}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(frequency: BehaviorFrequency) => {
                frequency !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      aggression: {
                        severity:
                          tempClientFile?.behaviors?.aggression?.severity ||
                          BehaviorSeverity.LOW,
                        frequency: frequency,
                        requireMedicalAttn:
                          tempClientFile?.behaviors?.aggression
                            ?.requireMedicalAttn || false,
                        bite:
                          tempClientFile?.behaviors?.aggression?.bite || false,
                        weapons:
                          tempClientFile?.behaviors?.aggression?.weapons ||
                          false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorFrequency).map((frequency) => (
                <Select.Option key={frequency}>{frequency}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Severity">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.aggression;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.aggression?.severity}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(severity: BehaviorSeverity) => {
                severity !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      aggression: {
                        severity: severity,
                        frequency:
                          tempClientFile?.behaviors?.aggression?.frequency ||
                          BehaviorFrequency.MONTHLY,
                        requireMedicalAttn:
                          tempClientFile?.behaviors?.aggression
                            ?.requireMedicalAttn || false,
                        bite:
                          tempClientFile?.behaviors?.aggression?.bite || false,
                        weapons:
                          tempClientFile?.behaviors?.aggression?.weapons ||
                          false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorSeverity).map((severity) => (
                <Select.Option key={severity}>{severity}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Biting" style={{ alignItems: 'center' }}>
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.aggression?.bite}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    aggression: {
                      severity:
                        tempClientFile?.behaviors?.aggression?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.aggression?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      requireMedicalAttn:
                        tempClientFile?.behaviors?.aggression
                          ?.requireMedicalAttn || false,
                      bite: e,
                      weapons:
                        tempClientFile?.behaviors?.aggression?.weapons || false,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Uses Objects As Weapons"
            style={{ alignItems: 'center' }}
          >
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.aggression?.weapons}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    aggression: {
                      severity:
                        tempClientFile?.behaviors?.aggression?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.aggression?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      requireMedicalAttn:
                        tempClientFile?.behaviors?.aggression
                          ?.requireMedicalAttn || false,
                      bite:
                        tempClientFile?.behaviors?.aggression?.bite || false,
                      weapons: e,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="May Require Medical Attention"
            style={{ alignItems: 'center' }}
          >
            <Switch
              disabled={isEditingDisabled}
              checked={
                tempClientFile?.behaviors?.aggression?.requireMedicalAttn
              }
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    aggression: {
                      severity:
                        tempClientFile?.behaviors?.aggression?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.aggression?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      requireMedicalAttn: e,
                      bite:
                        tempClientFile?.behaviors?.aggression?.bite || false,
                      weapons:
                        tempClientFile?.behaviors?.aggression?.weapons || false,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'runAway'} level={3}>
        Run Away
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Frequency">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.runAway;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.runAway?.frequency}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(frequency: BehaviorFrequency) => {
                frequency !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      runAway: {
                        severity:
                          tempClientFile?.behaviors?.runAway?.severity ||
                          BehaviorSeverity.LOW,
                        frequency: frequency,
                        leaveHome:
                          tempClientFile?.behaviors?.runAway?.leaveHome ||
                          false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorFrequency).map((frequency) => (
                <Select.Option key={frequency}>{frequency}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Severity">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.runAway;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.runAway?.severity}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(severity: BehaviorSeverity) => {
                severity !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      runAway: {
                        severity: severity,
                        frequency:
                          tempClientFile?.behaviors?.runAway?.frequency ||
                          BehaviorFrequency.MONTHLY,
                        leaveHome:
                          tempClientFile?.behaviors?.runAway?.leaveHome ||
                          false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorSeverity).map((severity) => (
                <Select.Option key={severity}>{severity}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Leaves Home" style={{ alignItems: 'center' }}>
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.runAway?.leaveHome}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    runAway: {
                      severity:
                        tempClientFile?.behaviors?.runAway?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.runAway?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      leaveHome: e,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'destroyProperty'} level={3}>
        Destroy Property
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Frequency">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.destroyProperty;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.destroyProperty?.frequency}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(frequency: BehaviorFrequency) => {
                frequency !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      destroyProperty: {
                        severity:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.severity || BehaviorSeverity.LOW,
                        frequency: frequency,
                        targetHighValue:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.targetHighValue || false,
                        targetGlass:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.targetGlass || false,
                        structuralDamage:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.structuralDamage || false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorFrequency).map((frequency) => (
                <Select.Option key={frequency}>{frequency}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Severity">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.destroyProperty;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.destroyProperty?.severity}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(severity: BehaviorSeverity) => {
                severity !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      destroyProperty: {
                        severity: severity,
                        frequency:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.frequency || BehaviorFrequency.MONTHLY,
                        targetHighValue:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.targetHighValue || false,
                        targetGlass:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.targetGlass || false,
                        structuralDamage:
                          tempClientFile?.behaviors?.destroyProperty
                            ?.structuralDamage || false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorSeverity).map((severity) => (
                <Select.Option key={severity}>{severity}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Targets Valuables" style={{ alignItems: 'center' }}>
            <Switch
              disabled={isEditingDisabled}
              checked={
                tempClientFile?.behaviors?.destroyProperty?.targetHighValue
              }
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    destroyProperty: {
                      severity:
                        tempClientFile?.behaviors?.destroyProperty?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.destroyProperty?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      targetHighValue: e,
                      targetGlass:
                        tempClientFile?.behaviors?.destroyProperty
                          ?.targetGlass || false,
                      structuralDamage:
                        tempClientFile?.behaviors?.destroyProperty
                          ?.structuralDamage || false,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Targets Glass" style={{ alignItems: 'center' }}>
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.destroyProperty?.targetGlass}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    destroyProperty: {
                      severity:
                        tempClientFile?.behaviors?.destroyProperty?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.destroyProperty?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      targetHighValue:
                        tempClientFile?.behaviors?.destroyProperty
                          ?.targetHighValue || false,
                      targetGlass: e,
                      structuralDamage:
                        tempClientFile?.behaviors?.destroyProperty
                          ?.structuralDamage || false,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Causes Structural Damage"
            style={{ alignItems: 'center' }}
          >
            <Switch
              disabled={isEditingDisabled}
              checked={
                tempClientFile?.behaviors?.destroyProperty?.structuralDamage
              }
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    destroyProperty: {
                      severity:
                        tempClientFile?.behaviors?.destroyProperty?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.destroyProperty?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      targetHighValue:
                        tempClientFile?.behaviors?.destroyProperty
                          ?.targetHighValue || false,
                      targetGlass:
                        tempClientFile?.behaviors?.destroyProperty
                          ?.targetGlass || false,
                      structuralDamage: e,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'flopOnGround'} level={3}>
        Flop On Ground
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Frequency">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.flopOnGround;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.flopOnGround?.frequency}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(frequency: BehaviorFrequency) => {
                frequency !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      flopOnGround: {
                        severity:
                          tempClientFile?.behaviors?.flopOnGround?.severity ||
                          BehaviorSeverity.LOW,
                        frequency: frequency,
                        inHome:
                          tempClientFile?.behaviors?.flopOnGround?.inHome ||
                          false,
                        outsideHome:
                          tempClientFile?.behaviors?.flopOnGround
                            ?.outsideHome || false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorFrequency).map((frequency) => (
                <Select.Option key={frequency}>{frequency}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Severity">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.flopOnGround;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.flopOnGround?.severity}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(severity: BehaviorSeverity) => {
                severity !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      flopOnGround: {
                        severity: severity,
                        frequency:
                          tempClientFile?.behaviors?.flopOnGround?.frequency ||
                          BehaviorFrequency.MONTHLY,
                        inHome:
                          tempClientFile?.behaviors?.flopOnGround?.inHome ||
                          false,
                        outsideHome:
                          tempClientFile?.behaviors?.flopOnGround
                            ?.outsideHome || false,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorSeverity).map((severity) => (
                <Select.Option key={severity}>{severity}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Occurs at Home" style={{ alignItems: 'center' }}>
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.flopOnGround?.inHome}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    flopOnGround: {
                      severity:
                        tempClientFile?.behaviors?.flopOnGround?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.flopOnGround?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      inHome: e,
                      outsideHome:
                        tempClientFile?.behaviors?.flopOnGround?.outsideHome ||
                        false,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Occurs Outside Home"
            style={{ alignItems: 'center' }}
          >
            <Switch
              disabled={isEditingDisabled}
              checked={tempClientFile?.behaviors?.flopOnGround?.outsideHome}
              onChange={(e: boolean) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    flopOnGround: {
                      severity:
                        tempClientFile?.behaviors?.flopOnGround?.severity ||
                        BehaviorSeverity.LOW,
                      frequency:
                        tempClientFile?.behaviors?.flopOnGround?.frequency ||
                        BehaviorFrequency.MONTHLY,
                      inHome:
                        tempClientFile?.behaviors?.flopOnGround?.inHome ||
                        false,
                      outsideHome: e,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'takeOffClothes'} level={3}>
        Take Off Clothes
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Frequency">
            <Select
              allowClear
              onClear={() => {
                const tempBehaviors = tempClientFile.behaviors;
                delete tempBehaviors.takeOffClothes;
                updateTempClientFile({ behaviors: tempBehaviors });
              }}
              showSearch
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.takeOffClothes?.frequency}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(frequency: BehaviorFrequency) => {
                frequency !== undefined &&
                  updateTempClientFile({
                    behaviors: {
                      ...tempClientFile?.behaviors,
                      takeOffClothes: {
                        frequency: frequency,
                      },
                    },
                  });
              }}
            >
              {Object.keys(BehaviorFrequency).map((frequency) => (
                <Select.Option key={frequency}>{frequency}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Title id={'goals'} level={3}>
        Goals
      </Title>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Selected Goals">
            <Select
              showSearch
              disabled={isEditingDisabled}
              mode="multiple"
              value={tempClientFile?.behaviors?.goals}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(e: IGoals[]) => {
                updateSelectedGoals(e);
              }}
            >
              {Object.keys(IGoals).map((goal) => (
                <Select.Option key={goal}>{goal}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Other Goals">
            <TextArea
              autoSize={true}
              disabled={isEditingDisabled}
              value={tempClientFile?.behaviors?.otherGoals}
              onChange={(e) => {
                updateTempClientFile({
                  behaviors: {
                    ...tempClientFile?.behaviors,
                    otherGoals: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
