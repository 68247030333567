import React from 'react';
import { Row, Spin } from 'antd';

export const Loading: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Spin size="large" />
    </Row>
  );
};
