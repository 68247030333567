import React, { useContext, useEffect, useState } from 'react';
import * as FirestoreService from '../../services/firestore';
import {
  Badge,
  Button,
  Card,
  Popconfirm,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { AttendeeStatus, IIndirect } from '@finni-health/atlas-shared';
import { DISPLAY_TIME_FORMAT } from '../../consts';
import moment from 'moment';
import _ from 'lodash';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { EditIndirectModal } from './EditIndirectModal';

const { Text, Title } = Typography;

interface IProps {
  indirect: IIndirect;
  forceOpen: boolean;
  setSelectedEvent: (event: IIndirect) => void;
  refreshCallback: () => Promise<void>;
  isCalendarProcessing: boolean;
}

export const IndirectCard: React.FC<IProps> = ({
  indirect,
  forceOpen,
  setSelectedEvent,
  refreshCallback,
  isCalendarProcessing,
}: IProps) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<any>();

  // edit indirect modal
  const [isEditIndirectModalOpen, setIsEditIndirectModalOpen] =
    useState<boolean>(false);
  const hideEditIndirectModal = () => {
    setIsEditIndirectModalOpen(false);
  };

  const hidePopover = () => {
    setSelectedEvent({} as IIndirect);
    setOpen(false);
  };

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setSelectedEvent(indirect);
      setOpen(true);
    } else {
      setSelectedEvent({} as IIndirect);
      setOpen(false);
    }
  };

  const handleDeleteindirect = async () => {
    setSentRefreshCallback(true);
    await FirestoreService.deleteIndirect({ id: indirect.id });
    await refreshCallback();
  };

  useEffect(() => {
    setRef(React.createRef());
  }, []);

  useEffect(() => {
    if (forceOpen && ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [forceOpen, ref]);

  useEffect(() => {
    if (forceOpen) {
      handlePopoverOpenChange(true);
    }
  }, [forceOpen]);

  //Loading on cards
  const [sentRefreshCallback, setSentRefreshCallback] =
    useState<boolean>(false);
  useEffect(() => {
    if (sentRefreshCallback && !isCalendarProcessing) {
      setSentRefreshCallback(false);
    }
  }, [isCalendarProcessing]);
  const handleSubRefresh = () => {
    setSentRefreshCallback(true);
    refreshCallback();
  };

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <>
            <Tooltip placement="bottom" title="Edit indirect">
              <Button
                type="text"
                size="small"
                onClick={() => {
                  hidePopover();
                  setIsEditIndirectModalOpen(true);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Delete indirect">
              <Popconfirm
                onConfirm={handleDeleteindirect}
                title={
                  'Are you sure you want to permanently delete this indirect?'
                }
                okText="Delete"
              >
                <Button type="text" size="small" style={{ marginLeft: 5 }}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Tooltip>
            <Tooltip placement="bottom" title="Close">
              <Button
                type="text"
                size="small"
                style={{ marginLeft: 12 }}
                onClick={hidePopover}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          </>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {indirect.summary}
          </Title>
        </Row>
        <Row>
          <Text>{`${moment(indirect.startMs).format(
            DISPLAY_TIME_FORMAT
          )} — ${moment(indirect.endMs).format(DISPLAY_TIME_FORMAT)}`}</Text>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {indirect.attendees.map((attendee) => (
          <Tag style={{ marginBottom: 2, fontSize: 12 }}>
            <Badge
              status={
                attendee.status === AttendeeStatus.ACCEPTED
                  ? 'success'
                  : attendee.status === AttendeeStatus.DECLINED
                  ? 'error'
                  : attendee.status === AttendeeStatus.TENTATIVE
                  ? 'warning'
                  : 'default'
              }
              style={{ marginRight: 3 }}
            />
            {attendee.email}
          </Tag>
        ))}
        {indirect.description && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Description</Text>
            </Row>
            <Row>
              <Text>{indirect.description}</Text>
            </Row>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        //@ts-ignore
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <Card
          ref={ref}
          hoverable
          loading={sentRefreshCallback}
          style={{
            marginBottom: 5,
            minWidth: 'min-content',
            boxShadow: open
              ? '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'
              : undefined,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: '100%', height: 24 }}>
            <Text strong>{indirect.summary}</Text>
          </Row>

          <br />

          <Text>{`${moment(indirect.startMs).format(
            DISPLAY_TIME_FORMAT
          )} — ${moment(indirect.endMs).format(DISPLAY_TIME_FORMAT)}`}</Text>
          <br />
          {indirect.attendees.slice(0, 2).map((attendee) => (
            <Tag style={{ marginBottom: 2, fontSize: 11 }}>
              <Badge
                status={
                  attendee.status === AttendeeStatus.ACCEPTED
                    ? 'success'
                    : attendee.status === AttendeeStatus.DECLINED
                    ? 'error'
                    : attendee.status === AttendeeStatus.TENTATIVE
                    ? 'warning'
                    : 'default'
                }
                style={{ marginRight: 3 }}
              />
              {attendee.email}
            </Tag>
          ))}
          <br />
          {indirect.attendees.slice(2).length > 0 && (
            <Text style={{ fontSize: 12 }}>
              {`And ${indirect.attendees.slice(2).length} other guest${
                indirect.attendees.slice(2).length > 1 ? 's' : ''
              }`}
            </Text>
          )}
        </Card>
      </Popover>
      <EditIndirectModal
        indirect={indirect}
        refreshCallback={handleSubRefresh}
        hideModal={hideEditIndirectModal}
        isOpen={isEditIndirectModalOpen}
      />
    </>
  );
};
