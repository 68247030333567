import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import { IClient, IClientFile } from '@finni-health/atlas-shared';
import * as FirestoreService from '../services/firestore';
import { ClientDetailsSection } from '../components/ClientProfile/ClientDetailsSection';
import { Loading } from './Loading';
import moment from 'moment';
import { ClientIntakeForm } from '../components/ClientIntakeForm/ClientIntakeForm';
import { IoSchoolOutline } from 'react-icons/io5';
import _ from 'lodash';
import { ClientPayerCard } from '../components/ClientPayerCard';

const { Title, Link: LinkText } = Typography;

export enum TABLE_TABS {
  OVERVIEW = 'overview',
  INTAKE = 'intake',
}

export const ClientProfile: React.FC = () => {
  // @ts-ignore
  const { clientId } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [client, setClient] = useState<IClient>({} as IClient);
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [selectedTab, setSelectedTab] = useState<TABLE_TABS>(
    TABLE_TABS.OVERVIEW
  );

  const getClientData = async () => {
    if (clientId) {
      const client = await FirestoreService.getClientById(clientId);
      setClient(client);
      const clientFile = await FirestoreService.getClientFileByClientId(
        client.id,
        client.clinicId
      );
      setClientFile(clientFile);
    }
  };

  const getAllClientData = useCallback(async () => {
    setIsLoading(true);
    await getClientData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllClientData();
  }, [clientId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row align="middle">
        <Space size="middle">
          <Button onClick={history.goBack}>
            <LinkText>Back</LinkText>
          </Button>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'/clients'}>Clients</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`${client.firstName} ${client.lastName}`}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Space>
      </Row>
      <Row style={{ marginTop: 25 }} align="middle">
        <Title level={2}>{`${client.firstName} ${
          client.lastName
        }, ${moment().diff(client.dateOfBirth, 'years')}, ${
          client.sex
        }`}</Title>
        <Tag
          color={'orange'}
          style={{
            position: 'relative',
            left: 10,
            bottom: 9,
            fontSize: 15,
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <IoSchoolOutline
            style={{ fontSize: 17, marginRight: 3, marginBottom: -3 }}
          />
          Client
        </Tag>
      </Row>
      <Tabs
        type="card"
        defaultActiveKey={selectedTab.valueOf()}
        onChange={(key) => {
          setSelectedTab(key as TABLE_TABS);
        }}
      >
        <Tabs.TabPane tab="Overview" key={TABLE_TABS.OVERVIEW} />
      </Tabs>
      {selectedTab === TABLE_TABS.OVERVIEW && (
        <>
          <ClientDetailsSection
            client={client}
            clientFile={clientFile}
            clientRefreshCallback={getClientData}
          />
        </>
      )}
      {selectedTab === TABLE_TABS.INTAKE && (
        <ClientIntakeForm
          clientFile={clientFile}
          refreshCallback={getClientData}
        />
      )}
    </>
  );
};
