import { Button, Form, Input, Typography, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { SignUpStates, VALID_ZIP_CODE_REGEX } from '../../consts';
import Lottie from 'react-lottie-player';
import foxCelebrateAnimation from '../../lottie/fox-celebrate.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';
import { IUserSignupForm } from '../../pages/SignUpFlow';
import { USStateCode } from '@finni-health/atlas-shared';
import * as FirestoreService from '../../services/firestore';

const { Title, Text } = Typography;

type IProps = {
  inquiry: IUserSignupForm;
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
};

export const EnableCalendarIntegration: React.FC<IProps> = ({
  inquiry,
  setInquiryStatus,
  updateInquiry,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(isScreenMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  const startCalendarIntegration = async () => {
    const url = await FirestoreService.getOAuthConsentURL();
    window.location.replace(url);
  };

  return (
    <>
      <Title
        style={{ fontFamily: 'Reckless Neue' }}
      >{`Let's create a calendar for ${inquiry.clinicName}`}</Title>
      <Text
        style={{
          display: 'block',
          marginBottom: 30,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        Connect with your Google Calendar
      </Text>
      <Form onFinish={() => startCalendarIntegration()}>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{
            height: 44,
            width: 200,
          }}
        >
          Connect Calendar
        </Button>
      </Form>
    </>
  );
};
