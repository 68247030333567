import { Button, Row, Descriptions, Skeleton } from 'antd';
import { Timestamp } from '@firebase/firestore-types';
import _ from 'lodash';
import moment from 'moment';
import * as FirestoreService from '../../services/firestore';
import { useEffect, useState } from 'react';
import { DISPLAY_DATETIME_FORMAT, DISPLAY_DATE_FORMAT } from '../../consts';
import { IClient, IClientFile, IGuardian } from '@finni-health/atlas-shared';
import { Link } from 'react-router-dom';
import { IoHeartCircleOutline } from 'react-icons/io5';
import {
  EditClientFileModal,
  EDIT_CLIENT_FILE_MODAL_TABLE_TABS,
} from '../EditClientFileModal/EditClientFileModal';
import { formatAddress } from '../../helpers/profiles';

interface Props {
  client: IClient;
  clientFile: IClientFile;
  clientRefreshCallback: () => Promise<void>;
}

export const ClientDetailsSection = ({
  client,
  clientFile,
  clientRefreshCallback,
}: Props) => {
  const [guardian, setGuardian] = useState<IGuardian>({} as IGuardian);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] =
    useState<boolean>(false);
  const hideEditClientFileModal = () => {
    setIsEditClientFileModalVisible(false);
  };

  const fetchData = async () => {
    const guardian = await FirestoreService.getGuardianByClientId(
      client.id,
      client.clinicId
    );
    setGuardian(guardian);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ marginBottom: 25 }}>
      <Row justify="end" style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          onClick={() => setIsEditClientFileModalVisible(true)}
        >
          Edit Client Details
        </Button>
      </Row>
      <Descriptions
        bordered
        column={4}
        size="small"
        style={{ marginBottom: 25 }}
      >
        <Descriptions.Item label="Alias">{client.alias}</Descriptions.Item>
        <Descriptions.Item label="First Name">
          {client.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Middle Name">
          {client.middleName || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {client.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Sex">{client.sex}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {moment(client.dateOfBirth).format(DISPLAY_DATE_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian" span={4}>
          <Skeleton loading={isLoading} paragraph={false}>
            <Link to={`/guardian-profile/${guardian.id}`}>
              <IoHeartCircleOutline
                style={{ fontSize: 18, marginBottom: -3, marginRight: 2 }}
              />
              {`${guardian.firstName} ${guardian.lastName}`}
            </Link>
          </Skeleton>
        </Descriptions.Item>
        <Descriptions.Item
          label="Address"
          span={4}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {clientFile.address && formatAddress(clientFile.address)}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian email" span={2}>
          {guardian.email}
        </Descriptions.Item>
        <Descriptions.Item label="Guardian phone" span={2}>
          {guardian.phoneNumber?.primary}
        </Descriptions.Item>
        <Descriptions.Item label="Created On" span={2}>
          {moment((client.createdAt as Timestamp).toDate()).format(
            DISPLAY_DATE_FORMAT
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Last Edited" span={2}>
          {moment((client.updatedAt as Timestamp).toDate()).format(
            DISPLAY_DATETIME_FORMAT
          )}
        </Descriptions.Item>
      </Descriptions>
      <EditClientFileModal
        hideModal={hideEditClientFileModal}
        isVisible={isEditClientFileModalVisible}
        clientFile={clientFile}
        refreshCallback={clientRefreshCallback}
        defaultTab={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
      />
    </div>
  );
};
