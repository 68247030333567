import { Avatar, Row, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { COLORS } from '../consts';
import { WeekDays } from '../pages/types';

const { Text } = Typography;

export const getCalendarDayHeader = (day: WeekDays, selected: Moment) => {
  const selectedDate = selected.clone();
  selectedDate.day(day);

  return moment().format('LL') === selectedDate.format('LL') ? (
    <>
      <Row style={{ width: '100%' }} justify="center">
        <Row style={{ width: '100%' }} justify="center">
          <Text>{selectedDate.format('ddd')}</Text>
        </Row>
        <Row style={{ width: '100%' }} justify="center">
          <Avatar style={{ backgroundColor: COLORS.RED, fontSize: 17 }}>
            {selectedDate.format('D')}
          </Avatar>
        </Row>
      </Row>
    </>
  ) : (
    <Row style={{ width: '100%' }} justify="center">
      <Row style={{ width: '100%' }} justify="center">
        <Text>{selectedDate.format('ddd')}</Text>
      </Row>
      <Row style={{ width: '100%' }} justify="center">
        <Text style={{ fontSize: 17, lineHeight: '32px' }}>
          {selectedDate.format('D')}
        </Text>
      </Row>
    </Row>
  );
};

export const datePickerFormat = (selected: Moment) => {
  if (selected.startOf('week').month() === selected.endOf('week').month()) {
    return `${selected.format('MMMM')} ${selected.format('YYYY')}`;
  } else if (
    selected.startOf('week').year() !== selected.endOf('week').year()
  ) {
    return `${selected.startOf('week').format('MMM')} ${selected.format(
      'YYYY'
    )} — ${selected.endOf('week').format('MMM')} ${selected.format('YYYY')}`;
  } else {
    return `${selected.startOf('week').format('MMM')} — ${selected
      .endOf('week')
      .format('MMM')} ${selected.format('YYYY')}`;
  }
};
