import { ArrowLeftOutlined, BoldOutlined } from '@ant-design/icons';
import {
  ICreateUserEndpointRequest,
  IInvite,
  USStateCode,
  Sex,
  CountryCode,
} from '@finni-health/atlas-shared';
import { Button, Col, Row, Typography, message } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS, SignUpStates, DB_DATE_FORMAT } from '../consts';
import { isScreenMobile } from '../helpers/is-screen-mobile';
import { useQuery } from '../helpers/use-query';
import * as FirestoreService from '../services/firestore';
import { Loading } from './Loading';
import { AuthContext } from '../components/AuthProvider';
import '../css/peachi.css';
import moment, { Moment } from 'moment';

//inquire flow components
import { NewOrExistingPractice } from '../components/SignUpFlow/NewOrExistingPractice';
import { InviteFound } from '../components/SignUpFlow/InviteFound';
import { EnterPracticeName } from '../components/SignUpFlow/EnterPracticeName';
import { EnterPracticeState } from '../components/SignUpFlow/EnterPracticeState';
import { EnterInviteCode } from '../components/SignUpFlow/EnterInviteCode';
import { EnterPhoneNumber } from '../components/SignUpFlow/EnterPhoneNumber';
import { EnterPersonalAddress } from '../components/SignUpFlow/EnterPersonalAddress';
import { EnableCalendarIntegration } from '../components/SignUpFlow/EnableCalendarIntegration';

const { Text, Link: LinkText } = Typography;

export interface IUserSignupForm {
  clinicName: string;
  inviteId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: Moment;
  isFullTime: boolean;
  line1: string;
  line2: string;
  city: string;
  state: USStateCode;
  zipCode: string;
}

export const SignUpFlow: React.FC = () => {
  const user = useContext(AuthContext).user as any;
  const clinic = useContext(AuthContext).clinic as any;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [inquiryStatus, setInquiryStatus] = useState<SignUpStates>(
    SignUpStates.CHECK_INVITES
  );

  const [inquiry, setInquiry] = useState<IUserSignupForm>({
    clinicName: '',
    inviteId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '' as any,
    isFullTime: true,
    line1: '',
    line2: '',
    city: '',
    state: '' as any,
    zipCode: '',
  });

  const [isMobile, setIsMobile] = useState(isScreenMobile());
  const [invites, setInvites] = useState<IInvite[]>([]);

  const [inviteSelectedIndex, setInviteSelectedIndex] = useState<number>(0);

  const fetchData = async () => {
    const invites = await FirestoreService.getInvites();
    setInvites(invites);
    if (!_.isEmpty(invites)) {
      setInquiryStatus(SignUpStates.INVITE_FOUND);
    } else {
      setInquiryStatus(SignUpStates.NEW_OR_EXISTING_PRACTICE);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user.name) {
      window.addEventListener('resize', () => {
        setIsMobile(isScreenMobile());
      });
      try {
        updateInquiry({
          firstName: user.name.split(' ')[0],
          lastName: user.name.split(' ')[1],
        });
      } catch (err) {
        message.error(`Failed to fetch data`);
      }
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (
      !_.isEmpty(clinic) &&
      inquiryStatus !== SignUpStates.CALENDAR_INTEGRATION &&
      inquiryStatus !== SignUpStates.COMPLETE
    ) {
      window.location.replace(window.location.origin);
    }
  }, [clinic]);

  const createUser = async () => {
    setIsLoading(true);

    //create request
    const createUserRequest: ICreateUserEndpointRequest = {
      clinicName: inquiry.clinicName,
      inviteId: inquiry.inviteId,

      firstName: inquiry.firstName,
      lastName: inquiry.lastName,
      address: {
        line1: inquiry.line1,
        ...(inquiry.line2 && { line2: inquiry.line2 }),
        city: inquiry.city,
        state: inquiry.state,
        country: CountryCode.US,
        zipCode: inquiry.zipCode,
      },
      addressNotes: '',
      phoneNumber: inquiry.phoneNumber,
      sex: Sex.UNKNOWN,
      dateOfBirth: moment().format(DB_DATE_FORMAT),
      isFullTime: inquiry.isFullTime,
      npi: '',
    };

    try {
      await FirestoreService.createUser(createUserRequest);
      if (inquiry.clinicName) {
        //still need to integrate calendar
        setInquiryStatus(SignUpStates.CALENDAR_INTEGRATION);
      } else {
        message.success(`Successfully created your account!`);
        window.location.replace(window.location.origin);
      }
    } catch (err) {
      message.error("Couldn't create user, please try again");
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (inquiryStatus === SignUpStates.COMPLETE) {
      createUser();
    }
  }, [inquiryStatus]);

  const updateInquiry = (source: any) => {
    const merged = _.merge(_.cloneDeep(inquiry), source);
    setInquiry(merged);
    console.log('inquiry', merged);
  };

  const getSignUpFormComponent = () => {
    switch (inquiryStatus) {
      case SignUpStates.INVITE_FOUND:
        return (
          <InviteFound
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
            user={user}
          />
        );

      case SignUpStates.NEW_OR_EXISTING_PRACTICE:
        return (
          <NewOrExistingPractice
            setInquiryStatus={setInquiryStatus}
            user={user}
          />
        );

      case SignUpStates.ENTER_PRACTICE_NAME:
        return (
          <EnterPracticeName
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
          />
        );

      case SignUpStates.ENTER_PRACTICE_STATE:
        return (
          <EnterPracticeState
            inquiry={inquiry}
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
          />
        );

      case SignUpStates.ENTER_INVITE_CODE:
        return (
          <EnterInviteCode
            setInquiryStatus={setInquiryStatus}
            setInviteSelectedIndex={setInviteSelectedIndex}
            invites={invites}
          />
        );

      case SignUpStates.ENTER_PERSONAL_PHONE_NUMBER:
        return (
          <EnterPhoneNumber
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
          />
        );

      case SignUpStates.ENTER_PERSONAL_ADDRESS:
        return (
          <EnterPersonalAddress
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
            invite={invites[inviteSelectedIndex]}
          />
        );

      case SignUpStates.CALENDAR_INTEGRATION:
        return (
          <EnableCalendarIntegration
            inquiry={inquiry}
            setInquiryStatus={setInquiryStatus}
            updateInquiry={updateInquiry}
          />
        );
    }
  };

  return (
    <Row
      style={{
        backgroundColor: '#fbf7f0',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Col span={24}>
        <Row
          style={{
            width: '100%',
            marginTop: 30,
            paddingBottom: 30,
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          }}
          justify="center"
          align="middle"
        >
          {inquiryStatus > 0 && inquiryStatus <= 3 && (
            <Button
              onClick={() => {
                setInquiryStatus(inquiryStatus - 1);
              }}
              style={{ height: 40, position: 'absolute', left: 20 }}
              type="text"
            >
              <ArrowLeftOutlined />
            </Button>
          )}
          <Col>
            <a
              href="https://atlas.finnihealth.com"
              style={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'start',
                width: '100%',
              }}
            >
              <Text
                style={{
                  fontSize: 36,
                  color: COLORS.PRIMARY,
                  fontFamily: 'peachi',
                }}
              >
                {`Atlas `}
              </Text>
            </a>
          </Col>
        </Row>
        <div
          style={{
            background: COLORS.PRIMARY,
            marginBottom: isMobile ? 25 : 100,
            height: 3,
            transition: 'all 0.4s',
            width:
              inquiryStatus === SignUpStates.CHECK_INVITES ||
              inquiryStatus === SignUpStates.INVITE_FOUND ||
              inquiryStatus === SignUpStates.NEW_OR_EXISTING_PRACTICE
                ? '10%'
                : inquiryStatus === SignUpStates.ENTER_PRACTICE_NAME
                ? '20%'
                : inquiryStatus === SignUpStates.ENTER_PRACTICE_STATE ||
                  inquiryStatus === SignUpStates.ENTER_PERSONAL_PHONE_NUMBER
                ? '40%'
                : inquiryStatus === SignUpStates.CALENDAR_INTEGRATION ||
                  inquiryStatus === SignUpStates.ENTER_PERSONAL_ADDRESS ||
                  inquiryStatus === SignUpStates.COMPLETE
                ? '75%'
                : 0,
          }}
        />
        <Row
          style={{
            width: '100%',
            marginTop: 30,
            marginBottom: 100,
            textAlign: 'center',
            paddingLeft: isMobile ? 10 : 0,
            paddingRight: isMobile ? 10 : 0,
          }}
          justify="center"
        >
          {isLoading || SignUpStates.CHECK_INVITES ? (
            <Loading />
          ) : (
            <Col>{getSignUpFormComponent()}</Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
