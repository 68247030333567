import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IOAuthPostEndpointRequest, IClinic } from '@finni-health/atlas-shared';
import _ from 'lodash';
import { Loading } from './Loading';
import * as FirestoreService from '../services/firestore';
import { AuthContext } from '../components/AuthProvider';
import { message } from 'antd';

const requiredConsent = [
  'email',
  'profile',
  // 'https://www.googleapis.com/auth/calendar.events.public.readonly',
  // 'https://www.googleapis.com/auth/calendar.calendarlist.readonly',
  'https://www.googleapis.com/auth/calendar.app.created',
  // 'https://www.googleapis.com/auth/calendar.events.freebusy',
  // 'https://www.googleapis.com/auth/calendar.settings.readonly',
  // 'https://www.googleapis.com/auth/calendar.freebusy',
  'openid',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
];

export const OAuth: React.FC = () => {
  const clinic: IClinic = useContext(AuthContext).clinic;
  const { search } = window.location;

  useEffect(() => {
    storeResult();
  }, []);

  const storeResult = async () => {
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const scopes = params.get('scope')?.split(' ');

    //if we want to ensure that the user has consented to all scopes
    if (!_.isEmpty(_.xor(requiredConsent, scopes))) {
      //fail
      console.log('User did not consent to all scopes');
      message.error('Atlas requires calendar access to function properly.');
      window.location.replace(window.location.origin + '/settings');
    } else {
      try {
        if (code) {
          const request: IOAuthPostEndpointRequest = {
            code,
            clinicId: clinic.id,
          };
          const success = await FirestoreService.postOAuthCode(request);
          window.location.replace(window.location.origin + '/');
        }
      } catch (err) {
        console.error(err);
      }
    }
    //success
    window.location.replace(window.location.origin + '/schedules');
  };

  return <Loading />;
};
