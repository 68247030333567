import moment from 'moment';
import {
  BillingCode,
  IAppointment,
  IAuthorization,
} from '@finni-health/atlas-shared';

export const getDurationFromAppointments = (appointments: IAppointment[]) => {
  const totalMs = appointments.reduce(
    (partialSum, appt) => partialSum + (appt.endMs - appt.startMs),
    0
  );

  const duration = moment.duration(totalMs);

  return duration;
};

export const getWeeklyHoursFromAuth = (
  auth: IAuthorization | undefined,
  code: BillingCode
) => {
  if (auth && auth.auths && auth.auths[code]) {
    const authDuration = moment.duration(
      moment(auth.endDate).diff(moment(auth.startDate))
    );
    const numWeeks = authDuration.asWeeks() + (authDuration.days() % 7) / 7;

    const totalHours =
      ((auth.auths[code]?.units || 0) * (auth.auths[code]?.unitSize || 0)) / 60;

    return +(totalHours / numWeeks).toFixed(2);
  } else {
    return 0;
  }
};
