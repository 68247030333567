import { Modal, Button, Form, Row, Col, Input, message, Select } from 'antd';
import { useState } from 'react';
import { VALID_EMAIL_REGEX, ERROR_MESSAGE } from '../consts';
import * as FirestoreService from '../services/firestore';
import {
  ICreateInviteRequest,
  IUser,
  UserPermission,
} from '@finni-health/atlas-shared';

interface ICreateInviteFormValues {
  firstName: string;
  lastName: string;
  email: string;
  role: UserPermission;
}

interface Props {
  hideModal: () => void;
  refreshCallback: () => void;
  isVisible: boolean;
  user: IUser;
}

export const CreateInviteModal = ({
  hideModal,
  isVisible,
  user,
  refreshCallback,
}: Props) => {
  const [form] = Form.useForm<ICreateInviteFormValues>();
  const [isSaving, setIsSaving] = useState(false);

  const handleFormOnFinish = async (values: ICreateInviteFormValues) => {
    setIsSaving(true);
    const createInquiryRequest: ICreateInviteRequest = {
      clinicId: user.clinicId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role,
    };
    try {
      await FirestoreService.createInvite(createInquiryRequest);
      form.resetFields();
      refreshCallback();
      hideModal();
      message.success('Invite created');
    } catch (err: any) {
      message.error((err as string) || ERROR_MESSAGE);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      title={`Create Invite`}
      onCancel={hideModal}
      open={isVisible}
      footer={false}
      width={800}
      style={{ top: 20 }}
    >
      <Form
        form={form}
        layout="vertical"
        labelCol={{ span: 24 }}
        onFinish={handleFormOnFinish}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="First Name" name="firstName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" name="lastName">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  pattern: VALID_EMAIL_REGEX,
                  message: 'Please provide a valid email address',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: 'Please choose a Role',
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {Object.keys(UserPermission).map(
                  (role) =>
                    role !== UserPermission.SUPERADMIN && (
                      <Select.Option key={role} value={role}>
                        {role}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button loading={isSaving} type="primary" htmlType="submit">
            Create Invite
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
