import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Statistic,
  Tooltip,
  message,
  Typography,
} from 'antd';
import { AuthContext } from '../components/AuthProvider';
import * as FirestoreService from '../services/firestore';
import _ from 'lodash';
import {
  ActiveStatus,
  IntakeStatus,
  IClient,
  IClientFile,
  UserPermission,
  BillingCode,
  IAppointment,
} from '@finni-health/atlas-shared';
import { ScheduleCard } from '../components/Schedule/ScheduleCard';
import { Loading } from './Loading';
import { getUtilizationColor } from '../helpers/colors';
import {
  getDurationFromAppointments,
  getWeeklyHoursFromAuth,
} from '../helpers/schedules';
import {
  ExclamationCircleFilled,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { filterClients } from '../helpers/filterClients';
import moment, { Moment } from 'moment';
import * as momentTz from 'moment-timezone';
import { useParams, useHistory } from 'react-router-dom';
import { ERROR_MESSAGE } from '../consts';

const { Text } = Typography;
const { Search } = Input;

export const Schedules: React.FC = () => {
  const user = useContext(AuthContext).user;
  const history = useHistory();

  // URL params
  const { selectedWeek } = useParams<{
    selectedWeek?: string;
  }>();

  // Component data
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientFiles, setClientFiles] = useState<IClientFile[]>([]);

  // Week picker
  const [calWeek, setCalWeek] = useState<Moment>(
    selectedWeek
      ? moment()
          .year(parseInt(selectedWeek.split('-')[0]))
          .week(parseInt(selectedWeek.split('-')[1]))
      : moment()
  );

  // Search
  const [filteredClients, setFilteredClients] = useState<IClient[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  // Statistics and alerts
  const [totalHours, setTotalHours] = useState<number>(0);
  const [authHours, setAuthHours] = useState<number>(0);
  const [isAuthHoursLoading, setIsAuthHoursLoading] = useState<boolean>(false);
  const [hasMissingAuths, setHasMissingAuths] = useState<boolean>(false);

  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addClient, setAddClient] = useState<boolean>(false);

  // First load
  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchData();
    }
  }, [user]);

  // Refresh on auth update
  useEffect(() => {
    const totalHours = getDurationFromAppointments(
      appointments || []
    ).asHours();
    setTotalHours(totalHours);
  }, [clientFiles, appointments]);

  // Navigate weeks
  useEffect(() => {
    if (!isNavigating && !isLoading) {
      fetchAppointments();
    }
  }, [calWeek]);

  //URL manager
  useEffect(() => {
    let url = '/schedules';

    //Add week number to URL
    url += `/${calWeek.year()}-${calWeek.week()}`;

    //apply URL to browser
    window.history.replaceState(null, 'Atlas: ABA Scheduling Software', url);
  }, [calWeek]);

  const fetchClients = async (appointments: IAppointment[]) => {
    let clients = await FirestoreService.getAllClientsForClinic(user.clinicId);
    const clientFiles: IClientFile[] = [];

    clients = await filterClients(clients, async (client: IClient) => {
      const clientFile = await FirestoreService.getClientFileByClientId(
        client.id,
        client.clinicId
      );
      const shouldKeep =
        clientFile.isHot &&
        (user.permissions.includes(UserPermission.RBT) &&
        !user.permissions.includes(UserPermission.ADMIN)
          ? !_.isEmpty(appointments) &&
            appointments.some(
              (appt) =>
                appt.clientId === client.id &&
                appt.attendees.find((attendee) => attendee.email === user.email)
            )
          : true);

      if (shouldKeep) {
        clientFiles.push(clientFile);
      }
      return shouldKeep;
    });

    setClients(clients);
    setClientFiles(clientFiles);
    setFilteredClients(clients);
  };

  const fetchAppointments = async () => {
    setIsNavigating(true);
    try {
      const appointments = (
        await FirestoreService.getAppointmentsForClinicIdAndWeek({
          clinicId: user.clinicId,
          week: calWeek.week(),
          year: calWeek.year(),
          timeZone: momentTz.tz.guess(),
        })
      ).filter((appt) => appt.id.includes('_'));
      appointments.sort((a, b) => a.startMs - b.startMs);

      setAppointments(appointments);
      setIsNavigating(false);

      return appointments;
    } catch (err) {
      if (!isNavigating && !isLoading) {
        message.open({
          content: (
            <>
              <Col>
                <Row>
                  <ExclamationCircleFilled
                    style={{ color: '#faad14', marginTop: 3 }}
                  />
                  <Text>{`Calendar integration is required to enable this functionality`}</Text>
                </Row>
                <Row style={{ width: '100%', marginTop: 5 }}>
                  <Button
                    type="primary"
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    onClick={() => {
                      history.push('/settings/general');
                      message.destroy('calendar-integration');
                    }}
                  >
                    Settings
                  </Button>
                </Row>
              </Col>
            </>
          ),
          duration: 30,
          key: 'calendar-integration',
          onClick: () => message.destroy('calendar-integration'),
        });
        throw new Error(
          'Calendar integration is required to enable this functionality'
        );
      }
      return [];
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const appointments = await fetchAppointments();
    await fetchClients(appointments);
    setIsLoading(false);
  };

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    const filteredClients = clients.filter(
      (client) =>
        client.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
        client.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
        client.alias.toLowerCase().includes(searchString.toLowerCase())
    );
    setFilteredClients(filteredClients);
  };

  const onCalendarChange = (date: Moment | null) => {
    if (date) {
      setCalWeek(date);
    }
  };

  const onPanelChange = (value: Moment) => {
    setCalWeek(value);
  };

  useEffect(() => {
    if (clients.length === 0) {
      setAddClient(true);
    } else {
      setAddClient(false);
    }
  }, [clients]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ minWidth: 500 }}>
          {(user.permissions.includes(UserPermission.ADMIN) ||
            user.permissions.includes(UserPermission.BCBA)) && (
            <Row>
              <Col span={2} style={{ marginLeft: 10 }}>
                <Statistic title="Active Clients" value={clients.length} />
              </Col>
              <Col span={2}>
                <Statistic
                  title="Scheduled Hours"
                  value={totalHours.toFixed(2)}
                />
              </Col>
            </Row>
          )}
          <div
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 999,
              paddingTop: 15,
              paddingBottom: 15,
              background: '#fff',
            }}
          >
            <Row align="middle" style={{ width: '100%', marginBottom: 5 }}>
              <Space size={'large'}>
                <Button
                  onClick={() => {
                    if (calWeek.week() !== moment().week()) {
                      setCalWeek(moment());
                    }
                  }}
                >
                  This week
                </Button>
                <div>
                  <Button
                    size="small"
                    type="text"
                    onClick={() => {
                      const newCalWeek = calWeek.clone();
                      newCalWeek.subtract(1, 'week');
                      setCalWeek(newCalWeek);
                    }}
                  >
                    <LeftOutlined />
                  </Button>
                  <Button
                    size="small"
                    type="text"
                    onClick={() => {
                      const newCalWeek = calWeek.clone();
                      newCalWeek.add(1, 'week');
                      setCalWeek(newCalWeek);
                    }}
                  >
                    <RightOutlined />
                  </Button>
                </div>
                <DatePicker
                  size="large"
                  allowClear={false}
                  style={{ cursor: 'text' }}
                  format={() =>
                    `${calWeek
                      .startOf('week')
                      .format('DD MMM')} ${calWeek.format('YYYY')} — ${calWeek
                      .endOf('week')
                      .format('DD MMM')} ${calWeek.format('YYYY')}`
                  }
                  bordered={false}
                  value={calWeek}
                  onPanelChange={onPanelChange}
                  onChange={onCalendarChange}
                  picker="week"
                />
              </Space>
            </Row>
            <Row style={{ width: '100%' }}>
              <Search
                placeholder="Search by Client's First Name, Last Name, or Initials"
                allowClear
                value={searchString}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Row>
          </div>
          <Row>
            {addClient && (
              <Button
                type="primary"
                onClick={() => {
                  history.push('/clients/create');
                }}
                style={{ marginTop: 5 }}
              >
                Add a Client
                <RightOutlined />
              </Button>
            )}
            {filteredClients.map((client) => (
              <Col span={24}>
                <ScheduleCard
                  client={client}
                  updateHours={() => {}}
                  appointments={appointments.filter(
                    (appt) => appt.clientId === client.id
                  )}
                  refreshCallback={fetchAppointments}
                  isNavigating={isNavigating}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};
