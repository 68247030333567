import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  message,
  Popover,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import * as FirestoreService from '../../services/firestore';
import {
  AttendeeStatus,
  getSessionNameFromBilling,
  IAppointment,
  IClient,
  INote,
  ICreateCompletedAppointmentEndpointRequest,
} from '@finni-health/atlas-shared';
import { DISPLAY_TIME_FORMAT, COLORS } from '../../consts';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { IoSchoolOutline } from 'react-icons/io5';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
  UndoOutlined,
  VideoCameraOutlined,
  CheckCircleFilled,
  FileFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { EditAppointmentModal } from './EditAppointmentModal';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmDeleteAppointmentModal } from './ConfirmDeleteAppointmentModal';
import { CalendarType } from '../../pages/types';
import {
  isAppointmentCancelled,
  getAppointmentLocationText,
} from '../../helpers/appointments';
import { getBillingCodeColor } from '../../helpers/colors';
import { AuthContext } from '../AuthProvider';
import { isMatchingContext } from '../../pages/Calendar';
import { CancelAppointmentModal } from './CancelAppointmentModal';
import { NotesTabs } from './NotesTabs';

const { Text, Title } = Typography;

const CANCELLED_TEXT = '(CANCELLED) ';

interface IProps {
  client: IClient;
  appointment: IAppointment;
  calendarType: CalendarType;
  isMatchNotesDrawerOpen: boolean;
  forceOpen: boolean;
  isCalendarProcessing: boolean;
  setIsMatchNotesDrawerOpen: (isOpen: boolean) => void;
  refreshAppointments: () => Promise<void>;
  refreshAll: () => Promise<void>;
  setSelectedAppointment: (appointment: IAppointment) => void;
  setSelectedClient: (client: IClient) => void;
}

export const AppointmentCard: React.FC<IProps> = ({
  client,
  appointment,
  calendarType,
  isMatchNotesDrawerOpen,
  setIsMatchNotesDrawerOpen,
  refreshAppointments,
  refreshAll,
  forceOpen,
  setSelectedAppointment,
  setSelectedClient,
  isCalendarProcessing,
}: IProps) => {
  const user = useContext(AuthContext).user;
  const isMatching = useContext(isMatchingContext);
  const [ref, setRef] = useState<any>();

  //General states
  const [open, setOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isUnmatching, setIsUnmatching] = useState<boolean>(false);

  //Notes states
  const [notes, setNotes] = useState<INote[]>([]);
  const [unapprovedNoteCount, setUnapprovedNoteCount] = useState<number>(0);

  //Delete appointments modal
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const hideConfirmDeleteModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };
  const confirmDelete = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  //Edit appointments modal
  const [isEditAppointmentModalOpen, setIsEditAppointmentModalOpen] =
    useState<boolean>(false);
  const hideEditAppointmentModal = () => {
    setIsEditAppointmentModalOpen(false);
  };

  //Cancel appointments modal
  const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] =
    useState(false);
  const hideCancelAppointmentModal = () => {
    setIsCancelAppointmentModalOpen(false);
  };
  const handleCancelAppointment = async () => {
    setIsCancelAppointmentModalOpen(true);
  };
  const handleUnCancelAppointment = async () => {
    setIsCancelling(true);
    setSentRefreshCallback(true);
    try {
      //Keeping this option here for now in case we want to un-cancel an appointment that was cancelled before the new completedAppointment cancel flow
      await FirestoreService.updateAppointment({
        ...appointment,
        summary: `${appointment.summary.replaceAll(CANCELLED_TEXT, '')}`,
        attendees: appointment.attendees.map((attendee) => ({
          email: attendee.email,
          status: AttendeeStatus.ACCEPTED,
        })),
      });
      message.success('Appointment un-cancelled');
      refreshAppointments();
      hidePopover();
    } catch (err) {
      message.error('Failed to un-cancel appointment');
      console.error(err);
    }
    setIsCancelling(false);
  };

  //Hover effects
  const [displayCompletionEffect, setDisplayCompletionEffect] =
    useState<boolean>(false);
  const showCompletionEffectValue = (
    appointmentValue: string,
    noteValue: string
  ) => {
    if (displayCompletionEffect && appointmentValue !== noteValue) {
      return noteValue;
    } else {
      return appointmentValue;
    }
  };
  const showCompletionEffectStyle = (
    appointmentValue: string,
    noteValue: string
  ) => {
    if (displayCompletionEffect && appointmentValue !== noteValue) {
      return {
        color: COLORS.PRIMARY,
      };
    } else {
      return {};
    }
  };

  //First load
  useEffect(() => {
    if (!_.isEmpty(user) && !_.isEmpty(client) && !_.isEmpty(appointment)) {
      fetchNotes(forceOpen);
    }
  }, [user, appointment, client]);

  const hidePopover = () => {
    setDisplaySplitEffectNote({} as INote);
    setSelectedAppointment({} as IAppointment);
    setSelectedClient({} as IClient);
    setOpen(false);
    fetchNotes(true);
  };

  //Loading on cards
  const [sentRefreshCallback, setSentRefreshCallback] =
    useState<boolean>(false);
  useEffect(() => {
    if (sentRefreshCallback && !isCalendarProcessing) {
      setSentRefreshCallback(false);
    }
  }, [isCalendarProcessing]);
  useEffect(() => {
    if (isMatching.id == appointment.id) {
      setSentRefreshCallback(true);
    }
  }, [isMatching]);
  const handleSubRefresh = () => {
    setSentRefreshCallback(true);
    refreshAppointments();
  };

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (!isConfirmDeleteModalOpen) {
      if (newOpen) {
        setSelectedAppointment(appointment);
        setSelectedClient(client);
        setOpen(true);
      } else if (!isMatchNotesDrawerOpen) {
        hidePopover();
      }
    }
  };

  useEffect(() => {
    if (forceOpen && !_.isEmpty(client) && !_.isEmpty(appointment)) {
      handlePopoverOpenChange(true);
    }
  }, [forceOpen, client, appointment]);

  useEffect(() => {
    setRef(React.createRef());
  }, []);

  //Scroll to on forceOpen page load
  useEffect(() => {
    if (forceOpen && ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [forceOpen, ref]);

  //Display effect of split, hacky but saves a tonne of refactor
  const [displaySplitEffectNote, setDisplaySplitEffectNote] = useState<INote>(
    {} as INote
  );
  useEffect(() => {
    if (!_.isEmpty(displaySplitEffectNote) && !_.isEmpty(appointment)) {
      const clone = ref.current.cloneNode(true);
      clone.setAttribute('id', 'split-clone');
      clone.style.boxShadow = `0 1px 10px -2px ${COLORS.PRIMARY}, 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)`;

      //Delete extra approved counts
      clone.firstChild.children[0].children[1].removeChild(
        clone.firstChild.children[0].children[1].children[0]
      );

      //Location
      if (
        clone.firstChild.children[3].textContent !==
        getAppointmentLocationText(displaySplitEffectNote.location)
      ) {
        clone.firstChild.children[3].style.color = COLORS.PRIMARY;
      }
      clone.firstChild.children[3].textContent = getAppointmentLocationText(
        displaySplitEffectNote.location
      );

      //Time
      const noteTime = `${moment(displaySplitEffectNote?.startMs).format(
        DISPLAY_TIME_FORMAT
      )} — ${moment(displaySplitEffectNote?.endMs).format(
        DISPLAY_TIME_FORMAT
      )}`;
      if (clone.firstChild.children[5].textContent !== noteTime) {
        clone.firstChild.children[5].style.color = COLORS.PRIMARY;
      }
      clone.firstChild.children[5].textContent = noteTime;

      ref.current.parentNode.appendChild(clone);
    } else {
      const clone = document.getElementById('split-clone');
      clone?.parentNode?.removeChild(clone);
    }
  }, [displaySplitEffectNote]);

  const handleUnmatchNote = async (id: string) => {
    setIsUnmatching(true);
    setSentRefreshCallback(true);
    await FirestoreService.updateNote({
      id,
      appointmentId: '',
      manualOverride: false,
    });
    refreshAll();
    hidePopover();
    setIsUnmatching(false);
  };

  const handleReloadNote = async (id: string) => {
    setIsCancelling(true);

    setSentRefreshCallback(true);
    message.info('Queuing Note Reload');
    await FirestoreService.reloadNoteById(id);
    message.success('Reloaded Note');
    refreshAll();
    hidePopover();
    setIsCancelling(false);
  };

  const handleApproveNote = async (id: string) => {
    setIsCancelling(true);

    setSentRefreshCallback(true);
    message.info('Queuing Note Approval');
    const result = await FirestoreService.approveNote(id);
    if (result == true) {
      message.success('Approved note successfully');
    } else {
      message.error('Failed to approve note');
    }
    refreshAll();
    hidePopover();
    setIsCancelling(false);
  };

  const handleCompleteNote = async (id: string) => {
    setIsCancelling(true);

    setSentRefreshCallback(true);
    const request: ICreateCompletedAppointmentEndpointRequest = { noteId: id };
    const result = await FirestoreService.createCompletedAppointment(request);
    if (!_.isEmpty(result)) {
      message.success('Completed appointment successfully');
    } else {
      message.error('Failed to complete appointment');
    }
    refreshAppointments();
    hidePopover();
    setIsCancelling(false);
  };

  const handleSplitAppointment = async (
    note: INote,
    appointment: IAppointment
  ) => {
    setIsCancelling(true);

    setSentRefreshCallback(true);
    try {
      await FirestoreService.splitAppointmentByNoteAndAppointment(
        note,
        appointment
      );
      message.success('Split appointment successfully');
    } catch (error) {
      message.error('Failed to split appointment');
    }
    refreshAppointments();
    hidePopover();
    setIsCancelling(false);
  };

  //fetch notes
  const fetchNotes = async (force: boolean = false) => {
    const notes = await FirestoreService.getAllNotesByAppointmentId(
      appointment.id,
      appointment.clinicId,
      force
    );
    setUnapprovedNoteCount(
      notes.filter((note) => note.approved === false).length
    );
    setNotes(notes);
  };

  const popoverContent = () => {
    return (
      <div style={{ width: 300 }}>
        <Row justify="end" style={{ marginBottom: 5 }}>
          <>
            {notes.length == 0 && (
              <Tooltip placement="bottom" title="Edit appointment">
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    hidePopover();
                    setIsEditAppointmentModalOpen(true);
                  }}
                >
                  <EditOutlined />
                  Edit
                </Button>
              </Tooltip>
            )}
            {!appointment.attendees
              .map((attendee) => attendee.status)
              .includes(AttendeeStatus.DECLINED) ? (
              notes.length == 0 && (
                <Tooltip placement="bottom" title="Cancel appointment">
                  <Button
                    type="text"
                    size="small"
                    style={{ marginLeft: 5 }}
                    onClick={handleCancelAppointment}
                    loading={isCancelling}
                  >
                    <StopOutlined style={{ margin: 0 }} />
                    Cancel
                  </Button>
                </Tooltip>
              )
            ) : (
              <Tooltip placement="bottom" title="Un-cancel appointment">
                <Button
                  type="text"
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={handleUnCancelAppointment}
                  loading={isCancelling}
                >
                  <UndoOutlined style={{ margin: 0 }} />
                </Button>
              </Tooltip>
            )}
            {notes.length == 0 && (
              <Tooltip placement="bottom" title="Delete appointment">
                <Button
                  type="text"
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={confirmDelete}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="Close">
              <Button
                type="text"
                size="small"
                style={{ marginLeft: 12 }}
                onClick={hidePopover}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          </>
        </Row>
        <Row>
          <Title level={4} style={{ marginBottom: 0 }}>
            {client && !_.isEmpty(client) ? (
              <Link to={`/client-profile/${client.id}`}>
                <IoSchoolOutline
                  style={{ fontSize: 18, marginBottom: -1, marginRight: 3 }}
                />
                {client.alias}
              </Link>
            ) : (
              appointment.summary
            )}
          </Title>
        </Row>
        <Row>
          <Text>
            {getSessionNameFromBilling(
              appointment.billingCode,
              appointment.modifiers
            )}
          </Text>
          <Text type="secondary" style={{ marginLeft: 7 }}>{`${
            appointment.billingCode
          }, ${appointment.modifiers.join(', ')}`}</Text>
        </Row>
        <Row>
          <Text
            style={showCompletionEffectStyle(
              `${moment(appointment.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(appointment.endMs).format(DISPLAY_TIME_FORMAT)}`,

              `${moment(notes[0]?.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(notes[0]?.endMs).format(DISPLAY_TIME_FORMAT)}`
            )}
          >
            {showCompletionEffectValue(
              `${moment(appointment.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(appointment.endMs).format(DISPLAY_TIME_FORMAT)}`,

              `${moment(notes[0]?.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(notes[0]?.endMs).format(DISPLAY_TIME_FORMAT)}`
            )}
          </Text>
        </Row>
        {appointment.meetsLink ? (
          <Row align="middle" style={{ marginTop: 8 }}>
            <Tooltip title="Join Google Meet">
              <Button
                type="primary"
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Link
                  style={showCompletionEffectStyle(
                    getAppointmentLocationText(appointment.location),
                    getAppointmentLocationText(notes[0]?.location)
                  )}
                  to={{ pathname: appointment.meetsLink }}
                  target="_blank"
                >
                  <VideoCameraOutlined style={{ marginRight: 3 }} />
                  {showCompletionEffectValue(
                    getAppointmentLocationText(appointment.location),
                    getAppointmentLocationText(notes[0]?.location)
                  )}
                </Link>
              </Button>
            </Tooltip>
          </Row>
        ) : (
          <Row align="middle">
            <Text
              style={showCompletionEffectStyle(
                getAppointmentLocationText(appointment.location),
                getAppointmentLocationText(notes[0]?.location)
              )}
            >
              {showCompletionEffectValue(
                getAppointmentLocationText(appointment.location),
                getAppointmentLocationText(notes[0]?.location)
              )}
            </Text>
          </Row>
        )}
        <Row style={{ marginTop: 20 }}>
          <Text strong>Guests</Text>
        </Row>
        {appointment.attendees.map((attendee) => (
          <Tag style={{ marginBottom: 2, fontSize: 12 }}>
            <Badge
              status={
                attendee.status === AttendeeStatus.ACCEPTED
                  ? 'success'
                  : attendee.status === AttendeeStatus.DECLINED
                  ? 'error'
                  : attendee.status === AttendeeStatus.TENTATIVE
                  ? 'warning'
                  : 'default'
              }
              style={{ marginRight: 3 }}
            />
            {attendee.email}
          </Tag>
        ))}
        {appointment.description && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Text strong>Description</Text>
            </Row>
            <Row>
              <Text>{appointment.description}</Text>
            </Row>
          </>
        )}
        {notes.length > 0 ? (
          <NotesTabs
            notes={notes}
            appointment={appointment}
            isLoading={isUnmatching}
            handleUnmatchNote={handleUnmatchNote}
            handleReloadNote={handleReloadNote}
            handleApproveNote={handleApproveNote}
            handleCompleteNote={handleCompleteNote}
            handleSplitAppointment={handleSplitAppointment}
            setDisplayCompletionEffect={setDisplayCompletionEffect}
            setDisplaySplitEffectNote={setDisplaySplitEffectNote}
            openMatchNotesDrawer={() => {
              setIsMatchNotesDrawerOpen(true);
            }}
            hidePopover={hidePopover}
          />
        ) : isAppointmentCancelled(appointment) ? (
          <></>
        ) : (
          // <Button
          //   type="primary"
          //   style={{ marginTop: 15 }}
          //   onClick={() => setIsMatchNotesDrawerOpen(true)}
          // >
          //   Browse un-linked notes
          // </Button>
          <></>
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="left"
        open={open}
        onOpenChange={handlePopoverOpenChange}
        content={popoverContent}
        zIndex={500}
        showArrow={false}
        //@ts-ignore
        getPopupContainer={(trigger) => trigger.parentElement}
        style={{ position: 'fixed' }}
      >
        <Card
          ref={ref}
          hoverable
          loading={sentRefreshCallback}
          style={{
            marginBottom: 5,
            minWidth: 'min-content',
            boxShadow: open
              ? '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'
              : undefined,
          }}
          bodyStyle={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 3,
          }}
        >
          <Row justify="space-between" style={{ width: '100%', height: 24 }}>
            <Text
              strong
              style={{
                textDecoration: isAppointmentCancelled(appointment)
                  ? 'line-through'
                  : undefined,
                opacity: isAppointmentCancelled(appointment) ? 0.5 : 1,
              }}
            >
              {`${isAppointmentCancelled(appointment) ? CANCELLED_TEXT : ''}${
                client && !_.isEmpty(client)
                  ? client.alias
                  : appointment.summary
              }`}
            </Text>
            {notes.length > 0 ? (
              unapprovedNoteCount > 0 ? (
                <Tooltip
                  title={`${unapprovedNoteCount} unapproved note${
                    unapprovedNoteCount > 1 ? 's' : ''
                  }`}
                >
                  <Text
                    style={{
                      position: 'relative',
                      left: 12,
                      bottom: 2,
                      color: 'white',
                      fontSize: 12,
                    }}
                    strong
                  >
                    {unapprovedNoteCount}
                  </Text>
                  <FileFilled
                    style={{
                      marginRight: 4,
                      fontSize: 18,
                      color: COLORS.BLUE,
                    }}
                  />
                </Tooltip>
              ) : (
                //Approved Notes
                <Tooltip
                  title={`${notes.length} approved note${
                    notes.length > 1 ? 's' : ''
                  }`}
                >
                  {notes.length > 1 && (
                    <Text
                      style={{
                        position: 'relative',
                        left: -5,
                        bottom: 2,
                        color: COLORS.GREEN,
                        fontSize: 12,
                      }}
                      strong
                    >
                      {notes.length}
                    </Text>
                  )}
                  <CheckCircleFilled
                    style={{
                      marginRight: 4,
                      fontSize: 18,
                      color: COLORS.GREEN,
                    }}
                  />
                </Tooltip>
              )
            ) : moment() > moment(appointment.endMs) ? (
              //Missing Notes
              !isAppointmentCancelled(appointment) && (
                // <Tooltip title="Session notes missing">
                //   <ExclamationCircleFilled
                //     style={{
                //       marginRight: 4,
                //       fontSize: 18,
                //       color: COLORS.DARK_YELLOW,
                //     }}
                //   />
                // </Tooltip>
                <></>
              )
            ) : (
              <></>
            )}
          </Row>
          <Tag
            color={getBillingCodeColor(
              appointment.billingCode,
              appointment.modifiers
            )}
            style={{ marginTop: 2, marginBottom: 2 }}
          >
            {getSessionNameFromBilling(
              appointment.billingCode,
              appointment.modifiers
            )}
          </Tag>
          <br />
          <Text
            style={showCompletionEffectStyle(
              getAppointmentLocationText(appointment.location),
              getAppointmentLocationText(notes[0]?.location)
            )}
          >
            {appointment.meetsLink && (
              <VideoCameraOutlined style={{ marginRight: 3 }} />
            )}
            {showCompletionEffectValue(
              getAppointmentLocationText(appointment.location),
              getAppointmentLocationText(notes[0]?.location)
            )}
          </Text>
          <br />
          <Text
            style={showCompletionEffectStyle(
              `${moment(appointment.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(appointment.endMs).format(DISPLAY_TIME_FORMAT)}`,
              `${moment(notes[0]?.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(notes[0]?.endMs).format(DISPLAY_TIME_FORMAT)}`
            )}
          >
            {showCompletionEffectValue(
              `${moment(appointment.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(appointment.endMs).format(DISPLAY_TIME_FORMAT)}`,
              `${moment(notes[0]?.startMs).format(
                DISPLAY_TIME_FORMAT
              )} — ${moment(notes[0]?.endMs).format(DISPLAY_TIME_FORMAT)}`
            )}
          </Text>
          <br />
          {calendarType === CalendarType.CLIENTS && (
            <>
              {appointment.attendees.slice(0, 2).map((attendee) => (
                <Tag style={{ marginBottom: 2, fontSize: 11 }}>
                  <Badge
                    status={
                      attendee.status === AttendeeStatus.ACCEPTED
                        ? 'success'
                        : attendee.status === AttendeeStatus.DECLINED
                        ? 'error'
                        : attendee.status === AttendeeStatus.TENTATIVE
                        ? 'warning'
                        : 'default'
                    }
                    style={{ marginRight: 3 }}
                  />
                  {attendee.email}
                </Tag>
              ))}
              <br />
              {appointment.attendees.slice(2).length > 0 && (
                <Text style={{ fontSize: 12 }}>
                  {`And ${appointment.attendees.slice(2).length} other guest${
                    appointment.attendees.slice(2).length > 1 ? 's' : ''
                  }`}
                </Text>
              )}
            </>
          )}
        </Card>
      </Popover>
      <EditAppointmentModal
        key={uuidv4()}
        appointment={appointment}
        isOpen={isEditAppointmentModalOpen}
        hideModal={hideEditAppointmentModal}
        refreshCallback={handleSubRefresh}
      />
      <ConfirmDeleteAppointmentModal
        appointment={appointment}
        isVisible={isConfirmDeleteModalOpen}
        hideModal={hideConfirmDeleteModal}
        refreshCallback={handleSubRefresh}
        hidePopover={hidePopover}
      />
      <CancelAppointmentModal
        refreshCallback={handleSubRefresh}
        hideModal={hideCancelAppointmentModal}
        isVisible={isCancelAppointmentModalOpen}
        appointment={appointment}
        client={client}
      />
    </>
  );
};
