import * as FirestoreService from '../../services/firestore';
import {
  Modal,
  Button,
  message,
  Form,
  Row,
  Col,
  Select,
  Typography,
  Input,
  Card,
  Tag,
  Badge,
} from 'antd';
import _ from 'lodash';
import { useContext, useState } from 'react';
import {
  getSessionNameFromBilling,
  IAppointment,
  CancellationReason,
  IClient,
  AttendeeStatus,
  ICreateCancelledCompletedAppointmentEndpointRequest,
} from '@finni-health/atlas-shared';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from '../../consts';
import moment from 'moment';
import { AuthContext } from '../AuthProvider';
import { getBillingCodeColor } from '../../helpers/colors';
import {
  getAppointmentLocationText,
  getCancellationReasonText,
} from '../../helpers/appointments';

const { Text } = Typography;
const { TextArea } = Input;

const CANCELLED_TEXT = '(CANCELLED) ';

interface Props {
  refreshCallback: () => void;
  hideModal: () => void;
  isVisible: boolean;
  appointment: IAppointment;
  client: IClient;
}

interface ICancelAppointmentFormValues {
  reason: CancellationReason;
  description: string;
}

export const CancelAppointmentModal = ({
  refreshCallback,
  hideModal,
  isVisible,
  appointment,
  client,
}: Props) => {
  const user = useContext(AuthContext).user;

  const [form] = Form.useForm<ICancelAppointmentFormValues>();

  const [isCancelling, setIsCancelling] = useState(false);

  const saveCancellation = async () => {
    setIsCancelling(true);

    try {
      //GCal
      await FirestoreService.updateAppointment({
        ...appointment,
        summary: `${CANCELLED_TEXT}${appointment.summary}`,
        attendees: appointment.attendees.map((attendee) => ({
          email: attendee.email,
          status: AttendeeStatus.DECLINED,
        })),
      });
      //Completed appointment
      const values = form.getFieldsValue();
      const request: ICreateCancelledCompletedAppointmentEndpointRequest = {
        clinicId: appointment.clinicId,
        appointmentId: appointment.id,
        cancellationReason: values.reason,
        cancellationNotes: values.description,
      };
      await FirestoreService.createCancelledCompletedAppointment(request);
      form.resetFields();
      message.success('Appointment cancelled');
      refreshCallback();
      hideModal();
    } catch (err) {
      message.error('Failed to cancel appointment');
      console.error(err);
    }
    setIsCancelling(false);
  };

  const hideModalIfNotSaving = () => {
    if (!isCancelling) {
      hideModal();
    }
  };

  return (
    <Modal
      title={`Cancel Appointment`}
      closable={!isCancelling}
      footer={null}
      destroyOnClose={true}
      onCancel={hideModalIfNotSaving}
      open={isVisible}
      width={500}
      bodyStyle={{ paddingLeft: 40, paddingRight: 40 }}
    >
      {client && (
        <>
          <Card
            id="appointmentCard"
            style={{ marginBottom: 15, marginTop: 8 }}
            bodyStyle={{
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Row>
              <Col span={14}>
                <Row
                  justify="space-between"
                  style={{ width: '100%', height: 24 }}
                >
                  <Text strong>{client.alias}</Text>
                </Row>
                <Text>{`${moment(appointment.startMs).format(
                  DISPLAY_DATE_FORMAT
                )} `}</Text>
                <Text>{`${moment(appointment.startMs).format(
                  DISPLAY_TIME_FORMAT
                )} — ${moment(appointment.endMs).format(
                  DISPLAY_TIME_FORMAT
                )}`}</Text>
                <br />
                <Row>
                  <Tag
                    color={getBillingCodeColor(
                      appointment.billingCode,
                      appointment.modifiers
                    )}
                    style={{ marginTop: 2, marginBottom: 2 }}
                  >
                    {getSessionNameFromBilling(
                      appointment.billingCode,
                      appointment.modifiers
                    )}
                  </Tag>
                  <Text style={{ marginTop: 2, marginBottom: 2 }}>
                    {getAppointmentLocationText(appointment.location)}
                  </Text>
                </Row>
              </Col>
              <Col span={10}>
                <Text strong>Guests</Text>
                <Row>
                  {appointment.attendees.slice(0, 2).map((attendee) => (
                    <Tag style={{ marginBottom: 2, fontSize: 11 }}>
                      <Badge
                        status={
                          attendee.status === AttendeeStatus.ACCEPTED
                            ? 'success'
                            : attendee.status === AttendeeStatus.DECLINED
                            ? 'error'
                            : attendee.status === AttendeeStatus.TENTATIVE
                            ? 'warning'
                            : 'default'
                        }
                        style={{ marginRight: 3 }}
                      />
                      {attendee.email}
                    </Tag>
                  ))}
                  <br />
                  {appointment.attendees.slice(2).length > 0 && (
                    <Text style={{ fontSize: 12 }}>
                      {`And ${
                        appointment.attendees.slice(2).length
                      } other guest${
                        appointment.attendees.slice(2).length > 1 ? 's' : ''
                      }`}
                    </Text>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </>
      )}
      <Form
        form={form}
        layout="vertical"
        labelWrap={false}
        labelCol={{ span: 24 }}
        onFinish={saveCancellation}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Please select a cancellation reason',
                },
              ]}
            >
              <Select
                placeholder="Select a reason"
                style={{ width: '100%' }}
                onChange={(value) => {
                  form.setFieldValue('reason', value);
                }}
              >
                {Object.entries(CancellationReason).map(([key, value]) => (
                  <Select.Option value={key}>{`${getCancellationReasonText(
                    value
                  )}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please describe why the appointment is cancelled',
                },
              ]}
            >
              <TextArea
                placeholder="Short description"
                allowClear
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={26}>
          <Col span={24}>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={isCancelling}
              style={{ float: 'right' }}
            >
              Cancel Appointment
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
