import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { SignUpStates, VALID_ZIP_CODE_REGEX } from '../../consts';
import Lottie from 'react-lottie-player';
import foxHiAnimation from '../../lottie/fox-hi.json';
import { isScreenMobile } from '../../helpers/is-screen-mobile';

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  user: any;
};

export const NewOrExistingPractice: React.FC<IProps> = ({
  setInquiryStatus,
  user,
}: IProps) => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [pauseAnimation, setPauseAnimation] = useState(false);

  const [isMobile, setIsMobile] = useState(isScreenMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  return (
    <>
      <div style={{ marginTop: isMobile ? -20 : -60, marginBottom: 40 }}>
        {playAnimation ? (
          <Lottie
            play
            animationData={foxHiAnimation}
            style={{ width: 125, height: 125, margin: 'auto' }}
            onLoopComplete={() => {
              setPlayAnimation(false);
            }}
          />
        ) : (
          <Lottie
            play={!pauseAnimation}
            goTo={pauseAnimation ? 56 : undefined}
            animationData={foxHiAnimation}
            segments={[27, 80]}
            style={{ width: 125, height: 125, margin: 'auto' }}
            onLoopComplete={() => {
              setPauseAnimation(true);
              setTimeout(function () {
                setPauseAnimation(false);
              }, 2000);
            }}
          />
        )}
      </div>
      <Title style={{ fontFamily: 'Reckless Neue' }}>{`Hi ${
        user.name.split(' ')[0]
      }, Welcome to Atlas!`}</Title>
      <Text style={{ display: 'block', marginBottom: 30, fontWeight: 400 }}>
        Are you registering a new practice or joining an existing practice?
      </Text>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          height: 44,
          minWidth: 150,
        }}
        type="primary"
        onClick={() => {
          setInquiryStatus(SignUpStates.ENTER_PRACTICE_NAME);
        }}
      >
        Register new practice
      </Button>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          marginLeft: 20,
          height: 44,
          minWidth: 150,
        }}
        onClick={() => {
          setInquiryStatus(SignUpStates.ENTER_INVITE_CODE);
        }}
      >
        Join existing practice
      </Button>
    </>
  );
};
