import { IClient } from '@finni-health/atlas-shared';

export const filterClients = async (clients: IClient[], callback: any) => {
  const fail = Symbol();
  return (
    await Promise.all(
      clients.map(async (client: any) =>
        (await callback(client)) ? client : fail
      )
    )
  ).filter((client) => client !== fail);
};
