import { Input, DatePicker, Row, Select } from 'antd';
import { useState, useEffect } from 'react';
import { ICredential, Credential } from '@finni-health/atlas-shared';
import { HiOutlineBellAlert } from 'react-icons/hi2';
import {
  AiOutlineCalendar,
  AiOutlineNumber,
  AiOutlineDown,
} from 'react-icons/ai';
import { COLORS } from '../consts';
import moment, { Moment } from 'moment';

const EXPIRY_REMINDER_OPTIONS = [
  { label: '7 days before expiry', value: 7 },
  { label: '15 days before expiry', value: 15 },
  { label: '1 month before expiry', value: 30 },
  { label: '3 months before expiry', value: 90 },
];

interface IProps {
  type: Credential;
  credential: ICredential | undefined;
  updateCredential: (value: ICredential) => void;
  [props: string]: any;
}

export const CredentialInput: React.FC<IProps> = ({
  ...params
}: IProps & any) => {
  //input params
  const { type, credential, updateCredential, ...props } = params;

  //form
  const [selectedIdentifier, setSelectedIdentifier] = useState<string>('');
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<
    Moment | undefined
  >(undefined);
  const [selectedExpiryReminder, setSelectedExpiryReminder] = useState<any>({});

  //expiry visualization
  const [daysToExpiry, setDaysToExpiry] = useState<number>(0);
  const [isExpiring, setIsExpiring] = useState<boolean>(false);
  const currentTime = moment().valueOf();

  useEffect(() => {
    setSelectedIdentifier(credential?.identifier || '');
    setSelectedExpiryDate(
      credential?.expiryMs ? moment(credential?.expiryMs) : undefined
    );
    setSelectedExpiryReminder(
      EXPIRY_REMINDER_OPTIONS.find(
        (option: any) =>
          option.value * 86400000 >=
          credential?.expiryMs - credential?.expiryWarningOffsetMs
      ) || EXPIRY_REMINDER_OPTIONS[0]
    );
  }, [credential]);

  useEffect(() => {
    const tempCredential = {} as ICredential;
    tempCredential.type = credential?.type || type;
    tempCredential.expiryMs = selectedExpiryDate?.valueOf() || 0;
    tempCredential.expiryWarningOffsetMs =
      tempCredential.expiryMs - selectedExpiryReminder.value * 86400000;
    tempCredential.identifier = selectedIdentifier;
    updateCredential(tempCredential);
    if (
      currentTime > tempCredential.expiryWarningOffsetMs &&
      tempCredential.expiryMs > 0
    ) {
      setDaysToExpiry(
        Math.floor(
          (tempCredential.expiryMs - currentTime) / (1000 * 60 * 60 * 24)
        )
      );
      setIsExpiring(true);
    } else {
      setIsExpiring(false);
    }
  }, [selectedExpiryDate, selectedExpiryReminder, selectedIdentifier]);

  return (
    <div {...props}>
      <Input
        prefix={<AiOutlineNumber style={{ marginLeft: -5, marginRight: 5 }} />}
        suffix={
          isExpiring ? (
            <text style={{ color: COLORS.RED }}>
              {daysToExpiry > 0
                ? `EXPIRING IN ${daysToExpiry} DAY${
                    daysToExpiry === 1 ? '' : 'S'
                  }`
                : `CREDENTIAL EXPIRED ${Math.abs(daysToExpiry)} DAY${
                    Math.abs(daysToExpiry) === 1 ? '' : 'S'
                  } AGO`}
            </text>
          ) : (
            <></>
          )
        }
        placeholder={`${type} Number`}
        style={{
          color: isExpiring ? COLORS.RED : undefined,
          borderColor: isExpiring ? COLORS.RED : undefined,
        }}
        value={selectedIdentifier}
        onChange={(e) => {
          setSelectedIdentifier(e.target.value);
        }}
      />
      <Row style={{ marginTop: 2 }}>
        <AiOutlineCalendar
          style={{ color: 'gray', marginTop: 8, marginRight: 5, marginLeft: 5 }}
        />
        <text style={{ marginTop: 4, color: 'gray' }}>Expiry Date:</text>
        <DatePicker
          bordered={false}
          size="middle"
          suffixIcon={null}
          value={selectedExpiryDate}
          onSelect={(date) => {
            setSelectedExpiryDate(date);
          }}
        />
        <HiOutlineBellAlert
          style={{ color: 'gray', marginTop: 8, marginRight: 5 }}
        />
        <text style={{ marginTop: 4, color: 'gray' }}>Alert:</text>
        <Select
          bordered={false}
          style={{ width: 220, marginTop: -1, marginLeft: -2 }}
          value={selectedExpiryReminder.value}
          onChange={(value) => {
            setSelectedExpiryReminder(
              EXPIRY_REMINDER_OPTIONS.find((option) => option.value === value)!
            );
          }}
          suffixIcon={<AiOutlineDown style={{ marginLeft: -50 }} />}
        >
          {Object.values(EXPIRY_REMINDER_OPTIONS).map((expiry_reminder) => (
            <Select.Option
              value={expiry_reminder.value}
              key={expiry_reminder.value}
            >
              {expiry_reminder.label}
            </Select.Option>
          ))}
        </Select>
      </Row>
    </div>
  );
};
