import { Tag } from 'antd';
import { IntakeStatus, InquiryStatus } from '@finni-health/atlas-shared';

interface Props {
  status: InquiryStatus | IntakeStatus;
  style?: React.CSSProperties;
}

enum StatusTagColors {
  UNDISCOVERED = 'cyan',
  REFERRAL = 'cyan',

  IN_PROCESS = 'geekblue',

  AWAITING_RESPONSE = 'gold',
  AWAITING_DIAGNOSIS = 'gold',
  CONFIRMING_INSURANCE = 'gold',
  PENDING_AUTHORIZATION = 'gold',

  INTAKE_ASSESSMENT_IN_PROCESS = 'purple',
  IN_PERSON_ASSESSMENT_IN_PROCESS = 'magenta',
  ISP_IN_PROCESS = 'orange',
  ISP_APPROVED = 'cyan',
  HOURS_APPROVED = 'lime',

  ACTIVE = 'green',

  UNQUALIFIED = 'default',
  NO_RESPONSE = 'default',
  CHURNED = 'default',
}

enum StatusTagText {
  UNDISCOVERED = 'UNDISCOVERED',
  REFERRAL = 'REFERRAL',

  IN_PROCESS = 'IN PROCESS',

  AWAITING_RESPONSE = 'AWAITING RESPONSE',
  AWAITING_DIAGNOSIS = 'AWAITING DIAGNOSIS',
  CONFIRMING_INSURANCE = 'CONFIRMING INSURANCE',
  PENDING_AUTHORIZATION = 'PENDING AUTHORIZATION',

  INTAKE_ASSESSMENT_IN_PROCESS = 'INTAKE ASSESSMENT IN PROCESS',
  IN_PERSON_ASSESSMENT_IN_PROCESS = 'IN PERSON ASSESSMENT IN PROCESS',
  ISP_IN_PROCESS = 'ISP IN PROCESS',
  ISP_APPROVED = 'ISP APPROVED',
  HOURS_APPROVED = 'HOURS APPROVED',

  ACTIVE = 'ACTIVE',

  UNQUALIFIED = 'UNQUALIFIED',
  NO_RESPONSE = 'NO RESPONSE',
  CHURNED = 'CHURNED',
}

export const IntakeStatusTag = ({ status, style }: Props) => {
  return (
    <Tag color={StatusTagColors[status]} style={style}>
      {StatusTagText[status]}
    </Tag>
  );
};
