import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Table,
  Typography,
  Statistic,
  Space,
  Tooltip,
  Popconfirm,
  message,
  Tag,
  Descriptions,
  Button,
} from 'antd';
import { Timestamp } from '@firebase/firestore-types';
import { AuthContext } from './AuthProvider';
import * as FirestoreService from '../services/firestore';
import _ from 'lodash';
import { IInvite, UserPermission } from '@finni-health/atlas-shared';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../consts';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateInviteModal } from './CreateInviteModal';
import { grey, red } from '@ant-design/colors';

const { Link: LinkText } = Typography;

export const InviteTable: React.FC = () => {
  const user = useContext(AuthContext).user;

  const [invites, setInvites] = useState<IInvite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Create invite modal
  const [isCreateInviteModalVisible, setIsCreateInviteModalVisible] =
    useState<boolean>(false);
  const hideCreateInviteModal = () => {
    setIsCreateInviteModalVisible(false);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchInvites();
    }
  }, [user]);

  const fetchInvites = async () => {
    setIsLoading(true);
    let invites = await FirestoreService.getAllInvitesForClinic(user.clinicId);

    invites = invites.map((invite) => {
      const name = `${invite.firstName} ${invite.lastName}`;
      return {
        ...invite,
        name: name,
        key: invite.id,
      };
    });

    setInvites(invites);
    setIsLoading(false);
  };

  const removeRowExtras = (row: any) => {
    const cleanedRow: any = { ...row };
    delete cleanedRow.name;
    delete cleanedRow.key;
    return cleanedRow;
  };

  const deleteInvite = async (invite: IInvite) => {
    setIsLoading(true);
    try {
      await FirestoreService.deleteInvite({
        id: invite.id,
        clinicId: invite.clinicId,
      });
      message.success(`Deleted invite ${invite.firstName} ${invite.lastName}`);
      fetchInvites();
    } catch (err) {
      message.error("Couldn't delete invite, please try again");
      console.error(err);
    }
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Invite Code',
      dataIndex: 'id',
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
    },
    {
      width: 15,
      align: 'center' as any,
      render: (_text: string, row: IInvite) => (
        <Row wrap={false} justify="center" align="middle">
          <Space size="large">
            <Popconfirm
              title={`Are you sure you want to delete invite for ${row.firstName} ${row.lastName}?`}
              placement="bottomRight"
              onConfirm={() => {
                deleteInvite(row);
              }}
              okText="Yes"
              cancelText="No"
            >
              <div>
                <Tooltip title="Delete Invite">
                  <LinkText>
                    <DeleteOutlined
                      style={{
                        color: red.primary,
                        fontSize: 16,
                      }}
                    />
                    Delete
                  </LinkText>
                </Tooltip>
              </div>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Space
          size="large"
          style={{
            marginBottom: '15px',
          }}
        >
          <Statistic
            loading={isLoading}
            title="Total Pending Invites"
            value={invites.length}
          />
        </Space>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={() => {
            setIsCreateInviteModalVisible(true);
          }}
        >
          <PlusOutlined style={{ marginLeft: -3 }} />
          Invite
        </Button>
      </Row>
      <Table columns={tableColumns} dataSource={invites} loading={isLoading} />
      <CreateInviteModal
        hideModal={hideCreateInviteModal}
        isVisible={isCreateInviteModalVisible}
        user={user}
        refreshCallback={fetchInvites}
      />
    </>
  );
};
