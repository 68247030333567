import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  message,
  Row,
  Select,
  Skeleton,
  TimePicker,
  Tooltip,
} from 'antd';
import {
  stringToColor,
  IAppointment,
  IUser,
  AttendeeStatus,
  ICalendarCreateAppointmentEndpointRequest,
  BillingCode,
  Modifier,
  AppointmentLocation,
  IClientFile,
  UserPermission,
} from '@finni-health/atlas-shared';
import * as FirestoreService from '../../services/firestore';
import { DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { COLORS, DISPLAY_TIME_FORMAT } from '../../consts';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { RiSaveFill } from 'react-icons/ri';
import { ConfirmDeleteAppointmentModal } from '../Calendar/ConfirmDeleteAppointmentModal';
import { addUntilRrule } from '../../helpers/appointments';
import { AuthContext } from '../AuthProvider';

interface IProps {
  therapists: IUser[];
  appointment: IAppointment;
  clientFile: IClientFile;
  refreshCallback: () => Promise<IAppointment[]>;
  isNavigating: boolean;
}

export const ScheduleBlock: React.FC<IProps> = ({
  therapists,
  appointment,
  clientFile,
  refreshCallback,
  isNavigating,
}: IProps) => {
  const user = useContext(AuthContext).user;

  const [tempAppointment, setTempAppointment] = useState<IAppointment>(
    {} as IAppointment
  );

  //Delete appointments modal
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const hideConfirmDeleteModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };
  const confirmDelete = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!_.isEmpty(appointment)) {
      setTempAppointment(appointment);
      setIsLoading(false);
    }
  }, [appointment]);

  const handleDiscardChanges = () => {
    setTempAppointment(appointment);
  };

  const handleSave = async () => {
    setIsLoading(true);
    // Get the main instance containing RRULE and let it end at this instance
    const mainAppointment = await FirestoreService.getAppointmentById({
      id: appointment.id.split('_')[0],
      clinicId: appointment.clinicId,
    });

    const appointmentToEnd = addUntilRrule(mainAppointment, appointment);
    await FirestoreService.updateAppointment(appointmentToEnd);

    //if this appointment also needs deleting
    const endDate = moment.utc(appointment.startMs).subtract(1, 'minute');
    if (endDate.valueOf() <= mainAppointment.startMs) {
      await FirestoreService.deleteAppointment({
        id: mainAppointment.id,
        clinicId: appointment.clinicId,
      });
    }

    // Create new appointment containing the changes to start today
    const originalStart = moment(appointment.startMs);
    const startDatetime = moment(tempAppointment.startMs)
      .set({
        year: originalStart.year(),
        month: originalStart.month(),
        date: originalStart.date(),
      })
      .day(originalStart.format('dddd'));
    const endDatetime = moment(tempAppointment.endMs)
      .set({
        year: originalStart.year(),
        month: originalStart.month(),
        date: originalStart.date(),
      })
      .day(originalStart.format('dddd'));

    // End time rolled over to the next day in UTC
    if (endDatetime.isBefore(startDatetime)) {
      endDatetime.add(1, 'days');
    }

    const startMs = startDatetime.valueOf();
    const endMs = endDatetime.valueOf();

    const createAppointmentRequest: ICalendarCreateAppointmentEndpointRequest =
      {
        clinicId: tempAppointment.clinicId,
        clientId: tempAppointment.clientId,
        attendeeEmails: [tempAppointment.attendees[0].email],
        billingCode: BillingCode.CODE_97153,
        modifiers: [Modifier.U1],
        location: AppointmentLocation.HOME,
        summary: tempAppointment.summary,
        description: 'Ongoing session created by Atlas.',
        startMs,
        endMs,
        timeZone: moment.tz.guess(),
        rrule: mainAppointment.rrule, //use the same RRULE as the main appointment
      };

    await FirestoreService.createAppointment(createAppointmentRequest);
    await refreshCallback();
    message.success('Changes saved');
    setIsLoading(false);
  };

  const handleSelectTherapist = (email: string) => {
    const newAppointment = _.cloneDeep(tempAppointment);
    newAppointment.attendees = [{ email, status: AttendeeStatus.ACCEPTED }];
    setTempAppointment(newAppointment);
  };

  const handleTimeSelect = (key: string) => {
    return (datetime: Moment | null) => {
      if (datetime) {
        const newAppointment = _.cloneDeep(tempAppointment);
        _.set(newAppointment, key, datetime.valueOf());
        setTempAppointment(newAppointment);
      }
    };
  };

  return (
    <Card
      size="small"
      bodyStyle={{ paddingTop: 5, paddingBottom: 7 }}
      style={{
        marginBottom: 3,
        borderColor: !_.isEqual(appointment, tempAppointment)
          ? COLORS.PRIMARY
          : undefined,
      }}
    >
      {isLoading || isNavigating ? (
        <Row justify="center" align="middle" style={{ height: 135 }}>
          <Skeleton />
        </Row>
      ) : (
        <>
          {(!user.permissions.includes(UserPermission.RBT) ||
            user.permissions.includes(UserPermission.ADMIN)) && (
            <Row justify="space-between">
              {!_.isEqual(appointment, tempAppointment) ? (
                <>
                  <Tooltip title="Discard Changes">
                    <Button
                      icon={<StopOutlined style={{ fontSize: 12 }} />}
                      size="small"
                      type="text"
                      onClick={handleDiscardChanges}
                    />
                  </Tooltip>
                  <Tooltip title="Save">
                    <Button
                      icon={
                        <RiSaveFill
                          style={{
                            position: 'relative',
                            top: 3,
                            fontSize: 16,
                            color: COLORS.PRIMARY,
                          }}
                        />
                      }
                      size="small"
                      type="text"
                      onClick={handleSave}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Delete">
                    <Button
                      icon={<DeleteOutlined style={{ fontSize: 12 }} />}
                      size="small"
                      type="text"
                      onClick={confirmDelete}
                    />
                  </Tooltip>
                  <div />
                </>
              )}
            </Row>
          )}
          <Row style={{ width: '100%', marginBottom: 5 }}>
            <Select
              allowClear
              showSearch
              placeholder="Therapist"
              optionFilterProp="key"
              style={{ width: '100%' }}
              suffixIcon={<></>}
              defaultValue={
                therapists.find((therapist) =>
                  tempAppointment.attendees.find(
                    (attendee: any) => attendee.email === therapist.email
                  )
                )?.email
              }
              onSelect={handleSelectTherapist}
              disabled={
                user.permissions.includes(UserPermission.RBT) &&
                !user.permissions.includes(UserPermission.ADMIN)
              }
            >
              {therapists.map((therapist) => (
                <Select.Option
                  key={`${therapist.firstName} ${therapist.lastName}`}
                  value={therapist.email}
                >
                  <Badge
                    color={stringToColor(therapist.id)}
                    text={`${therapist.firstName} ${therapist.lastName}`}
                  />
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row style={{ width: '100%', marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="Start"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              value={moment(tempAppointment.startMs)}
              style={{ width: '100%' }}
              onSelect={handleTimeSelect('startMs')}
              onChange={handleTimeSelect('startMs')}
              disabled={
                user.permissions.includes(UserPermission.RBT) &&
                !user.permissions.includes(UserPermission.ADMIN)
              }
            />
          </Row>
          <Row style={{ width: '100%', marginBottom: 5 }}>
            <TimePicker
              hideDisabledOptions
              placeholder="End"
              format={DISPLAY_TIME_FORMAT}
              minuteStep={15}
              use12Hours
              showNow={false}
              value={moment(tempAppointment.endMs)}
              style={{ width: '100%' }}
              onSelect={handleTimeSelect('endMs')}
              onChange={handleTimeSelect('endMs')}
              disabled={
                user.permissions.includes(UserPermission.RBT) &&
                !user.permissions.includes(UserPermission.ADMIN)
              }
            />
          </Row>
        </>
      )}
      <ConfirmDeleteAppointmentModal
        appointment={appointment}
        isVisible={isConfirmDeleteModalOpen}
        hideModal={hideConfirmDeleteModal}
        refreshCallback={refreshCallback}
        hidePopover={() => {}}
      />
    </Card>
  );
};
