import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { EditClinicForm } from '../components/EditClinicForm';
import { Tabs } from 'antd';
import { StaffTable } from '../components/StaffTable';
import { InviteTable } from '../components/InviteTable';
import { AuthContext } from '../components/AuthProvider';
import {
  UserPermission,
  capitalizeFirstLetter,
} from '@finni-health/atlas-shared';

export enum TABLE_TABS {
  ROSTER = 'Roster',
  INVITES = 'Invites',
}

export const Staff: React.FC = () => {
  const user = useContext(AuthContext).user;
  const { tab } = useParams<{
    tab?: string;
  }>();

  const location = useLocation();

  //set active tab
  const [activeTab, setActiveTab] = useState(
    (capitalizeFirstLetter(tab || '') as TABLE_TABS) || TABLE_TABS.ROSTER
  );

  //set active tab on URL change
  useEffect(() => {
    //get URL
    const url = window.location.pathname;

    //get tab from URL
    const tab = url.split('/')[2];

    //set active tab
    if (tab) {
      setActiveTab(capitalizeFirstLetter(tab) as TABLE_TABS);
    }
  }, [location]);

  //set URL on active tab change
  useEffect(() => {
    let url = '/staff';

    //Add tab to URL
    url += `/${activeTab.toLowerCase()}`;

    //apply URL to browser if not already there
    if (location.pathname !== url) {
      window.history.replaceState(null, 'Atlas: ABA Scheduling Software', url);
    }
  }, [activeTab]);

  return (
    <div
      style={{
        margin: 'auto',
      }}
    >
      <Tabs
        activeKey={activeTab}
        defaultActiveKey={activeTab}
        onChange={(key) => {
          setActiveTab(key as TABLE_TABS);
        }}
        items={[
          {
            label: `Roster`,
            key: TABLE_TABS.ROSTER,
            children: <StaffTable />,
          },
        ].concat(
          user.permissions.includes(UserPermission.ADMIN) ||
            user.permissions.includes(UserPermission.SUPERADMIN)
            ? [
                {
                  label: `Invites`,
                  key: TABLE_TABS.INVITES,
                  children: <InviteTable />,
                },
              ]
            : []
        )}
      />
    </div>
  );
};
