import firebase from 'firebase';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Typography,
  Form,
  Input,
  message,
  Row,
} from 'antd';
import * as FirestoreService from '../services/firestore';
import Lottie from 'react-lottie-player';
import astronautAnimation from '../lottie/astronaut.json';
import _ from 'lodash';
import { COLORS } from '../consts';
import { AuthContext } from '../components/AuthProvider';

const { Text } = Typography;

interface ILocationState {
  from: string;
}

interface ILoginFormValues {
  username: string;
  password: string;
}

export const Login: React.FC = () => {
  const refreshAuthContext = useContext(AuthContext).refresh;
  const state = useLocation().state as ILocationState;
  const history = useHistory();
  const isLoggedIn = Boolean(firebase.auth().currentUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('Logged out');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //force log out on /login page load
  useEffect(() => {
    logout();
  }, []);

  const handleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = 'en';
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const credential = result.credential!;
        const token = (credential as any).accessToken;
        const user = result.user;
        const userRequest = FirestoreService.getUserById(user?.uid!).then(
          async (user) => {
            if (_.isEmpty(user)) {
              await refreshAuthContext();
              history.push('/signup');
            } else {
              await refreshAuthContext();
              history.push('/');
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <>
      <Row
        style={{ height: '100vh', background: '#fbf7f0' }}
        justify="center"
        align="middle"
      >
        <Col span={20} style={{ maxWidth: 400, marginTop: -75 }}>
          <Col style={{ marginBottom: 20 }}>
            <Lottie
              play
              animationData={astronautAnimation}
              style={{
                position: 'relative',
                width: 125,
                height: 125,
                margin: 'auto',
                bottom: -30,
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <a href="https://atlas.finnihealth.com">
                <Text
                  style={{
                    fontSize: 36,
                    color: COLORS.PRIMARY,
                    fontFamily: 'peachi',
                  }}
                >
                  {`Atlas `}
                </Text>
              </a>
            </div>
          </Col>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            initialValues={{ remember: true }}
          >
            <Form.Item name="login">
              <Button
                loading={isLoading}
                block
                onClick={handleLogin}
                type="primary"
              >
                Login with Google
              </Button>
            </Form.Item>
          </Form>

          <Divider>or</Divider>

          <Row justify="center">
            <Button onClick={handleLogin}>Create Account</Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};
