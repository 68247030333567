import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Layout } from 'antd';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import { Loading } from './Loading';
import { isEmpty } from 'lodash';
import { AuthContext } from '../components/AuthProvider';
import { Clients } from './Clients';
import { ClientProfile } from './ClientProfile';
import { Calendar } from './Calendar';
import { ClinicSettings } from './ClinicSettings';
import { UserProfile } from './UserProfile';
import { GuardianProfile } from './GuardianProfile';
import { Schedules } from './Schedules';
import { UserPermission } from '@finni-health/atlas-shared';
import { RbtCalendar } from './RbtCalendar';
import { Development } from './Development';
import { OAuth } from './OAuth';
import { Staff } from './Staff';

const { Content } = Layout;

export const Home: React.FC = () => {
  const user = useContext(AuthContext).user;

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isLoadingContext, setIsLoadingContext] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      setIsLoadingContext(false);
    }
  }, [user]);

  const onSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  function renderContent() {
    return (
      <Content
        style={{
          paddingTop: 10,
          paddingBottom: 0,
          paddingLeft: 15,
          paddingRight: 15,
          minHeight: 'fit-content',
          background: 'white',
        }}
      >
        {isLoadingContext ? (
          <Loading />
        ) : (
          <Switch>
            {!isEmpty(user) &&
            (user.permissions?.includes(UserPermission.BCBA) ||
              user.permissions?.includes(UserPermission.ADMIN) ||
              user.permissions?.includes(UserPermission.SUPERADMIN)) ? (
              <>
                <PrivateRoute path={'/settings/:tab?'} exact>
                  <ClinicSettings />
                </PrivateRoute>
                <PrivateRoute path={'/staff/:tab?'} exact>
                  <Staff />
                </PrivateRoute>
                {user.permissions?.includes(UserPermission.BCBA) ||
                  (user.permissions?.includes(UserPermission.ADMIN) && (
                    <>
                      <PrivateRoute
                        path={'/calendar/:selectedWeek?/:eventId?'}
                        exact
                      >
                        <Calendar />
                      </PrivateRoute>
                      <PrivateRoute path={'/'} exact>
                        <Clients />
                      </PrivateRoute>
                    </>
                  ))}
                {!user.permissions?.includes(UserPermission.BCBA) &&
                  !user.permissions?.includes(UserPermission.ADMIN) &&
                  user.permissions?.includes(UserPermission.RBT) && (
                    <>
                      <PrivateRoute
                        path={'/calendar/:selectedWeek?/:eventId?'}
                        exact
                      >
                        <RbtCalendar />
                      </PrivateRoute>
                      <PrivateRoute path={'/'} exact>
                        <RbtCalendar />
                      </PrivateRoute>
                    </>
                  )}
                <PrivateRoute path={'/guardian-profile/:guardianId'}>
                  <GuardianProfile />
                </PrivateRoute>
                <PrivateRoute path={'/client-profile/:clientId'}>
                  <ClientProfile />
                </PrivateRoute>
                <PrivateRoute path={'/clients/:tab?'} exact>
                  <Clients />
                </PrivateRoute>
                <PrivateRoute path={'/profile'} exact>
                  <UserProfile />
                </PrivateRoute>
                <PrivateRoute path={'/schedules/:selectedWeek?'} exact>
                  <Schedules />
                </PrivateRoute>
                <PrivateRoute path={'/auth'} exact>
                  <OAuth />
                </PrivateRoute>
                {user.permissions?.includes(UserPermission.SUPERADMIN) && (
                  <PrivateRoute path={'/dev'} exact>
                    <Development />
                  </PrivateRoute>
                )}
                {user.permissions?.includes(UserPermission.BCBA) &&
                  !user.permissions?.includes(UserPermission.RBT) && (
                    <>
                      <PrivateRoute path={'/'} exact>
                        <Clients />
                      </PrivateRoute>
                    </>
                  )}
              </>
            ) : (
              <>
                <PrivateRoute path={'/profile'} exact>
                  <UserProfile />
                </PrivateRoute>
                <PrivateRoute path={'/schedules/:selectedWeek?'} exact>
                  <Schedules />
                </PrivateRoute>
                <PrivateRoute path={'/calendar/:selectedWeek?/:eventId?'} exact>
                  <RbtCalendar />
                </PrivateRoute>
                <PrivateRoute path={'/'} exact>
                  <RbtCalendar />
                </PrivateRoute>
              </>
            )}
          </Switch>
        )}
      </Content>
    );
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Header />
      <Layout>
        <Sidebar
          isSidebarCollapsed={isSidebarCollapsed}
          onSidebarCollapse={onSidebarCollapse}
        />
        <Layout
          style={{
            overflow: 'scroll',
            borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
          }}
        >
          {renderContent()}
        </Layout>
      </Layout>
    </Layout>
  );
};
