import React, { useEffect, useState, useContext } from 'react';
import '../components/css/Appointments.css';
import { AuthContext } from '../components/AuthProvider';
import { Button, DatePicker, Row, Space, Table, Tag, Input, Col } from 'antd';
import { useParams } from 'react-router-dom';
import {
  IAppointment,
  IClient,
  IIndirect,
  IUser,
  stringToColor,
  UserPermission,
  getInitials,
} from '@finni-health/atlas-shared';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import * as momentTz from 'moment-timezone';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import * as FirestoreService from '../services/firestore';
import { CreateIndirectModal } from '../components/Calendar/CreateIndirectModal';
import { IRbtCalendarEvent, ICalendarRow, WeekDays } from './types';
import { RbtAppointmentCard } from '../components/Calendar/RbtAppointmentCard';
import { datePickerFormat, getCalendarDayHeader } from '../helpers/calendar';
import { IndirectCard } from '../components/Calendar/IndirectCard';

const { Search } = Input;

const DEBOUNCE_TIME = 500;

export const RbtCalendar: React.FC = () => {
  const user = useContext(AuthContext).user;

  // Params
  const { selectedWeek, eventId } = useParams<{
    selectedWeek?: string;
    eventId?: string;
  }>();

  // General state
  const [isLoading, setIsLoading] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const [eventCardOpenedOnce, setEventCardOpenedOnce] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  // Calendar
  const [clinicClients, setClinicClients] = useState<IClient[]>([]);
  const [appointmentsByTherapist, setAppointmentsByTherapist] = useState<
    ICalendarRow[]
  >([]);
  const [dataSource, setDataSource] = useState<ICalendarRow[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const [calWeek, setCalWeek] = useState<Moment>(
    selectedWeek ? moment().week(parseInt(selectedWeek)) : moment()
  );

  // Create indirect modal
  const [isCreateIndirectModalVisible, setIsCreateIndirectModalVisible] =
    useState<boolean>(false);
  const hideCreateIndirectModal = () => {
    setIsCreateIndirectModalVisible(false);
  };

  // Event card
  const [selectedEvent, setSelectedEvent] = useState<IRbtCalendarEvent>();

  //URL manager
  useEffect(() => {
    let url = '/calendar';

    //Add week number to URL
    url += '/' + calWeek.week();

    //Add appointmentId to URL
    if (!_.isEmpty(selectedEvent)) {
      url += '/' + selectedEvent.id;
    }

    //apply URL to browser
    window.history.replaceState(null, 'Atlas: ABA Scheduling Software', url);
  }, [calWeek, selectedEvent]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setHasLoadedOnce(false);
    throttledFetchAppointments();
  }, [user, calWeek]);

  const [fetchAppointmentsTimeout, setFetchAppointmentsTimeout] =
    useState<any>(0);

  const throttledFetchAppointments = async (...options: any) => {
    if (fetchAppointmentsTimeout) {
      clearTimeout(fetchAppointmentsTimeout);
    }
    setFetchAppointmentsTimeout(
      setTimeout(async () => {
        if (!_.isEmpty(options)) {
          fetchAppointments(...options);
        } else {
          fetchAppointments();
        }
      }, DEBOUNCE_TIME)
    );
  };

  const fetchData = async () => {
    setIsLoading(true);
    if (!_.isEmpty(user)) {
      const clinicClients = await FirestoreService.getAllClientsForClinic(
        user.clinicId
      );
      let unmatchedNotes =
        await FirestoreService.getAllUnmatchedNotesByClinicId(user.clinicId);
      if (!user.permissions.includes(UserPermission.ADMIN)) {
        unmatchedNotes = unmatchedNotes.filter(
          (note) => note.userId === user.id
        );
      }
      await throttledFetchAppointments(true);

      setClinicClients(clinicClients);
    }
    setIsLoading(false);
    setHasLoadedOnce(true);
  };

  const fetchAppointments = async (isCalledFromFetchData = false) => {
    setIsNavigating(true);

    let appointments =
      (await FirestoreService.getAppointmentsForClinicIdAndWeek({
        clinicId: user.clinicId,
        week: calWeek.week(),
        year: calWeek.year(),
        timeZone: momentTz.tz.guess(),
      })) as IRbtCalendarEvent[];

    let indirects = (await FirestoreService.getIndirectsForWeekAndClinicId({
      week: calWeek.week(),
      year: calWeek.year(),
      clinicId: user.clinicId,
      timeZone: momentTz.tz.guess(),
    })) as IRbtCalendarEvent[];

    appointments = appointments.filter((appointment) =>
      appointment.attendees.some((attendee) => attendee.email == user.email)
    );

    indirects = indirects.filter((indirect) =>
      indirect.attendees.some((attendee) => attendee.email == user.email)
    );

    const events = appointments.concat(indirects);

    const therapistAppointments = await groupAppointmentsByTherapist(events);

    //Open event card if eventId is in URL
    if (!eventCardOpenedOnce) {
      setSelectedEvent(appointments.find((appt) => appt.id === eventId));
      setEventCardOpenedOnce(true);
    } else {
      setSelectedEvent(undefined);
    }
    setAppointmentsByTherapist(therapistAppointments);
    setDataSource(therapistAppointments);

    setIsNavigating(false);
    if (!isCalledFromFetchData) {
      setHasLoadedOnce(true);
    }
  };

  const groupAppointmentsByTherapist = async (
    appointments: IRbtCalendarEvent[]
  ) => {
    let appointmentsPerPerson: IRbtCalendarEvent[] = [];

    appointments.forEach(async (appt) => {
      const apptSpread: IRbtCalendarEvent[] = [];

      appt.attendees.forEach(async (attendee) => {
        if (user.email === attendee.email) {
          apptSpread.push({
            ...appt,
            attendee: attendee.email,
          } as any);
        }
      });
      appointmentsPerPerson = appointmentsPerPerson.concat(apptSpread);
    });

    const groupedByPerson = _(appointmentsPerPerson)
      .groupBy((appointment: any) => appointment.attendee)
      .value();

    const result: ICalendarRow[] = [];

    const apptsForEmail = groupedByPerson[user.email] || [];
    result.push({
      person: user,
      sunday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 0),
      monday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 1),
      tuesday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 2),
      wednesday: apptsForEmail.filter(
        (appt) => moment(appt.startMs).day() === 3
      ),
      thursday: apptsForEmail.filter(
        (appt) => moment(appt.startMs).day() === 4
      ),
      friday: apptsForEmail.filter((appt) => moment(appt.startMs).day() === 5),
      saturday: apptsForEmail.filter(
        (appt) => moment(appt.startMs).day() === 6
      ),
    });

    return result;
  };

  const appointmentsTableRender = (events: IRbtCalendarEvent[]) => {
    return events
      .sort((a, b) => a.startMs - b.startMs)
      .map((event) =>
        // Has clientId, its an appointment, otherwise its indirect
        !_.isEmpty((event as any).clientId) ? (
          <RbtAppointmentCard
            client={
              clinicClients.find(
                (client) => client.id === (event as IAppointment).clientId
              )!
            }
            appointment={event as IAppointment}
            forceOpen={event.id === selectedEvent?.id ? true : false}
            setSelectedEvent={setSelectedEvent}
          />
        ) : (
          <IndirectCard
            indirect={event as IIndirect}
            forceOpen={event.id === selectedEvent?.id ? true : false}
            setSelectedEvent={setSelectedEvent}
            refreshCallback={throttledFetchAppointments}
            isCalendarProcessing={isNavigating}
          />
        )
      );
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        title: (
          <Row
            style={{ height: 54, width: '100%' }}
            align="middle"
            justify="center"
          >
            Calendar
          </Row>
        ),
        dataIndex: 'person',
        sorter: (a: any, b: any) =>
          a.person.firstName.localeCompare(b.person.firstName),

        render: (user: IUser) => {
          return (
            <Tag color={stringToColor(user.id)}>
              {`${user.firstName} ${user.lastName}`}
            </Tag>
          );
        },
        width: 145,
      },
      ...Object.values(WeekDays).map((day: WeekDays) => {
        return {
          title: getCalendarDayHeader(day, calWeek),
          dataIndex: day,
          render: appointmentsTableRender,
          width: 167,
        };
      }),
    ];

    return tableColumns;
  };

  const onChange = (date: Moment | null) => {
    if (date) {
      setCalWeek(date);
    }
  };

  const onPanelChange = (value: Moment) => {
    setCalWeek(value);
  };

  const [searchTimeout, setSearchTimeout] = useState<any>(0);

  const throttledSearch = async (searchString: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        performSearch(searchString);
      }, DEBOUNCE_TIME)
    );
  };

  const performSearch = (searchString: string) => {
    if (!_.isEmpty(searchString)) {
      const filteredData = [
        Object.fromEntries(
          Object.entries(appointmentsByTherapist[0]).map((day: any) => {
            if (day[0] === 'person') {
              return day;
            }
            day[1] = day[1].filter((appt: any) => {
              const client = clinicClients.find(
                (client) => client.id === appt.clientId
              )!;
              return (
                client.firstName
                  .toLowerCase()
                  .includes(searchString.toLowerCase()) ||
                client.lastName
                  .toLowerCase()
                  .includes(searchString.toLowerCase()) ||
                client?.alias.toLowerCase().includes(searchString.toLowerCase())
              );
            });
            return day;
          })
        ),
      ];
      setDataSource(filteredData as ICalendarRow[]);
    } else {
      setDataSource(appointmentsByTherapist);
    }
  };

  const handleSearch = (searchString: string) => {
    setSearchString(searchString);
    throttledSearch(searchString);
  };

  return (
    <div style={{ minWidth: 1500 }}>
      <Row align="middle" justify="space-between">
        <Space size={'large'}>
          <Button
            onClick={() => {
              if (calWeek.week() !== moment().week()) {
                setCalWeek(moment());
              }
            }}
          >
            Today
          </Button>
          <div>
            <Button
              size="small"
              type="text"
              onClick={() => {
                const newCalWeek = calWeek.clone();
                newCalWeek.subtract(1, 'week');
                setCalWeek(newCalWeek);
              }}
            >
              <LeftOutlined />
            </Button>
            <Button
              size="small"
              type="text"
              onClick={() => {
                const newCalWeek = calWeek.clone();
                newCalWeek.add(1, 'week');
                setCalWeek(newCalWeek);
              }}
            >
              <RightOutlined />
            </Button>
          </div>
          <DatePicker
            size="large"
            format={() => datePickerFormat(calWeek)}
            bordered={false}
            value={calWeek}
            onPanelChange={onPanelChange}
            onChange={onChange}
            picker="week"
          />
        </Space>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={() => {
            setIsCreateIndirectModalVisible(true);
          }}
        >
          <PlusOutlined style={{ marginLeft: -3 }} />
          Log Indirect
        </Button>
      </Row>
      <Row style={{ marginTop: 15, marginBottom: 15 }}>
        <Col span={24}>
          <Search
            placeholder="Search calendars by First or Last Name"
            allowClear
            value={searchString}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      <Row>
        <Table
          style={{
            width: '100%',
            verticalAlign: 'top',
            overflow: 'visible',
          }}
          columns={getTableColumns()}
          dataSource={dataSource}
          loading={(isLoading || isNavigating) && !hasLoadedOnce}
          pagination={false}
          showSorterTooltip={false}
        />
      </Row>
      <CreateIndirectModal
        isVisible={isCreateIndirectModalVisible}
        hideModal={hideCreateIndirectModal}
        refreshCallback={throttledFetchAppointments}
      />
    </div>
  );
};
