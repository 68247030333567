import { Row, Col, Typography, Form, Input, Select } from 'antd';
import _ from 'lodash';
import {
  CountryCode,
  IClientFile,
  USStateCode,
} from '@finni-health/atlas-shared';
import { PhoneNumberInput } from '../PhoneNumberInput';

const { Title } = Typography;

interface Props {
  isEditingDisabled: boolean;
  tempClientFile: IClientFile;
  updateTempClientFile: (source: Partial<IClientFile>) => void;
}

export const ClientProvidersSection = ({
  isEditingDisabled,
  tempClientFile,
  updateTempClientFile,
}: Props) => {
  return (
    <>
      <Title id={'providers'} level={2} style={{ marginTop: 20 }}>
        Providers
      </Title>
      <Title id={'diagnostician'} level={3}>
        Diagnostician
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="First Name">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.firstName}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      firstName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Last Name">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.lastName}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      lastName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Office Name">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.officeName}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      officeName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Phone Number">
            <PhoneNumberInput
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.phoneNumber}
              onChange={(e: any) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      phoneNumber: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Email">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.email}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      email: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Address Line 1">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.address?.line1}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      address: {
                        ...tempClientFile?.providers?.diagnostician?.address,
                        line1: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address Line 2">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.address?.line2}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      address: {
                        ...tempClientFile?.providers?.diagnostician?.address,
                        line2: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="City">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.address?.city}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      address: {
                        ...tempClientFile?.providers?.diagnostician?.address,
                        city: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="State">
            <Select
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.address?.state}
              onChange={(value) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      address: {
                        ...tempClientFile?.providers?.diagnostician?.address,
                        state: value,
                      },
                    },
                  },
                });
              }}
            >
              {Object.values(USStateCode).map((state) => (
                <Select.Option value={state} key={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Country">
            <Select
              disabled={true}
              value={CountryCode.US}
              onChange={(_) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      address: {
                        ...tempClientFile?.providers?.diagnostician?.address,
                        country: CountryCode.US,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zip Code">
            <Input
              type="number"
              maxLength={5}
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.diagnostician?.address?.zipCode}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    diagnostician: {
                      ...tempClientFile?.providers?.diagnostician,
                      address: {
                        ...tempClientFile?.providers?.diagnostician?.address,
                        zipCode: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'pediatrician'} level={3}>
        Pediatrician
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="First Name">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.firstName}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      firstName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Last Name">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.lastName}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      lastName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Office Name">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.officeName}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      officeName: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="Phone Number">
            <PhoneNumberInput
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.phoneNumber}
              onChange={(e: any) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      phoneNumber: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Email">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.email}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      email: e.target.value,
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Address Line 1">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.address?.line1}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      address: {
                        ...tempClientFile?.providers?.pediatrician?.address,
                        line1: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address Line 2">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.address?.line2}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      address: {
                        ...tempClientFile?.providers?.pediatrician?.address,
                        line2: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="City">
            <Input
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.address?.city}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      address: {
                        ...tempClientFile?.providers?.pediatrician?.address,
                        city: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="State">
            <Select
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.address?.state}
              onChange={(value) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      address: {
                        ...tempClientFile?.providers?.pediatrician?.address,
                        state: value,
                      },
                    },
                  },
                });
              }}
            >
              {Object.values(USStateCode).map((state) => (
                <Select.Option value={state} key={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Country">
            <Input
              disabled={true}
              value={CountryCode.US}
              onChange={(_) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      address: {
                        ...tempClientFile?.providers?.pediatrician?.address,
                        country: CountryCode.US,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zip Code">
            <Input
              type="number"
              maxLength={5}
              disabled={isEditingDisabled}
              value={tempClientFile?.providers?.pediatrician?.address?.zipCode}
              onChange={(e) => {
                updateTempClientFile({
                  providers: {
                    ...tempClientFile?.providers,
                    pediatrician: {
                      ...tempClientFile?.providers?.pediatrician,
                      address: {
                        ...tempClientFile?.providers?.pediatrician?.address,
                        zipCode: e.target.value,
                      },
                    },
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Title id={'diagnosisReports'} level={3}>
        Diagnosis Reports
      </Title>
      <Row gutter={24}>
        <Col span={6}>
          {tempClientFile?.providers?.diagnosisReportUrls?.map(
            (diagnosisReportUrl, index) => (
              <a target="_blank" href={diagnosisReportUrl}>
                <Input
                  disabled={true} //TODO: add upload functionality
                  value={`View diagnostic report ${index + 1}`}
                  style={{ color: 'blue', cursor: 'pointer', marginBottom: 10 }}
                />
              </a>
            )
          )}
        </Col>
      </Row>
    </>
  );
};
