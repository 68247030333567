import { Button, Row, Descriptions, Skeleton } from 'antd';
import { Timestamp } from '@firebase/firestore-types';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DISPLAY_DATETIME_FORMAT, DISPLAY_DATE_FORMAT } from '../../consts';
import { IClient, IClientFile, IGuardian } from '@finni-health/atlas-shared';
import { EditClientFileModal } from '../EditClientFileModal/EditClientFileModal';
import * as FirestoreService from '../../services/firestore';
import { Link } from 'react-router-dom';
import { IoSchoolOutline } from 'react-icons/io5';

interface Props {
  guardian: IGuardian;
  refreshCallback: () => Promise<void>;
}

export const GuardianDetailsSection = ({
  guardian,
  refreshCallback,
}: Props) => {
  const [client, setClient] = useState<IClient>({} as IClient);
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Edit Guardian Modal
  const [isEditGuardianModalVisible, setIsEditGuardianModalVisible] =
    useState<boolean>(false);
  const hideEditGuardianModal = () => {
    setIsEditGuardianModalVisible(false);
  };

  const fetchData = async () => {
    const client = await FirestoreService.getClientByGuardianId(
      guardian.id,
      guardian.clinicId
    );
    const clientFile = await FirestoreService.getClientFileByGuardianId(
      guardian.id,
      guardian.clinicId
    );

    setClient(client);
    setClientFile(clientFile);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ marginBottom: 25 }}>
      <Row justify="end" style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          onClick={() => setIsEditGuardianModalVisible(true)}
        >
          Edit profile
        </Button>
      </Row>
      <Descriptions
        bordered
        column={4}
        size="small"
        style={{ marginBottom: 25 }}
      >
        <Descriptions.Item label="First Name" span={2}>
          {guardian.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" span={2}>
          {guardian.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Client" span={4}>
          {!_.isEmpty(client) && (
            <Skeleton loading={isLoading} paragraph={false}>
              <Link to={`/client-profile/${client.id}`}>
                <IoSchoolOutline
                  style={{ fontSize: 18, marginBottom: -3, marginRight: 3 }}
                />
                {client.alias}
              </Link>
            </Skeleton>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Created On" span={2}>
          {moment((guardian.createdAt as Timestamp).toDate()).format(
            DISPLAY_DATE_FORMAT
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Last Edited" span={2}>
          {moment((guardian.updatedAt as Timestamp).toDate()).format(
            DISPLAY_DATETIME_FORMAT
          )}
        </Descriptions.Item>
      </Descriptions>
      <EditClientFileModal
        hideModal={hideEditGuardianModal}
        refreshCallback={refreshCallback}
        isVisible={isEditGuardianModalVisible}
        clientFile={clientFile}
      />
    </div>
  );
};
