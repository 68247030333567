import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Progress,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import {
  BillingCode,
  IAppointment,
  IClient,
  IClientFile,
  IUser,
  UserPermission,
  Weekday,
} from '@finni-health/atlas-shared';
import * as FirestoreService from '../../services/firestore';
import { IntakeStatusTag } from '../IntakeStatusTag';
import _ from 'lodash';
import {
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import { getUtilizationColor } from '../../helpers/colors';
import { AuthContext } from '../AuthProvider';
import {
  getDurationFromAppointments,
  getWeeklyHoursFromAuth,
} from '../../helpers/schedules';
import { Link } from 'react-router-dom';
import {
  EditClientFileModal,
  EDIT_CLIENT_FILE_MODAL_TABLE_TABS,
} from '../EditClientFileModal/EditClientFileModal';
import { ScheduleBlock } from './ScheduleBlock';
import moment from 'moment';
import { AddScheduleBlock } from './AddScheduleBlock';
import { IoSchoolOutline } from 'react-icons/io5';
import { COLORS, ERROR_MESSAGE } from '../../consts';

const { Text, Title } = Typography;
const { TextArea } = Input;

interface IProps {
  client: IClient;
  appointments: IAppointment[];
  refreshCallback: () => Promise<IAppointment[]>;
  updateHours: (authHoursChange: number) => void;
  hideClientInitials?: boolean;
  isNavigating: boolean;
}

export const ScheduleCard: React.FC<IProps> = ({
  client,
  appointments,
  refreshCallback,
  updateHours,
  hideClientInitials,
  isNavigating,
}: IProps) => {
  const user = useContext(AuthContext).user;

  const [city, setCity] = useState<string>('');
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [tempClientFile, setTempClientFile] = useState<IClientFile>(
    {} as IClientFile
  );
  const [therapists, setTherapists] = useState<IUser[]>([]);
  const [totalHours, setTotalHours] = useState<number>(0);

  const [isAddingBlock, setIsAddingBlock] = useState<Set<Weekday>>(new Set());
  const [isSavingNotes, setIsSavingNotes] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Edit ClientFile Modal
  const [isEditClientFileModalVisible, setIsEditClientFileModalVisible] =
    useState<boolean>(false);
  const hideEditClientFileModal = () => {
    setIsEditClientFileModalVisible(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const clientFile = await FirestoreService.getClientFileByClientId(
        client.id,
        client.clinicId
      );
      setClientFile(clientFile);
      setTempClientFile(_.cloneDeep(clientFile));
      setCity(clientFile.address.city);
    } catch (err) {
      console.log(err);
    }

    try {
      const users = await FirestoreService.getAllUsersForClinic(
        client.clinicId
      );
      const therapists = users.filter(
        (user: IUser) =>
          user.permissions.includes(UserPermission.RBT) ||
          user.permissions.includes(UserPermission.BCBA) ||
          user.permissions.includes(UserPermission.ADMIN)
      );
      setTherapists(therapists);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [client]);

  useEffect(() => {
    const totalHours = getDurationFromAppointments(
      appointments || []
    ).asHours();
    setTotalHours(totalHours);
  }, [appointments]);

  const handeSaveScheduleNotes = async (id: string, scheduleNotes: string) => {
    setIsSavingNotes(true);
    try {
      await FirestoreService.updateClientFile({ id, scheduleNotes });
      updateTempClientFile({ scheduleNotes });
      message.success('Changes Saved');
    } catch (err) {
      message.error(ERROR_MESSAGE);
    }
    setIsSavingNotes(false);
  };

  const updateTempClientFile = (source: Partial<IClientFile>) => {
    const merged = _.merge(_.cloneDeep(tempClientFile), source);
    setTempClientFile(merged);
  };

  const getTotalHoursColor = () => {
    const authHours = getWeeklyHoursFromAuth(
      clientFile.payers.primary?.auth,
      BillingCode.CODE_97153
    );
    return getUtilizationColor((totalHours / authHours) * 100);
  };

  const handleAddScheduleBlock = (weekday: Weekday) => {
    return () => {
      // if (isAuthValid(clientFile)) {
      const newIsAddingBlock = _.cloneDeep(isAddingBlock);
      newIsAddingBlock.add(weekday);
      setIsAddingBlock(newIsAddingBlock);
      // } else {
      // message.warn('Add auth start and end dates before creating a schedule');
      // }
    };
  };

  const handleCancelAddScheduleBlock = (weekday: Weekday) => {
    return () => {
      const newIsAddingBlock = _.cloneDeep(isAddingBlock);
      newIsAddingBlock.delete(weekday);
      setIsAddingBlock(newIsAddingBlock);
    };
  };

  const isAuthValid = (clientFile: IClientFile) => {
    return (
      !_.isEmpty(clientFile.payers.primary?.auth?.startDate) &&
      !_.isEmpty(clientFile.payers.primary?.auth?.endDate)
    );
  };

  return (
    <>
      <Card
        style={{
          background: '#fcfcfc',
          marginBottom: 20,
        }}
        bodyStyle={{ paddingTop: 10, paddingBottom: 10, paddingRight: 10 }}
      >
        {isLoading ? (
          <Row justify="center" align="middle" style={{ height: 280 }}>
            <Skeleton />
          </Row>
        ) : (
          <Row>
            <Col
              span={4}
              style={{
                paddingLeft: 0,
                paddingRight: 24,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {!hideClientInitials && (
                <Row>
                  <Text underline style={{ fontSize: 18, fontWeight: 600 }}>
                    <Link
                      to={
                        user.permissions.includes(UserPermission.RBT)
                          ? '#'
                          : `/client-profile/${client.id}`
                      }
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <IoSchoolOutline
                        style={{
                          fontSize: 18,
                          marginBottom: -3,
                          marginRight: 5,
                        }}
                      />
                      {client && client.alias}
                    </Link>
                  </Text>
                </Row>
              )}
              <Row style={{ marginTop: 10 }}>
                <Text
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {city}
                </Text>
              </Row>
              <Row>
                <IntakeStatusTag
                  style={{
                    maxWidth: 180,
                    whiteSpace: 'pre-line',
                  }}
                  status={clientFile.intakeStatus}
                />
              </Row>
              <Row>
                <Form.Item
                  style={{ marginTop: 15, marginBottom: 0, width: '100%' }}
                >
                  <Form
                    layout="vertical"
                    onFinish={(values) =>
                      handeSaveScheduleNotes(
                        tempClientFile.id,
                        values.scheduleNotes
                      )
                    }
                  >
                    <Row justify="space-between" align="middle">
                      <Text style={{ whiteSpace: 'nowrap' }}>Notes:</Text>
                      <Form.Item style={{ marginBottom: 5 }} shouldUpdate>
                        {({ getFieldsValue }) => {
                          const { scheduleNotes } = getFieldsValue();
                          const formDidntChange =
                            !scheduleNotes ||
                            scheduleNotes === tempClientFile.scheduleNotes;

                          return (
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="small"
                              loading={isSavingNotes}
                              disabled={formDidntChange}
                            >
                              Save
                            </Button>
                          );
                        }}
                      </Form.Item>
                    </Row>
                    <Form.Item name="scheduleNotes" noStyle>
                      <TextArea
                        defaultValue={tempClientFile.scheduleNotes}
                        style={{ height: hideClientInitials ? 178 : 134 }}
                        disabled={
                          !user.permissions.includes(UserPermission.ADMIN)
                        }
                      />
                    </Form.Item>
                  </Form>
                </Form.Item>
              </Row>
            </Col>
            <Col span={20} style={{ padding: 0 }}>
              {Object.values(Weekday).map((weekday: string) => (
                <Card.Grid
                  key={weekday}
                  style={{
                    width: '14.28%',
                    height: 32,
                    textAlign: 'center',
                    padding: 5,
                  }}
                  hoverable={false}
                >
                  <Title level={5}>{weekday}</Title>
                </Card.Grid>
              ))}
              {Object.values(Weekday).map((weekday) => (
                <Card.Grid
                  key={weekday}
                  style={{
                    width: '14.28%',
                    height: 'calc(100% - 33px)',
                    minHeight: 248,
                    textAlign: 'center',
                    padding: 5,
                  }}
                  hoverable={false}
                >
                  {appointments
                    .filter(
                      (appt) => moment(appt.startMs).format('dddd') === weekday
                    )
                    .map((appt) => (
                      <ScheduleBlock
                        key={appt.id}
                        therapists={therapists}
                        appointment={appt}
                        clientFile={clientFile}
                        refreshCallback={refreshCallback}
                        isNavigating={isNavigating}
                      />
                    ))}
                  {!isAddingBlock.has(weekday)
                    ? (user.permissions.includes(UserPermission.ADMIN) ||
                        user.permissions.includes(UserPermission.BCBA)) && (
                        <Button
                          size="small"
                          style={{ width: '100%' }}
                          disabled={isNavigating}
                          onClick={handleAddScheduleBlock(weekday)}
                        >
                          <PlusOutlined />
                        </Button>
                      )
                    : (user.permissions.includes(UserPermission.ADMIN) ||
                        user.permissions.includes(UserPermission.BCBA)) && (
                        <AddScheduleBlock
                          therapists={therapists}
                          client={client}
                          clientFile={clientFile}
                          weekday={weekday}
                          refreshCallback={refreshCallback}
                          cancelAddScheduleBlock={handleCancelAddScheduleBlock(
                            weekday
                          )}
                        />
                      )}
                </Card.Grid>
              ))}
            </Col>
          </Row>
        )}
      </Card>
      <EditClientFileModal
        hideModal={hideEditClientFileModal}
        isVisible={isEditClientFileModalVisible}
        clientFile={tempClientFile}
        refreshCallback={async () => {
          const newClientFile = await FirestoreService.getClientFileByClientId(
            client.id,
            client.clinicId
          );

          const authHoursChange =
            getWeeklyHoursFromAuth(
              newClientFile.payers.primary?.auth,
              BillingCode.CODE_97153
            ) -
            getWeeklyHoursFromAuth(
              clientFile.payers.primary?.auth,
              BillingCode.CODE_97153
            );
          await fetchData();
          updateHours(authHoursChange);
        }}
        defaultTab={EDIT_CLIENT_FILE_MODAL_TABLE_TABS.CLIENT}
      />
    </>
  );
};
