import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Table,
  Typography,
  Statistic,
  Space,
  Tooltip,
  Popconfirm,
  message,
  Tag,
  Descriptions,
  Button,
} from 'antd';
import { Timestamp } from '@firebase/firestore-types';
import { AuthContext } from '../components/AuthProvider';
import * as FirestoreService from '../services/firestore';
import _ from 'lodash';
import { IUser, UserPermission } from '@finni-health/atlas-shared';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../consts';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { EditUserModal } from '../components/EditUserModal';
import { grey, red } from '@ant-design/colors';
import { CreateInviteModal } from './CreateInviteModal';

const { Link: LinkText } = Typography;

export const StaffTable: React.FC = () => {
  const user = useContext(AuthContext).user;

  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Update User Modal
  const [isEditUserModalVisible, setIsEditUserModalVisible] =
    useState<boolean>(false);
  const [editUserModalUser, setEditUserModalUser] = useState<IUser>(
    {} as IUser
  );
  const hideEditUserModal = () => {
    setIsEditUserModalVisible(false);
  };

  //Create invite modal
  const [isCreateInviteModalVisible, setIsCreateInviteModalVisible] =
    useState<boolean>(false);
  const hideCreateInviteModal = () => {
    setIsCreateInviteModalVisible(false);
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      fetchUsers();
    }
  }, [user]);

  const fetchUsers = async () => {
    setIsLoading(true);
    let users = await FirestoreService.getAllUsersForClinic(
      user.clinicId,
      true
    );

    users = users.map((user) => {
      const name = `${user.firstName} ${user.lastName}`;
      return {
        ...user,
        name: name,
        key: user.id,
      };
    });

    setUsers(users);
    setIsLoading(false);
  };

  const removeRowExtras = (row: any) => {
    const cleanedRow: any = { ...row };
    delete cleanedRow.name;
    delete cleanedRow.key;
    return cleanedRow;
  };

  const deleteUser = async (user: IUser) => {
    setIsLoading(true);
    try {
      await FirestoreService.deleteUser({ id: user.id });
      message.success(`Deleted user ${user.firstName} ${user.lastName}`);
      fetchUsers();
    } catch (err) {
      message.error("Couldn't delete user, please try again");
      console.error(err);
    }
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Roles',
      dataIndex: 'permissions',
      sorter: (a: IUser, b: IUser) =>
        a.permissions.length - b.permissions.length,
      render: (UserPermissions: UserPermission[]) => {
        return UserPermissions.map((permiss) =>
          permiss !== UserPermission.SUPERADMIN ? <Tag>{permiss}</Tag> : <></>
        );
      },
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'City',
      dataIndex: ['address', 'city'],
      sorter: (a: IUser, b: IUser) =>
        a.address?.city?.localeCompare(b.address?.city),
    },
    {
      title: 'Hours',
      dataIndex: 'isFullTime',
      render: (isFullTime: boolean) =>
        isFullTime ? (
          <Tag color="blue">Full-time</Tag>
        ) : (
          <Tag color="orange">Part-time</Tag>
        ),
    },
    // {
    //   title: 'Employee ID',
    //   dataIndex: 'id',
    // },
    {
      width: 15,
      align: 'center' as any,
      render: (_text: string, row: IUser) => (
        <Row wrap={false} justify="center" align="middle">
          <Space size="large">
            <Tooltip title="Edit User">
              <LinkText
                onClick={() => {
                  setEditUserModalUser(removeRowExtras(row));
                  setIsEditUserModalVisible(true);
                }}
              >
                <EditOutlined style={{ fontSize: 16 }} />
                Edit
              </LinkText>
            </Tooltip>
            <Popconfirm
              title={`Are you sure you want to delete user ${row.firstName} ${row.lastName}?`}
              placement="bottomRight"
              disabled={user.id === row.id}
              onConfirm={() => {
                deleteUser(row);
              }}
              okText="Yes"
              cancelText="No"
            >
              <div>
                <Tooltip title="Delete User">
                  <LinkText disabled={user.id === row.id}>
                    <DeleteOutlined
                      style={{
                        color: user.id === row.id ? grey.primary : red.primary,
                        fontSize: 16,
                      }}
                    />
                    Delete
                  </LinkText>
                </Tooltip>
              </div>
            </Popconfirm>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Space
          size="large"
          style={{
            marginBottom: '15px',
          }}
        >
          <Statistic
            loading={isLoading}
            title="Total Staff"
            value={users.length}
          />
          <Statistic
            loading={isLoading}
            title="Full-Time"
            value={users.filter((user) => user.isFullTime).length}
          />
          <Statistic
            loading={isLoading}
            title="Part-Time"
            value={users.filter((user) => !user.isFullTime).length}
          />
        </Space>
        {/* <Button
          shape="round"
          size="large"
          type="primary"
          onClick={() => {
            setIsCreateInviteModalVisible(true);
          }}
        >
          <PlusOutlined style={{ marginLeft: -3 }} />
          Invite
        </Button> */}
      </Row>
      <Table
        columns={tableColumns}
        dataSource={users}
        loading={isLoading}
        // expandable={{
        //   expandedRowRender: (row: IUser) => (
        //     // <Descriptions bordered column={4} style={{ paddingLeft: 50 }}>
        //     //   <Descriptions.Item label="Sex: " span={1}>
        //     //     {row.sex}
        //     //   </Descriptions.Item>
        //     //   <Descriptions.Item label="Date of Birth: " span={1}>
        //     //     {row.dateOfBirth}
        //     //   </Descriptions.Item>
        //     //   <Descriptions.Item label="Created On: " span={2}>
        //     //     {moment((row.createdAt as Timestamp).toDate()).format(
        //     //       DISPLAY_DATE_FORMAT
        //     //     )}
        //     //   </Descriptions.Item>
        //     //   <Descriptions.Item
        //     //     label="Address: "
        //     //     span={1}
        //     //     style={{ whiteSpace: 'pre-wrap' }}
        //     //   >
        //     //     {`${row.address?.line1}${
        //     //       row.address?.line2 ? ` ${row.address?.line2}` : ''
        //     //     },\n${row.address?.city}, ${row.address?.state} ${
        //     //       row.address?.zipCode
        //     //     }\n${row.address?.country}`}
        //     //   </Descriptions.Item>
        //     //   <Descriptions.Item label="Address Notes: " span={3}>
        //     //     {row.addressNotes}
        //     //   </Descriptions.Item>
        //     // </Descriptions>
        //   ),
        // }}
      />
      <EditUserModal
        hideModal={hideEditUserModal}
        isVisible={isEditUserModalVisible}
        user={editUserModalUser}
        refreshCallback={fetchUsers}
      />
      <CreateInviteModal
        hideModal={hideCreateInviteModal}
        isVisible={isCreateInviteModalVisible}
        user={user}
        refreshCallback={fetchUsers}
      />
    </>
  );
};
