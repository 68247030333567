import {
  AttendeeStatus,
  IAppointment,
  AppointmentLocation,
  capitalizeFirstLetter,
} from '@finni-health/atlas-shared';
import moment from 'moment';

export const getEarliestAppointmentTime = () => {
  const start = moment();
  const remainder = 15 - (start.minute() % 15);
  const earliestAppointmentTime = moment(start).add(remainder, 'minutes');

  return earliestAppointmentTime;
};

/**
 * We consider an appointment to be cancelled if ANY attendee declines the appointment
 *
 * @param appointment the appointment
 * @returns True if any attendee declines the appointment
 */
export const isAppointmentCancelled = (appointment: IAppointment) => {
  return appointment.attendees
    .map((attendee) => attendee.status)
    .includes(AttendeeStatus.DECLINED);
};

export const getAppointmentLocationText = (
  appointmentLocation: AppointmentLocation
) => {
  switch (appointmentLocation) {
    case AppointmentLocation.HOME:
      return 'Home';
    case AppointmentLocation.OFFICE:
      return 'Office';
    case AppointmentLocation.TELEHEALTH:
      return 'Telehealth';
    default:
      return 'Community';
  }
};

export const getCancellationReasonText = (reason: string) => {
  return reason
    .split('_')
    .map((substring: string) => capitalizeFirstLetter(substring.toLowerCase()))
    .join(' ');
};

export const addUntilRrule = (
  mainAppointment: IAppointment,
  untilInstance: IAppointment
) => {
  //end date
  const endDate = moment
    .utc(untilInstance.startMs)
    .subtract(60, 'minute')
    .format('YYYYMMDD[T]HHMMSS[Z]');

  // Add new UNTIL rule for 1 minute before the instance start time to delete 'this and all future'
  const untilRule = {
    key: 'UNTIL',
    value: endDate,
  };

  // Parse existing rules into a map
  const rrules = mainAppointment.rrule?.slice(6).split(';') || [];
  const rrulesMap = rrules?.map((rrule) => {
    const keyValue = rrule.split('=');
    return { key: keyValue[0], value: keyValue[1] };
  });

  // Replace or add the new UNTIL rule
  const untilRuleIndex = rrulesMap.findIndex((rrule) => rrule.key === 'UNTIL');
  if (untilRuleIndex) {
    rrulesMap[untilRuleIndex] = untilRule;
  } else {
    rrulesMap.push(untilRule);
  }

  return {
    ...mainAppointment,
    rrule: `RRULE:${rrulesMap
      .map((rrule) => `${rrule.key}=${rrule.value}`)
      .join(';')}`,
  };
};
